
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as _91action_93WvsZVu2YCAMeta } from "/vercel/path0/apps/zefire-spa/pages/auth/[action].vue?macro=true";
import { default as authaI2K7xb7i3Meta } from "/vercel/path0/apps/zefire-spa/pages/auth.vue?macro=true";
import { default as commissioningrYIznb9uxOMeta } from "/vercel/path0/apps/zefire-spa/pages/commissioning.vue?macro=true";
import { default as _91aggregation_937n1s9zlrSmMeta } from "/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/[aggregation].vue?macro=true";
import { default as contractsJdvtoCQX0IMeta } from "/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/contracts.vue?macro=true";
import { default as datahealthzmb3yPgGh6Meta } from "/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/datahealth.vue?macro=true";
import { default as filesZ3c99e9I3pMeta } from "/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/files.vue?macro=true";
import { default as index5byCkC794XMeta } from "/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/index.vue?macro=true";
import { default as invoicesKV0wWjTzGkMeta } from "/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/invoices.vue?macro=true";
import { default as notesO9vBXAkWKbMeta } from "/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/notes.vue?macro=true";
import { default as portfolioz9QvnqNoq3Meta } from "/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/portfolio.vue?macro=true";
import { default as supervisionMVSCq8I7V1Meta } from "/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/supervision.vue?macro=true";
import { default as _91uuid_93PIiDSS9biyMeta } from "/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid].vue?macro=true";
import { default as _91kind_936gKu17aaiyMeta } from "/vercel/path0/apps/zefire-spa/pages/companies/[kind].vue?macro=true";
import { default as companies42lfd9xJqVMeta } from "/vercel/path0/apps/zefire-spa/pages/companies.vue?macro=true";
import { default as filesCn9tqoRc75Meta } from "/vercel/path0/apps/zefire-spa/pages/contracts/[uuid]/files.vue?macro=true";
import { default as indexDeoKr0fijUMeta } from "/vercel/path0/apps/zefire-spa/pages/contracts/[uuid]/index.vue?macro=true";
import { default as invoicesSDRcaoR5nHMeta } from "/vercel/path0/apps/zefire-spa/pages/contracts/[uuid]/invoices.vue?macro=true";
import { default as notesFBPkpO5CsgMeta } from "/vercel/path0/apps/zefire-spa/pages/contracts/[uuid]/notes.vue?macro=true";
import { default as _91uuid_932mlo2nHQn5Meta } from "/vercel/path0/apps/zefire-spa/pages/contracts/[uuid].vue?macro=true";
import { default as indexBS57a8fycaMeta } from "/vercel/path0/apps/zefire-spa/pages/contracts/index.vue?macro=true";
import { default as contractsvOjlVtwGLyMeta } from "/vercel/path0/apps/zefire-spa/pages/contracts.vue?macro=true";
import { default as calendar8zVESAAD4MMeta } from "/vercel/path0/apps/zefire-spa/pages/dashboard/calendar.vue?macro=true";
import { default as datahealthqOnADDATiyMeta } from "/vercel/path0/apps/zefire-spa/pages/dashboard/datahealth.vue?macro=true";
import { default as fileslSMVyRo1HQMeta } from "/vercel/path0/apps/zefire-spa/pages/dashboard/files.vue?macro=true";
import { default as indexJJGaVJsD2qMeta } from "/vercel/path0/apps/zefire-spa/pages/dashboard/index.vue?macro=true";
import { default as marketLlajF6LQGyMeta } from "/vercel/path0/apps/zefire-spa/pages/dashboard/market.vue?macro=true";
import { default as notes4Pa0fyC8CGMeta } from "/vercel/path0/apps/zefire-spa/pages/dashboard/notes.vue?macro=true";
import { default as supervisiontVwwBXeZkMMeta } from "/vercel/path0/apps/zefire-spa/pages/dashboard/supervision.vue?macro=true";
import { default as technoC0PyJ7WkObMeta } from "/vercel/path0/apps/zefire-spa/pages/dashboard/techno.vue?macro=true";
import { default as dashboarduLFks7k62nMeta } from "/vercel/path0/apps/zefire-spa/pages/dashboard.vue?macro=true";
import { default as datastudiog8qVm4NZ1SMeta } from "/vercel/path0/apps/zefire-spa/pages/datastudio.vue?macro=true";
import { default as _91_91notionId_93_93FUsOFLhD9YMeta } from "/vercel/path0/apps/zefire-spa/pages/docs/[[notionId]].vue?macro=true";
import { default as docsjtarmfBB1JMeta } from "/vercel/path0/apps/zefire-spa/pages/docs.vue?macro=true";
import { default as errorZq9LRN1aDJMeta } from "/vercel/path0/apps/zefire-spa/pages/error.vue?macro=true";
import { default as filesvUrLZNGHmMMeta } from "/vercel/path0/apps/zefire-spa/pages/invoices/[uuid]/files.vue?macro=true";
import { default as indexAIFcvkI1ydMeta } from "/vercel/path0/apps/zefire-spa/pages/invoices/[uuid]/index.vue?macro=true";
import { default as _91uuid_93Z4kl0UupXTMeta } from "/vercel/path0/apps/zefire-spa/pages/invoices/[uuid].vue?macro=true";
import { default as invoicesfDBeYEGj7dMeta } from "/vercel/path0/apps/zefire-spa/pages/invoices.vue?macro=true";
import { default as indexah9GHoIt7gMeta } from "/vercel/path0/apps/zefire-spa/pages/invoicing/index.vue?macro=true";
import { default as invoicesYDMeWgfCNdMeta } from "/vercel/path0/apps/zefire-spa/pages/invoicing/invoices.vue?macro=true";
import { default as invoicingwevQYbygojMeta } from "/vercel/path0/apps/zefire-spa/pages/invoicing.vue?macro=true";
import { default as counterpartsIFjO2Dwct4Meta } from "/vercel/path0/apps/zefire-spa/pages/marketplace/counterparts.vue?macro=true";
import { default as indexMfdZoEGcBDMeta } from "/vercel/path0/apps/zefire-spa/pages/marketplace/index.vue?macro=true";
import { default as marketplacePYGDqQAvloMeta } from "/vercel/path0/apps/zefire-spa/pages/marketplace.vue?macro=true";
import { default as _91aggregation_93wPdem2iH1TMeta } from "/vercel/path0/apps/zefire-spa/pages/metrics/[aggregation].vue?macro=true";
import { default as assetsvfNpNKHWHXMeta } from "/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/assets.vue?macro=true";
import { default as calendarbAR7lJjs02Meta } from "/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/calendar.vue?macro=true";
import { default as contractsFQLF9k2AfJMeta } from "/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/contracts.vue?macro=true";
import { default as datahealthphmRJpvmekMeta } from "/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/datahealth.vue?macro=true";
import { default as datastatusQD3estD2sxMeta } from "/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/datastatus.vue?macro=true";
import { default as files3HGRjtHKXIMeta } from "/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/files.vue?macro=true";
import { default as indexeihIrc2WxnMeta } from "/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/index.vue?macro=true";
import { default as invoicesiQ2v99AmcGMeta } from "/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/invoices.vue?macro=true";
import { default as notesZRmkZberTJMeta } from "/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/notes.vue?macro=true";
import { default as supervisionmtFiGTZ7qHMeta } from "/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/supervision.vue?macro=true";
import { default as _91uuid_93cTazIdxA4tMeta } from "/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid].vue?macro=true";
import { default as indexj8u6rhGUJaMeta } from "/vercel/path0/apps/zefire-spa/pages/portfolio/index.vue?macro=true";
import { default as portfolioHY88Rt1bvzMeta } from "/vercel/path0/apps/zefire-spa/pages/portfolio.vue?macro=true";
import { default as settingsnFfravseHHMeta } from "/vercel/path0/apps/zefire-spa/pages/settings.vue?macro=true";
import { default as ui_45testingpnIVJvEErnMeta } from "/vercel/path0/apps/zefire-spa/pages/ui-testing.vue?macro=true";
export default [
  {
    name: "auth",
    path: "/auth",
    component: () => import("/vercel/path0/apps/zefire-spa/pages/auth.vue"),
    children: [
  {
    name: "auth-action",
    path: ":action()",
    component: () => import("/vercel/path0/apps/zefire-spa/pages/auth/[action].vue")
  }
]
  },
  {
    name: "commissioning",
    path: "/commissioning",
    meta: commissioningrYIznb9uxOMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/commissioning.vue")
  },
  {
    name: "companies",
    path: "/companies",
    component: () => import("/vercel/path0/apps/zefire-spa/pages/companies.vue"),
    children: [
  {
    name: "companies-kind",
    path: ":kind()",
    component: () => import("/vercel/path0/apps/zefire-spa/pages/companies/[kind].vue"),
    children: [
  {
    name: _91uuid_93PIiDSS9biyMeta?.name,
    path: ":uuid()",
    component: () => import("/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid].vue"),
    children: [
  {
    name: "companies-kind-uuid-aggregation",
    path: ":aggregation()",
    meta: _91aggregation_937n1s9zlrSmMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/[aggregation].vue")
  },
  {
    name: "companies-kind-uuid-contracts",
    path: "contracts",
    meta: contractsJdvtoCQX0IMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/contracts.vue")
  },
  {
    name: "companies-kind-uuid-datahealth",
    path: "datahealth",
    component: () => import("/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/datahealth.vue")
  },
  {
    name: "companies-kind-uuid-files",
    path: "files",
    meta: filesZ3c99e9I3pMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/files.vue")
  },
  {
    name: "companies-kind-uuid",
    path: "",
    meta: index5byCkC794XMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/index.vue")
  },
  {
    name: "companies-kind-uuid-invoices",
    path: "invoices",
    meta: invoicesKV0wWjTzGkMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/invoices.vue")
  },
  {
    name: "companies-kind-uuid-notes",
    path: "notes",
    meta: notesO9vBXAkWKbMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/notes.vue")
  },
  {
    name: "companies-kind-uuid-portfolio",
    path: "portfolio",
    meta: portfolioz9QvnqNoq3Meta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/portfolio.vue")
  },
  {
    name: "companies-kind-uuid-supervision",
    path: "supervision",
    component: () => import("/vercel/path0/apps/zefire-spa/pages/companies/[kind]/[uuid]/supervision.vue")
  }
]
  }
]
  }
]
  },
  {
    name: contractsvOjlVtwGLyMeta?.name,
    path: "/contracts",
    component: () => import("/vercel/path0/apps/zefire-spa/pages/contracts.vue"),
    children: [
  {
    name: _91uuid_932mlo2nHQn5Meta?.name,
    path: ":uuid()",
    meta: _91uuid_932mlo2nHQn5Meta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/contracts/[uuid].vue"),
    children: [
  {
    name: "contracts-uuid-files",
    path: "files",
    meta: filesCn9tqoRc75Meta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/contracts/[uuid]/files.vue")
  },
  {
    name: "contracts-uuid",
    path: "",
    component: () => import("/vercel/path0/apps/zefire-spa/pages/contracts/[uuid]/index.vue")
  },
  {
    name: "contracts-uuid-invoices",
    path: "invoices",
    meta: invoicesSDRcaoR5nHMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/contracts/[uuid]/invoices.vue")
  },
  {
    name: "contracts-uuid-notes",
    path: "notes",
    meta: notesFBPkpO5CsgMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/contracts/[uuid]/notes.vue")
  }
]
  },
  {
    name: "contracts",
    path: "",
    meta: indexBS57a8fycaMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/contracts/index.vue")
  }
]
  },
  {
    name: dashboarduLFks7k62nMeta?.name,
    path: "/dashboard",
    meta: dashboarduLFks7k62nMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard-calendar",
    path: "calendar",
    meta: calendar8zVESAAD4MMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/dashboard/calendar.vue")
  },
  {
    name: "dashboard-datahealth",
    path: "datahealth",
    component: () => import("/vercel/path0/apps/zefire-spa/pages/dashboard/datahealth.vue")
  },
  {
    name: "dashboard-files",
    path: "files",
    meta: fileslSMVyRo1HQMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/dashboard/files.vue")
  },
  {
    name: "dashboard",
    path: "",
    meta: indexJJGaVJsD2qMeta || {},
    alias: ["/"],
    component: () => import("/vercel/path0/apps/zefire-spa/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-market",
    path: "market",
    meta: marketLlajF6LQGyMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/dashboard/market.vue")
  },
  {
    name: "dashboard-notes",
    path: "notes",
    meta: notes4Pa0fyC8CGMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/dashboard/notes.vue")
  },
  {
    name: "dashboard-supervision",
    path: "supervision",
    meta: supervisiontVwwBXeZkMMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/dashboard/supervision.vue")
  },
  {
    name: "dashboard-techno",
    path: "techno",
    meta: technoC0PyJ7WkObMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/dashboard/techno.vue")
  }
]
  },
  {
    name: "datastudio",
    path: "/datastudio",
    meta: datastudiog8qVm4NZ1SMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/datastudio.vue")
  },
  {
    name: "docs",
    path: "/docs",
    meta: docsjtarmfBB1JMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/docs.vue"),
    children: [
  {
    name: "docs-notionId",
    path: ":notionId?",
    component: () => import("/vercel/path0/apps/zefire-spa/pages/docs/[[notionId]].vue")
  }
]
  },
  {
    name: "error",
    path: "/error",
    meta: errorZq9LRN1aDJMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/error.vue")
  },
  {
    name: "invoices",
    path: "/invoices",
    component: () => import("/vercel/path0/apps/zefire-spa/pages/invoices.vue"),
    children: [
  {
    name: _91uuid_93Z4kl0UupXTMeta?.name,
    path: ":uuid()",
    meta: _91uuid_93Z4kl0UupXTMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/invoices/[uuid].vue"),
    children: [
  {
    name: "invoices-uuid-files",
    path: "files",
    meta: filesvUrLZNGHmMMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/invoices/[uuid]/files.vue")
  },
  {
    name: "invoices-uuid",
    path: "",
    component: () => import("/vercel/path0/apps/zefire-spa/pages/invoices/[uuid]/index.vue")
  }
]
  }
]
  },
  {
    name: invoicingwevQYbygojMeta?.name,
    path: "/invoicing",
    meta: invoicingwevQYbygojMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/invoicing.vue"),
    children: [
  {
    name: "invoicing",
    path: "",
    component: () => import("/vercel/path0/apps/zefire-spa/pages/invoicing/index.vue")
  },
  {
    name: "invoicing-invoices",
    path: "invoices",
    meta: invoicesYDMeWgfCNdMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/invoicing/invoices.vue")
  }
]
  },
  {
    name: marketplacePYGDqQAvloMeta?.name,
    path: "/marketplace",
    meta: marketplacePYGDqQAvloMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/marketplace.vue"),
    children: [
  {
    name: "marketplace-counterparts",
    path: "counterparts",
    component: () => import("/vercel/path0/apps/zefire-spa/pages/marketplace/counterparts.vue")
  },
  {
    name: "marketplace",
    path: "",
    component: () => import("/vercel/path0/apps/zefire-spa/pages/marketplace/index.vue")
  }
]
  },
  {
    name: "metrics-aggregation",
    path: "/metrics/:aggregation()",
    meta: _91aggregation_93wPdem2iH1TMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/metrics/[aggregation].vue")
  },
  {
    name: portfolioHY88Rt1bvzMeta?.name,
    path: "/portfolio",
    meta: portfolioHY88Rt1bvzMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/portfolio.vue"),
    children: [
  {
    name: _91uuid_93cTazIdxA4tMeta?.name,
    path: ":uuid()",
    meta: _91uuid_93cTazIdxA4tMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid].vue"),
    children: [
  {
    name: "portfolio-uuid-assets",
    path: "assets",
    component: () => import("/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/assets.vue")
  },
  {
    name: "portfolio-uuid-calendar",
    path: "calendar",
    meta: calendarbAR7lJjs02Meta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/calendar.vue")
  },
  {
    name: "portfolio-uuid-contracts",
    path: "contracts",
    component: () => import("/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/contracts.vue")
  },
  {
    name: "portfolio-uuid-datahealth",
    path: "datahealth",
    meta: datahealthphmRJpvmekMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/datahealth.vue")
  },
  {
    name: "portfolio-uuid-datastatus",
    path: "datastatus",
    component: () => import("/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/datastatus.vue")
  },
  {
    name: "portfolio-uuid-files",
    path: "files",
    meta: files3HGRjtHKXIMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/files.vue")
  },
  {
    name: "portfolio-uuid",
    path: "",
    component: () => import("/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/index.vue")
  },
  {
    name: "portfolio-uuid-invoices",
    path: "invoices",
    meta: invoicesiQ2v99AmcGMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/invoices.vue")
  },
  {
    name: "portfolio-uuid-notes",
    path: "notes",
    meta: notesZRmkZberTJMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/notes.vue")
  },
  {
    name: "portfolio-uuid-supervision",
    path: "supervision",
    component: () => import("/vercel/path0/apps/zefire-spa/pages/portfolio/[uuid]/supervision.vue")
  }
]
  },
  {
    name: "portfolio",
    path: "",
    meta: indexj8u6rhGUJaMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/portfolio/index.vue")
  }
]
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsnFfravseHHMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/settings.vue")
  },
  {
    name: "ui-testing",
    path: "/ui-testing",
    meta: ui_45testingpnIVJvEErnMeta || {},
    component: () => import("/vercel/path0/apps/zefire-spa/pages/ui-testing.vue")
  }
]