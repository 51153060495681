import Highcharts from "highcharts"
import HighchartsVue, { Chart } from "highcharts-vue"

import boost from "highcharts/modules/boost"
import stockChart from "highcharts/modules/stock"
import exporting from "highcharts/modules/exporting"
import exportData from "highcharts/modules/export-data"
import histogram from "highcharts/modules/histogram-bellcurve"
import More from "highcharts/highcharts-more"

import type { Options as HighchartOptions } from "highcharts"

const defaultHighchartOptions: HighchartOptions = {
  rangeSelector: {
    buttons: [
      { type: "day", count: 1, text: "1d" },
      { type: "day", count: 15, text: "15d" },
      { type: "month", count: 1, text: "1m" },
      { type: "month", count: 3, text: "3m" },
      { type: "month", count: 5, text: "5m" },
      { type: "ytd", text: "YTD", title: "View year to date" },
      { type: "year", count: 1, text: "1y" },
      { type: "all", text: "All" },
    ],
    inputEnabled: false, // it supports only days
    selected: 7, // all
    buttonTheme: {
      width: 30,
      fill: "none",
      stroke: "none",
      "stroke-width": 0,
      r: 8,
    },
  },
  title: { text: "" },
  credits: { enabled: false },
  accessibility: { enabled: false },
  navigator: {
    enabled: false,
    maskInside: false,
    xAxis: { visible: false },
  },

  scrollbar: { enabled: false },
  responsive: {
    rules: [
      {
        chartOptions: { title: { text: "", floating: true } },
        condition: { maxWidth: 768 },
      },
      {
        condition: { maxWidth: 500 },
        chartOptions: {
          legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "bottom",
            floating: false,
            margin: 20,
          },
        },
      },
    ],
  },
  colors: palette,
  chart: {
    // @ts-expect-error : wrongly typed from highchart
    zoomType: "x",
  },
  time: {
    useUTC: false, // display data in browser timezone
  },
  yAxis: { opposite: false },
  xAxis: {
    type: "datetime",
  },
  tooltip: {
    enabled: true,
    animation: true,
    split: true,
    shared: false,
    valueDecimals: 2,
  },
  boost: {
    enabled: true,
    // useGPUTranslations: true,  // unadvised for timestamps xAxis
    usePreallocated: true,
  },
  plotOptions: {
    series: {
      marker: { enabled: false },
      states: { hover: { enabled: true }, select: { enabled: true }, inactive: { opacity: 1 } },
      dataGrouping: {
        enabled: true,
        firstAnchor: "start",
        lastAnchor: "start",
        dateTimeLabelFormats: {
          // millisecond: ["%A, %b %e, %H:%M:%S.%L", "%A, %b %e, %H:%M:%S.%L", ""],
          second: ["%A, %b %e, %H:%M", "%A, %b %e, %H:%M", ""],
          minute: ["%A, %b %e, %H:%M", "%A, %b %e, %H:%M", ""],
          hour: ["%A, %b %e, %H:%M", "%A, %b %e, %H:%M", ""],
          day: ["%A, %b %e, %Y", "%A, %b %e", ""],
          week: ["Week from %A, %b %e, %Y", "%A, %b %e", ""],
          month: ["%B %Y", "%B", ""],
          year: ["%Y", "%Y", ""],
        },
      },
    },
    area: {
      marker: {
        enabled: false,
        symbol: "circle",
        radius: 2,
      },
      fillOpacity: 0.5,
      lineWidth: 2,
    },
  },
  legend: {
    enabled: true,
    layout: "vertical",
    align: "right",
    verticalAlign: "middle",
    floating: false,
  },
  exporting: {
    enabled: true,
    sourceWidth: 1800,
    sourceHeight: 700,
  },
}
export default defineNuxtPlugin({
  name: "highchart",
  enforce: "pre",
  async setup(nuxtApp) {
    nuxtApp.vueApp.use(HighchartsVue as any)

    boost(Highcharts)

    exporting(Highcharts)
    exportData(Highcharts)

    histogram(Highcharts)
    stockChart(Highcharts)

    More(Highcharts)

    // Need to be done after registration of highcharts modules:
    Highcharts.setOptions(defaultHighchartOptions)

    Highcharts.removeEvent(Highcharts.Chart, "beforeShowResetZoom") // see https://github.com/highcharts/highcharts/issues/10320#issuecomment-806303951

    nuxtApp.vueApp.component("highchart", Chart)
    // this is the equivalent of a normal functional plugin
  },
})
