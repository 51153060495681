import * as Sentry from "@sentry/vue"
import * as pkg from "~/package.json"

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()

  if (!config.public.sentry.dsn) {
    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: config.public.sentry.dsn,
    release: `zefire-front@${pkg.version}`,
    environment: config.public.sentry.env,
    integrations: [
      Sentry.captureConsoleIntegration(),
      Sentry.browserTracingIntegration({ router: useRouter() }),
      Sentry.vueIntegration({
        tracingOptions: {
          trackComponents: true,
          hooks: ["activate", "create", "unmount", "mount", "update"],
          timeout: 5000, // milliseconds
        },
      }),
      Sentry.replayIntegration({
        stickySession: true,
        networkDetailAllowUrls: [
          window.location.origin,
          ...config.public.sentry.networkDetailAllowUrls,
        ],
        // https://docs.sentry.io/platforms/javascript/guides/vue/session-replay/configuration/#privacy-configuration
        // By default, don't mask replay data.
        // Only elements with ['.sentry-mask', '[data-sentry-mask]'] will be mased.
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: true,
        //
        networkRequestHeaders: ["Cache-Control"],
        networkResponseHeaders: [
          "Referrer-Policy",
          "x-process-time",
          "x-request-id",
          "x-zefire-version",
        ],
      }),
      Sentry.browserProfilingIntegration(),
      Sentry.feedbackIntegration({
        colorScheme: "system",
      }),
    ],
    tracePropagationTargets: config.public.sentry.tracePropagationTargets as string[],

    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    tracesSampleRate: config.public.sentry.tracesSampleRate,
    profilesSampleRate: 1.0, // is 100% of tracesSampleRate

    // Capture Replay for 10% of all sessions, plus for 100% of sessions with an error
    replaysSessionSampleRate: config.public.sentry.replaysSessionSampleRate,
    replaysOnErrorSampleRate: config.public.sentry.replaysOnErrorSampleRate,

    trackComponents: true,
    logErrors: true, // still log errors in console
  })
})
