export default defineAppConfig({
  icon: {
    mode: "css", // default <Icon> mode applied
    aliases: {
      // nuxt: "logos:nuxt-icon",
    },
  },
  ui: {
    // prevent the use of `tailwind-merge` while icons handling is not done properly by nuxt/ui
    // this forces us to redefine every entries when changing defaults
    strategy: "override",

    primary: "rnxblue",
    secondary: "rnxgreen",
    accent: "rnxorange",

    // default on components:
    button: {
      color: {
        cool: {
          solid:
            "shadow-sm ring-1 ring-inset ring-cool-300 dark:ring-cool-700 text-cool-700 dark:text-cool-200 bg-cool-50 hover:bg-cool-100 disabled:bg-cool-50 dark:bg-cool-800 dark:hover:bg-cool-700/50 dark:disabled:bg-cool-800 focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400",
          ghost:
            "text-cool-500 dark:text-cool-200 hover:text-cool-700 dark:hover:text-white hover:bg-cool-50 dark:hover:bg-cool-800 focus-visible:ring-inset focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400",
        },
        slate: {
          solid:
            "shadow-sm ring-1 ring-inset ring-solid-300 dark:ring-solid-700 text-solid-700 dark:text-solid-200 bg-solid-50 hover:bg-solid-100 disabled:bg-solid-50 dark:bg-solid-800 dark:hover:bg-solid-700/50 dark:disabled:bg-solid-800 focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400",
          ghost:
            "text-slate-500 dark:text-slate-200 hover:text-slate-700 dark:hover:text-white hover:bg-slate-50 dark:hover:bg-slate-800 focus-visible:ring-inset focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400",
        },
        stone: {
          solid:
            "shadow-sm ring-1 ring-inset ring-stone-300 dark:ring-stone-700 text-stone-700 dark:text-stone-200 bg-stone-50 hover:bg-stone-100 disabled:bg-stone-50 dark:bg-stone-800 dark:hover:bg-stone-700/50 dark:disabled:bg-stone-800 focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400",
          ghost:
            "text-stone-500 dark:text-stone-200 hover:text-stone-700 dark:hover:text-white hover:bg-stone-50 dark:hover:bg-stone-800 focus-visible:ring-inset focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400",
        },
        zinc: {
          solid:
            "shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 text-zinc-700 dark:text-zinc-200 bg-zinc-50 hover:bg-zinc-100 disabled:bg-zinc-50 dark:bg-zinc-800 dark:hover:bg-zinc-700/50 dark:disabled:bg-zinc-800 focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400",
          ghost:
            "text-zinc-500 dark:text-zinc-200 hover:text-zinc-700 dark:hover:text-white hover:bg-zinc-50 dark:hover:bg-zinc-800 focus-visible:ring-inset focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400",
        },
      },
      icon: {
        base: "flex-shrink-0",
      },
    },
    dropdown: {
      item: {
        icon: {
          base: "flex-shrink-0 text-base",
        },
      },
    },
    input: {
      placeholder: "placeholder-cool-400 dark:placeholder-cool-500",
    },
    card: {
      base: "m-0",
      background: "bg-gray-50 dark:bg-gray-900",
      divide: "divide-y divide-gray-200 dark:divide-gray-800",
      ring: "ring-0 ring-gray-200 dark:ring-gray-800",
      rounded: "rounded-sm",
      shadow: "shadow-0",
      body: {
        padding: "px-4 py-2 ",
      },
      header: {
        base: "font-medium text-rnxblue-600 ",
        padding: "px-2 py-2 sm:px-5",
      },
      footer: {
        padding: "px-2 py-2 sm:px-2",
      },
    },
    table: {
      base: "bg-gray-50",
      td: {
        size: "text-sm",
        padding: "px-2 py-2",
      },
    },
  },
})
