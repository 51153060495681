import type { DateRangeSource } from "@angelblanco/v-calendar/dist/types/src/utils/date/range.js"
import type { DayInMonth } from "@angelblanco/v-calendar/dist/types/src/utils/date/helpers.js"

import * as R from "remeda"
import * as dt from "date-fns"
import { Freq, HorizonLabel } from "./schemas"

export const tz = "Europe/Brussels"

export function toDateTZ(timestamp: string | number | Date): Date {
  let sainTimestamp: Date

  if (R.isNumber(timestamp)) {
    sainTimestamp = dt.fromUnixTime(timestamp)
  } else if (R.isString(timestamp)) {
    sainTimestamp = dt.parseISO(timestamp)
  } else {
    sainTimestamp = timestamp
  }
  return sainTimestamp
}

export function formatDate(
  timestamp: Date | string | number | undefined | null,
  full?: boolean,
): string {
  if (!timestamp) {
    return ""
  }

  if (R.isString(timestamp)) {
    timestamp = dt.parseISO(timestamp)
  } else if (R.isNumber(timestamp)) {
    timestamp = dt.fromUnixTime(timestamp)
  }

  const fmt = full ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd"
  return dt.format(timestamp, fmt)
}

export function diffToDuration(end: string | Date, start: string | Date) {
  let diff = dt.differenceInMilliseconds(toDateTZ(end), toDateTZ(start)) / 1000

  // calculate (and subtract) whole days
  const days = Math.floor(diff / 86400)
  diff -= days * 86400

  // calculate (and subtract) whole hours
  const hours = Math.floor(diff / 3600) % 24
  diff -= hours * 3600

  // calculate (and subtract) whole minutes
  const minutes = Math.floor(diff / 60) % 60

  return `${days}d ${hours}h ${minutes}m`
}

/**
 * Generate an Interval for the "Year to Date",
 * correctly enlarging when it is still the beginning of the year (not yet march).
 */
export function defaultYTDInterval(): { start: Date; end: Date } {
  const nMonthsSlide = 3
  const now = new Date()
  let start: Date
  let end: Date
  if (now < dt.addMonths(dt.startOfYear(now), nMonthsSlide)) {
    start = dt.startOfYear(dt.addYears(now, -1))
    end = dt.startOfMonth(dt.addMonths(now, 1))
  } else {
    start = dt.startOfYear(now)
    end = dt.startOfYear(dt.addYears(now, 1))
  }

  return { start, end }
}

const now = () => new Date()

export const dth: Record<HorizonLabel, () => [Date, Date]> = {
  [HorizonLabel.enum.ytd]: () => [dt.startOfYear(now()), dt.startOfDay(now())],
  [HorizonLabel.enum.yt_last_month]: () => {
    if (now().getMonth() === 0) {
      // if in january, return last year instead
      return [dt.addYears(dt.startOfYear(now()), -1), dt.startOfYear(now())]
    } else {
      return [dt.startOfYear(now()), dt.startOfMonth(now())]
    }
  },
  [HorizonLabel.enum.yt_quarter]: () => {
    if (now().getMonth() < 2) {
      return [dt.startOfYear(now()), dt.addMonths(dt.startOfYear(now()), 3)]
    } else {
      return [dt.startOfYear(now()), dt.startOfQuarter(now())]
    }
  },
  [HorizonLabel.enum.last_year]: () => [
    dt.addYears(dt.startOfYear(now()), -1),
    dt.startOfYear(now()),
  ],
  [HorizonLabel.enum.rolling_year]: () => [
    dt.addDays(dt.startOfDay(now()), -365),
    dt.startOfDay(now()),
  ],
  // Months
  [HorizonLabel.enum.current_month]: () => [
    dt.startOfMonth(now()),
    dt.addMonths(dt.startOfMonth(now()), 1),
  ],
  [HorizonLabel.enum.last_month]: () => [
    dt.addMonths(dt.startOfMonth(now()), -1),
    dt.startOfMonth(now()),
  ],
  [HorizonLabel.enum.rolling_month]: () => [
    dt.addDays(dt.startOfDay(now()), -30),
    dt.startOfDay(now()),
  ],
  // Weeks
  [HorizonLabel.enum.current_week]: () => [
    dt.previousMonday(dt.startOfDay(now())),
    dt.nextMonday(dt.startOfDay(now())),
  ],
  [HorizonLabel.enum.last_week]: () => [
    dt.previousMonday(dt.previousMonday(dt.startOfDay(now()))),
    dt.previousMonday(dt.startOfDay(now())),
  ],
  [HorizonLabel.enum.rolling_week]: () => [
    dt.addDays(dt.startOfDay(now()), -7),
    dt.startOfDay(now()),
  ],
  // Custom
  [HorizonLabel.enum.custom]: () => [dt.startOfDay(now()), dt.startOfDay(now())],
  // Days
  [HorizonLabel.enum.today]: () => [dt.startOfDay(now()), dt.addDays(dt.startOfDay(now()), 1)],
  [HorizonLabel.enum.yesterday]: () => [dt.addDays(dt.startOfDay(now()), -1), dt.startOfDay(now())],
  // Rolling PRs
  [HorizonLabel.enum.rolling_0_30]: () => [
    dt.addDays(dt.startOfDay(now()), -30),
    dt.startOfDay(now()),
  ],
  [HorizonLabel.enum.rolling_0_90]: () => [
    dt.addDays(dt.startOfDay(now()), -90),
    dt.startOfDay(now()),
  ],
  [HorizonLabel.enum.rolling_30_60]: () => [
    dt.addDays(dt.startOfDay(now()), -60),
    dt.addDays(dt.startOfDay(now()), -30),
  ],
  [HorizonLabel.enum.rolling_30_120]: () => [
    dt.addDays(dt.startOfDay(now()), -120),
    dt.addDays(dt.startOfDay(now()), -30),
  ],
}

export const mapFreqDisabledDates: Partial<Record<Freq, DateRangeSource[]>> = {
  [Freq.enum["1MS"]]: [
    { repeat: { days: Array.from({ length: 30 }, (_, i) => i + 2) as DayInMonth[] } },
  ],
}

function getCurrentYearOrLastSixMonths(): Date {
  const currentYearStart = dt.startOfYear(new Date())
  const sixMonthsAgo = dt.sub(dt.startOfMonth(new Date()), { months: 6 })
  return dt.min([currentYearStart, sixMonthsAgo])
}

export const CurrentYearOrLastSixMonthsHorizonSchema = z.object({
  start: z.coerce.date().default(getCurrentYearOrLastSixMonths()),
  end: z.coerce.date().default(dt.startOfDay(new Date())),
})
