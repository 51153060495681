import revive_payload_client_nhjApmah46 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_encoding@0.1.13_eslint@9.17.0_3axlnwpasej3brneme4uyiwgiq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_EqMGrQPwcN from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_encoding@0.1.13_eslint@9.17.0_3axlnwpasej3brneme4uyiwgiq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_62b83oTu3V from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_encoding@0.1.13_eslint@9.17.0_3axlnwpasej3brneme4uyiwgiq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import highchart_J5yQudteOk from "/vercel/path0/apps/zefire-spa/plugins/highchart.ts";
import navigation_repaint_client_41ZfdGXiym from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_encoding@0.1.13_eslint@9.17.0_3axlnwpasej3brneme4uyiwgiq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_gkFmAOGfkz from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_encoding@0.1.13_eslint@9.17.0_3axlnwpasej3brneme4uyiwgiq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_B5xk0ziSn4 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_encoding@0.1.13_eslint@9.17.0_3axlnwpasej3brneme4uyiwgiq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_GuQ4aLTsQ1 from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.29.1_typescript@5.7.2_vue@3.5.13_typescript@5.7.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/zefire-spa/.nuxt/components.plugin.mjs";
import prefetch_client_0Yt94V1GIH from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_encoding@0.1.13_eslint@9.17.0_3axlnwpasej3brneme4uyiwgiq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_iRfgrHOdYJ from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.21.0_axios@1.7.9_change-case@5.4.4_magicast@0.3.5_nprogress@0.2.0_rollup@4.29.1_ty_hh5evb6uu26zmdkf7yg5lrscw4/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_HZ7tJtiwRk from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.21.0_axios@1.7.9_change-case@5.4.4_magicast@0.3.5_nprogress@0.2.0_rollup@4.29.1_ty_hh5evb6uu26zmdkf7yg5lrscw4/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_Az2Fb6XXKz from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.21.0_axios@1.7.9_change-case@5.4.4_magicast@0.3.5_nprogress@0.2.0_rollup@4.29.1_ty_hh5evb6uu26zmdkf7yg5lrscw4/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_iTMze7Qqk7 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.29.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_8Stft8F1xf from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.29.1_vite@6.0.7_@types+node@22.10.5_jiti@2.4.2_sass_hkesmjwy7corb7glojqdqnbyie/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import i18n_v4cz3C3PMp from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.2.0_magicast@0.3.5_rollup@4.29.1_vue@3.5.13_typescript@5.7.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import auth_vT9JWWT9pN from "/vercel/path0/apps/zefire-spa/plugins/auth.ts";
import custom_directives_gtEpGpM7lF from "/vercel/path0/apps/zefire-spa/plugins/custom-directives.ts";
import nprogress_OPYCKjD1DE from "/vercel/path0/apps/zefire-spa/plugins/nprogress.ts";
import sentry_3AyO8nEfhE from "/vercel/path0/apps/zefire-spa/plugins/sentry.ts";
import tippy_xFBYqfAPCu from "/vercel/path0/apps/zefire-spa/plugins/tippy.ts";
import v_calendar_dxaVzst4iS from "/vercel/path0/apps/zefire-spa/plugins/v-calendar.ts";
import vue_query_wrmMkNpEpe from "/vercel/path0/apps/zefire-spa/plugins/vue-query.ts";
export default [
  revive_payload_client_nhjApmah46,
  unhead_EqMGrQPwcN,
  router_62b83oTu3V,
  highchart_J5yQudteOk,
  navigation_repaint_client_41ZfdGXiym,
  check_outdated_build_client_gkFmAOGfkz,
  chunk_reload_client_B5xk0ziSn4,
  plugin_vue3_GuQ4aLTsQ1,
  components_plugin_KR1HBZs4kY,
  prefetch_client_0Yt94V1GIH,
  slideovers_iRfgrHOdYJ,
  modals_HZ7tJtiwRk,
  colors_Az2Fb6XXKz,
  plugin_client_iTMze7Qqk7,
  plugin_8Stft8F1xf,
  i18n_v4cz3C3PMp,
  auth_vT9JWWT9pN,
  custom_directives_gtEpGpM7lF,
  nprogress_OPYCKjD1DE,
  sentry_3AyO8nEfhE,
  tippy_xFBYqfAPCu,
  v_calendar_dxaVzst4iS,
  vue_query_wrmMkNpEpe
]