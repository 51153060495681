/* eslint-disable */
import { Zodios, type ZodiosOptions, makeApi } from "@zodios/core";
import { z } from "zod";

export const CompanyKind = z.enum([
  "company",
  "assetowner",
  "assetmanager",
  "holding",
  "offtaker",
  "tso",
  "dso",
]);
export type CompanyKind = z.infer<typeof CompanyKind>;

export const CompanySchemaShort = z.object({
  uuid: z.string().uuid(),
  kind: CompanyKind,
  name: z.string(),
  name_short: z.union([z.string(), z.null()]).nullish(),
});
export type CompanySchemaShort = z.infer<typeof CompanySchemaShort>;

export const MeilisearchInfos = z.object({
  datastudio_index: z.string(),
  topology_index: z.string(),
  api_key: z.union([z.string(), z.null()]).nullish(),
});
export type MeilisearchInfos = z.infer<typeof MeilisearchInfos>;

export const User = z.object({
  sub: z.string(),
  email: z.union([z.string(), z.null()]),
  name: z.union([z.string(), z.null()]),
  permissions: z.array(
    z.union([
      z.enum([
        "read:company",
        "write:company",
        "read:contract",
        "write:contract",
        "read:document",
        "write:document",
        "upload:document",
        "read:event",
        "write:event",
        "read:invoice",
        "write:invoice",
        "write:invoice_accounted",
        "read:market",
        "write:market",
        "read:note",
        "write:note",
        "read:supervision",
        "write:supervision",
        "read:tender",
        "write:tender",
        "read:datastudio",
        "read:finance",
        "read:partner",
        "read:timeseries",
        "write:datahealth",
        "write:geo",
        "write:goldensource",
        "write:metric",
      ]),
      z.string(),
    ])
  ),
  org_id: z.string(),
  company: CompanySchemaShort,
  meilisearch: z.union([MeilisearchInfos, z.null()]).nullish(),
});
export type User = z.infer<typeof User>;

export const sites = z.union([z.array(z.string()), z.null()]).nullish();
export type sites = z.infer<typeof sites>;

export const company_uuid = z.union([z.string(), z.null()]).nullish();
export type company_uuid = z.infer<typeof company_uuid>;

export const ValidationError = z.object({
  loc: z.array(z.union([z.string(), z.number()])),
  msg: z.string(),
  type: z.string(),
});
export type ValidationError = z.infer<typeof ValidationError>;

export const HTTPValidationError = z.object({
  detail: z.array(ValidationError).nullish(),
});
export type HTTPValidationError = z.infer<typeof HTTPValidationError>;

export const freq = z.union([z.string(), z.null()]).nullish().default("1YS");
export type freq = z.infer<typeof freq>;

export const TimeSeries = z.object({
  index: z.array(z.number()).nullish(),
  data: z.array(z.number()).nullish(),
  name: z.union([z.string(), z.null()]).nullish(),
});
export type TimeSeries = z.infer<typeof TimeSeries>;

export const SiteSummarySchema = z.object({
  capacity: z.number(),
  site_number: z.number().int(),
});
export type SiteSummarySchema = z.infer<typeof SiteSummarySchema>;

export const ContractStatus = z.enum([
  "no_status",
  "connection_requested",
  "commissioned",
  "form_filling",
  "under_verification",
  "contract_available",
  "signed",
  "countersigned",
  "litigation",
  "transferred",
]);
export type ContractStatus = z.infer<typeof ContractStatus>;

export const ContractSummarySchema = z.object({
  contract_number: z.number().int(),
  contract_status: ContractStatus,
});
export type ContractSummarySchema = z.infer<typeof ContractSummarySchema>;

export const AddressSchema = z.object({
  uuid: z.string().uuid(),
  name: z.union([z.string(), z.null()]).nullish(),
  address: z.union([z.string(), z.null()]).nullish(),
  address_extra: z.union([z.string(), z.null()]).nullish(),
  postal_box: z.union([z.string(), z.null()]).nullish(),
  city: z.union([z.string(), z.null()]).nullish(),
  postal_code: z.union([z.string(), z.null()]).nullish(),
  country: z.union([z.string(), z.null()]).nullish(),
});
export type AddressSchema = z.infer<typeof AddressSchema>;

export const CloudImageUrl = z.string();
export type CloudImageUrl = z.infer<typeof CloudImageUrl>;

export const ContactCloudImages = z.object({
  signature: z.union([CloudImageUrl, z.null()]).nullish(),
  avatar: CloudImageUrl.min(1).max(2083).url().nullish(),
});
export type ContactCloudImages = z.infer<typeof ContactCloudImages>;

export const ContactInfoSchema = z.object({
  uuid: z.string().uuid(),
  firstname: z.union([z.string(), z.null()]).nullish(),
  lastname: z.union([z.string(), z.null()]).nullish(),
  team_name: z.union([z.string(), z.null()]).nullish(),
  position: z.union([z.string(), z.null()]).nullish(),
  email: z.union([z.string(), z.null()]).nullish(),
  company_phone: z.union([z.string(), z.null()]).nullish(),
  mobile_phone: z.union([z.string(), z.null()]).nullish(),
  cloud_image: z.union([ContactCloudImages, z.null()]).nullish(),
});
export type ContactInfoSchema = z.infer<typeof ContactInfoSchema>;

export const CompanySchema = z.object({
  uuid: z.string().uuid(),
  kind: CompanyKind,
  name: z.string(),
  name_short: z.union([z.string(), z.null()]).nullish(),
  url: z.union([z.string(), z.null()]).nullish(),
  registration_number: z.union([z.string(), z.null()]).nullish(),
  registration_office: z.union([z.string(), z.null()]).nullish(),
  vat_number: z.union([z.string(), z.null()]).nullish(),
  legal_type: z.union([z.string(), z.null()]).nullish(),
  iban_code: z.union([z.string(), z.null()]).nullish(),
  bic_code: z.union([z.string(), z.null()]).nullish(),
  address: z.union([AddressSchema, z.null()]).nullish(),
  contact_infos: z.array(ContactInfoSchema),
  external_id: z.union([z.string(), z.null()]).nullish(),
  accounting_id: z.union([z.string(), z.null()]).nullish(),
  is_external: z.union([z.boolean(), z.null()]).nullish(),
});
export type CompanySchema = z.infer<typeof CompanySchema>;

export const CompanyKindCreate = z.literal("offtaker");
export type CompanyKindCreate = z.infer<typeof CompanyKindCreate>;

export const CompanySchemaCreate = z.object({
  name: z.string(),
  name_short: z.union([z.string(), z.null()]).nullish(),
  url: z.union([z.string(), z.null()]).nullish(),
  registration_number: z.union([z.string(), z.null()]).nullish(),
  registration_office: z.union([z.string(), z.null()]).nullish(),
  vat_number: z.union([z.string(), z.null()]).nullish(),
  legal_type: z.union([z.string(), z.null()]).nullish(),
  iban_code: z.union([z.string(), z.null()]).nullish(),
  bic_code: z.union([z.string(), z.null()]).nullish(),
  address_uuid: z.union([z.string(), z.null()]).nullish(),
  contact_info_uuids: z.union([z.array(z.string()), z.null()]).nullish(),
  kind: CompanyKindCreate,
});
export type CompanySchemaCreate = z.infer<typeof CompanySchemaCreate>;

export const CompanySchemaWithPermissions = z.object({
  uuid: z.string().uuid(),
  kind: CompanyKind,
  name: z.string(),
  name_short: z.union([z.string(), z.null()]).nullish(),
  url: z.union([z.string(), z.null()]).nullish(),
  registration_number: z.union([z.string(), z.null()]).nullish(),
  registration_office: z.union([z.string(), z.null()]).nullish(),
  vat_number: z.union([z.string(), z.null()]).nullish(),
  legal_type: z.union([z.string(), z.null()]).nullish(),
  iban_code: z.union([z.string(), z.null()]).nullish(),
  bic_code: z.union([z.string(), z.null()]).nullish(),
  address: z.union([AddressSchema, z.null()]).nullish(),
  contact_infos: z.array(ContactInfoSchema),
  external_id: z.union([z.string(), z.null()]).nullish(),
  accounting_id: z.union([z.string(), z.null()]).nullish(),
  is_external: z.union([z.boolean(), z.null()]).nullish(),
  editable: z.boolean(),
});
export type CompanySchemaWithPermissions = z.infer<
  typeof CompanySchemaWithPermissions
>;

export const CompanySchemaUpdate = z.object({
  name: z.union([z.string(), z.null()]).nullish(),
  name_short: z.union([z.string(), z.null()]).nullish(),
  url: z.union([z.string(), z.null()]).nullish(),
  registration_number: z.union([z.string(), z.null()]).nullish(),
  registration_office: z.union([z.string(), z.null()]).nullish(),
  vat_number: z.union([z.string(), z.null()]).nullish(),
  legal_type: z.union([z.string(), z.null()]).nullish(),
  iban_code: z.union([z.string(), z.null()]).nullish(),
  bic_code: z.union([z.string(), z.null()]).nullish(),
  address_uuid: z.union([z.string(), z.null()]).nullish(),
  contact_info_uuids: z.union([z.array(z.string()), z.null()]).nullish(),
});
export type CompanySchemaUpdate = z.infer<typeof CompanySchemaUpdate>;

export const AccountingInfosSchema = z.object({
  uuid: z.string().uuid(),
  external_id: z.union([z.string(), z.null()]),
  accounting_id: z.union([z.string(), z.null()]),
});
export type AccountingInfosSchema = z.infer<typeof AccountingInfosSchema>;

export const AccountingInfosSchemaCreate = z.object({
  external_id: z.union([z.string(), z.null()]).nullish(),
  accounting_id: z.union([z.string(), z.null()]).nullish(),
});
export type AccountingInfosSchemaCreate = z.infer<
  typeof AccountingInfosSchemaCreate
>;

export const AccountingInfosSchemaUpdate = z.object({
  external_id: z.union([z.string(), z.null()]).nullish(),
  accounting_id: z.union([z.string(), z.null()]).nullish(),
});
export type AccountingInfosSchemaUpdate = z.infer<
  typeof AccountingInfosSchemaUpdate
>;

export const CountrySchema = z.object({
  name: z.union([z.string(), z.null()]).nullish(),
  code: z.string(),
});
export type CountrySchema = z.infer<typeof CountrySchema>;

export const TenderRequestCreate = z.object({
  external_id: z.string(),
  counterpart: z.string(),
  country: CountrySchema,
  deadline: z.coerce.date(),
  sites: z.union([z.array(z.string()), z.null()]).nullish(),
  folder: z.string(),
});
export type TenderRequestCreate = z.infer<typeof TenderRequestCreate>;

export const RegionSchema = z.object({
  name: z.union([z.string(), z.null()]).nullish(),
  code: z.string(),
  uuid: z.string().uuid(),
});
export type RegionSchema = z.infer<typeof RegionSchema>;

export const PortfolioSchema = z.object({
  name: z.string(),
  uuid: z.string().uuid(),
});
export type PortfolioSchema = z.infer<typeof PortfolioSchema>;

export const NetworkVoltageSchema = z.object({
  name: z.union([z.string(), z.null()]).nullish(),
  code: z.string(),
});
export type NetworkVoltageSchema = z.infer<typeof NetworkVoltageSchema>;

export const SiteStatus = z.enum([
  "no_status",
  "development",
  "financing",
  "connection_requested",
  "construction",
  "waiting_commissioning",
  "commissioned",
  "test_passed",
  "test_failed",
  "work_acceptance",
  "minor_reserve",
  "major_reserve",
  "handed_over",
  "on_hold",
  "dismanteled",
]);
export type SiteStatus = z.infer<typeof SiteStatus>;

export const DeliveryPointCodeKind = z.enum(["MALO", "CARD", "EAN"]);
export type DeliveryPointCodeKind = z.infer<typeof DeliveryPointCodeKind>;

export const DeliveryPointType = z.enum([
  "injection",
  "production",
  "consumption",
]);
export type DeliveryPointType = z.infer<typeof DeliveryPointType>;

export const ContractState = z.enum([
  "in_operation",
  "inaccessible",
  "canceled",
  "improductive",
]);
export type ContractState = z.infer<typeof ContractState>;

export const Meter = z.object({
  serial_number: z.string(),
  registration_number: z.union([z.string(), z.null()]).nullish(),
  accessibility: z.union([z.boolean(), z.null()]).nullish(),
  intensity: z.union([z.string(), z.null()]).nullish(),
  voltage: z.union([z.string(), z.null()]).nullish(),
  property_type: z.union([z.string(), z.null()]).nullish(),
  model: z.union([z.string(), z.null()]).nullish(),
});
export type Meter = z.infer<typeof Meter>;

export const Metering = z.object({
  meter_model: z.string(),
  frequency: z.string(),
  meters: z.array(Meter),
});
export type Metering = z.infer<typeof Metering>;

export const Segment = z.enum([
  "p1",
  "p2",
  "p3",
  "p4",
  "c1",
  "c2",
  "c3",
  "c4",
  "c5",
  "unknown",
]);
export type Segment = z.infer<typeof Segment>;

export const VoltageDomain = z.enum(["BTINF", "BTSUP", "HTA", "HTB"]);
export type VoltageDomain = z.infer<typeof VoltageDomain>;

export const UnitValue = z.object({ value: z.number(), unit: z.string() });
export type UnitValue = z.infer<typeof UnitValue>;

export const LinkType = z.enum([
  "main",
  "backup",
  "deducting",
  "host",
  "consumption",
  "production",
  "internal",
  "distribution_network",
  "unimplemented",
]);
export type LinkType = z.infer<typeof LinkType>;

export const LinkedPRM = z.object({ prm: z.string(), type: LinkType });
export type LinkedPRM = z.infer<typeof LinkedPRM>;

export const NetworkConnection = z.object({
  voltage_domain: VoltageDomain,
  power_production: UnitValue,
  power_consumption: UnitValue,
  linked: z.array(LinkedPRM),
});
export type NetworkConnection = z.infer<typeof NetworkConnection>;

export const Address = z.object({
  address: z.string(),
  postal_code: z.string(),
  city: z.string(),
});
export type Address = z.infer<typeof Address>;

export const EnedisNetworkCfg = z.object({
  prm: z.string(),
  state: z.union([ContractState, z.null()]).nullish(),
  metering: Metering,
  segment: Segment,
  service_level: z.union([z.number(), z.null()]).nullish(),
  network: NetworkConnection,
  address: Address,
  technos: z.array(z.string()),
  raw_data: z.record(z.any()),
});
export type EnedisNetworkCfg = z.infer<typeof EnedisNetworkCfg>;

export const DSOSchema = z.object({
  name: z.union([z.string(), z.null()]).nullish(),
  code: z.string(),
});
export type DSOSchema = z.infer<typeof DSOSchema>;

export const DeliveryPointKind = z.enum(["meter", "headmeter", "submeter"]);
export type DeliveryPointKind = z.infer<typeof DeliveryPointKind>;

export const TurbineType = z.enum(["onshore", "offshore"]);
export type TurbineType = z.infer<typeof TurbineType>;

export const WindTurbineSchema = z.object({
  latitude: z.union([z.number(), z.null()]).nullish(),
  longitude: z.union([z.number(), z.null()]).nullish(),
  hub_height: z.union([z.number(), z.null()]).nullish(),
  name: z.string(),
  model: z.string(),
  manufacturer: z.string(),
  uuid: z.string().uuid(),
  capacity: z.number(),
  type: TurbineType,
  powercurve: z.array(z.array(z.any())),
});
export type WindTurbineSchema = z.infer<typeof WindTurbineSchema>;

export const SolarMountingType = z.enum([
  "ground_mounted",
  "canopy",
  "rooftop",
]);
export type SolarMountingType = z.infer<typeof SolarMountingType>;

export const SolarTrackingType = z.enum([
  "fix",
  "single_axis",
  "single_axis_azimuth",
  "dual_axis",
]);
export type SolarTrackingType = z.infer<typeof SolarTrackingType>;

export const OrientationReference = z.enum(["south", "north"]);
export type OrientationReference = z.infer<typeof OrientationReference>;

export const SolarFieldSchema = z.object({
  uuid: z.string().uuid(),
  latitude: z.union([z.number(), z.null()]).nullish(),
  longitude: z.union([z.number(), z.null()]).nullish(),
  name: z.string(),
  type: SolarMountingType,
  capacity: z.number(),
  tracking: SolarTrackingType,
  tilt: z.number(),
  orientation: z.number(),
  orientation_reference: OrientationReference,
  panel_capacity: z.union([z.number(), z.null()]).nullish(),
});
export type SolarFieldSchema = z.infer<typeof SolarFieldSchema>;

export const HydroType = z.enum([
  "run_of_river",
  "pumped_storage",
  "reservoir",
]);
export type HydroType = z.infer<typeof HydroType>;

export const HydroTurbineSchema = z.object({
  uuid: z.string().uuid(),
  latitude: z.union([z.number(), z.null()]).nullish(),
  longitude: z.union([z.number(), z.null()]).nullish(),
  type: HydroType,
  name: z.string(),
  capacity: z.number(),
});
export type HydroTurbineSchema = z.infer<typeof HydroTurbineSchema>;

export const CogeType = z.enum(["biomass", "waste", "other"]);
export type CogeType = z.infer<typeof CogeType>;

export const CogenerationSchema = z.object({
  uuid: z.string().uuid(),
  name: z.string(),
  latitude: z.union([z.number(), z.null()]).nullish(),
  longitude: z.union([z.number(), z.null()]).nullish(),
  type: CogeType,
  capacity: z.number(),
});
export type CogenerationSchema = z.infer<typeof CogenerationSchema>;

export const DeliveryPointSchema = z.object({
  code: z.union([z.string(), z.null()]).nullish(),
  reference: z.union([z.string(), z.null()]).nullish(),
  network_voltage: z.union([NetworkVoltageSchema, z.null()]).nullish(),
  code_kind: z.union([DeliveryPointCodeKind, z.null()]).nullish(),
  type: z.union([DeliveryPointType, z.null()]).nullish(),
  extra: z.union([z.record(z.any()), z.null()]).nullish(),
  network_extra: z.union([EnedisNetworkCfg, z.null()]).nullish(),
  dso: DSOSchema,
  kind: DeliveryPointKind,
  uuid: z.string().uuid(),
  assets: z.array(
    z.union([
      WindTurbineSchema,
      SolarFieldSchema,
      HydroTurbineSchema,
      CogenerationSchema,
    ])
  ),
});
export type DeliveryPointSchema = z.infer<typeof DeliveryPointSchema>;

export const Inverter = z.object({
  brand: z.union([z.string(), z.null()]).nullish(),
  model: z.union([z.string(), z.null()]).nullish(),
  capacity: z.union([z.number(), z.null()]).nullish(),
});
export type Inverter = z.infer<typeof Inverter>;

export const Module = z.object({
  model: z.union([z.string(), z.null()]).nullish(),
  capacity: z.union([z.number(), z.null()]).nullish(),
  number: z.union([z.number(), z.null()]).nullish(),
});
export type Module = z.infer<typeof Module>;

export const TechnicalData = z.object({
  inverters: z.union([z.array(Inverter), z.null()]).nullish(),
  extra: z.union([z.record(z.any()), z.null()]).nullish(),
  modules: z.union([z.array(Module), z.null()]).nullish(),
});
export type TechnicalData = z.infer<typeof TechnicalData>;

export const SiteStatusHistorySchema = z.object({
  date: z.coerce.date(),
  comment: z.union([z.string(), z.null()]).nullish(),
  status: z.union([SiteStatus, z.null()]).nullish(),
  uuid: z.string().uuid(),
});
export type SiteStatusHistorySchema = z.infer<typeof SiteStatusHistorySchema>;

export const SiteSchema = z.object({
  name: z.string(),
  latitude: z.number(),
  longitude: z.number(),
  description: z.union([z.record(z.any()), z.null()]).nullish(),
  region: RegionSchema,
  country: CountrySchema,
  commissioning: z.union([z.coerce.date(), z.null()]).nullish(),
  portfolio: z.union([PortfolioSchema, z.null()]).nullish(),
  external_id: z.union([z.string(), z.null()]).nullish(),
  network_voltage: z.union([NetworkVoltageSchema, z.null()]).nullish(),
  uuid: z.string().uuid(),
  capacity: z.number(),
  p50: z.union([z.number(), z.null()]).nullish(),
  machine_type: z.string(),
  assetowner: CompanySchemaShort,
  status: SiteStatus,
  is_external: z.boolean(),
  estimated_commissioning: z.union([z.coerce.date(), z.null()]).nullish(),
  deliverypoints: z.array(DeliveryPointSchema),
  metric_status: z.record(z.any()),
  contact_infos: z.array(ContactInfoSchema),
  address: z.union([AddressSchema, z.null()]).nullish(),
  technical_data: z.union([TechnicalData, z.null()]).nullish(),
  commissioning_status: z.union([SiteStatus, z.null()]).nullish(),
  construction_status: z.union([SiteStatus, z.null()]).nullish(),
  statuses: z.array(SiteStatusHistorySchema),
  is_self_consumption: z.boolean(),
  has_operation_data: z.boolean(),
  has_rt_data: z.boolean(),
  has_revenue_data: z.boolean(),
  has_budget_data: z.boolean(),
});
export type SiteSchema = z.infer<typeof SiteSchema>;

export const SiteSchemaUpdate = z.object({
  name: z.union([z.string(), z.null()]).nullish(),
  latitude: z.union([z.number(), z.null()]).nullish(),
  longitude: z.union([z.number(), z.null()]).nullish(),
  description: z.union([z.record(z.any()), z.null()]).nullish(),
  region: z.union([RegionSchema, z.null()]).nullish(),
  country: z.union([CountrySchema, z.null()]).nullish(),
  commissioning: z.union([z.coerce.date(), z.null()]).nullish(),
  portfolio: z.union([PortfolioSchema, z.null()]).nullish(),
  external_id: z.union([z.string(), z.null()]).nullish(),
  technical_data: z.union([TechnicalData, z.null()]).nullish(),
});
export type SiteSchemaUpdate = z.infer<typeof SiteSchemaUpdate>;

export const is_external = z.union([z.boolean(), z.null()]).nullish();
export type is_external = z.infer<typeof is_external>;

export const statuses = z.union([z.array(SiteStatus), z.null()]).nullish();
export type statuses = z.infer<typeof statuses>;

export const PastillaName = z.enum([
  "power_production",
  "availability",
  "data_health",
  "pr",
]);
export type PastillaName = z.infer<typeof PastillaName>;

export const PastillaStatus = z.enum([
  "missing",
  "missing_configuration",
  "good",
  "warning",
  "error",
  "compute_error",
  "skipped",
  "na",
]);
export type PastillaStatus = z.infer<typeof PastillaStatus>;

export const PastillaSchemaShort = z.object({
  name: PastillaName,
  status: PastillaStatus,
  gauge_value: z.union([z.number(), z.null()]).nullish(),
  budget_value: z.union([z.number(), z.null()]).nullish(),
  last_update: z.coerce.date(),
  last_status_change: z.coerce.date(),
  comment: z.union([z.string(), z.null()]).nullish(),
});
export type PastillaSchemaShort = z.infer<typeof PastillaSchemaShort>;

export const SiteContractStatus = z.enum([
  "market_access",
  "feed_in_tarif",
  "contract_for_difference",
  "ppa",
  "lease",
  "maintenance",
  "new_project",
  "not_contracted",
]);
export type SiteContractStatus = z.infer<typeof SiteContractStatus>;

export const SiteSchemaOverview = z.object({
  name: z.string(),
  latitude: z.number(),
  longitude: z.number(),
  description: z.union([z.record(z.any()), z.null()]).nullish(),
  region: RegionSchema,
  country: CountrySchema,
  commissioning: z.union([z.coerce.date(), z.null()]).nullish(),
  portfolio: z.union([PortfolioSchema, z.null()]).nullish(),
  external_id: z.union([z.string(), z.null()]).nullish(),
  network_voltage: z.union([NetworkVoltageSchema, z.null()]).nullish(),
  uuid: z.string().uuid(),
  capacity: z.number(),
  p50: z.union([z.number(), z.null()]).nullish(),
  machine_type: z.string(),
  assetowner: CompanySchemaShort,
  status: SiteStatus,
  is_external: z.boolean(),
  estimated_commissioning: z.union([z.coerce.date(), z.null()]).nullish(),
  metric_status: z.record(z.any()),
  cached_pastilla_values: z.record(PastillaSchemaShort),
  contract_status: z.array(SiteContractStatus),
  dp_codes: z.array(z.string()),
  dp_references: z.array(z.string()),
  data_origins: z.array(z.string()),
});
export type SiteSchemaOverview = z.infer<typeof SiteSchemaOverview>;

export const WindTurbineSchemaCreate = z.object({
  name: z.string(),
  latitude: z.union([z.number(), z.null()]).nullish(),
  longitude: z.union([z.number(), z.null()]).nullish(),
  hub_height: z.union([z.number(), z.null()]).nullish(),
  model: z.string(),
  manufacturer: z.string(),
});
export type WindTurbineSchemaCreate = z.infer<typeof WindTurbineSchemaCreate>;

export const SolarFieldSchemaCreate = z.object({
  name: z.string(),
  latitude: z.union([z.number(), z.null()]).nullish(),
  longitude: z.union([z.number(), z.null()]).nullish(),
  type: SolarMountingType,
  capacity: z.number(),
  tracking: SolarTrackingType,
  tilt: z.number(),
  orientation: z.number(),
  orientation_reference: OrientationReference,
  panel_capacity: z.union([z.number(), z.null()]).nullish(),
});
export type SolarFieldSchemaCreate = z.infer<typeof SolarFieldSchemaCreate>;

export const HydroTurbineSchemaCreate = z.object({
  name: z.string(),
  latitude: z.union([z.number(), z.null()]).nullish(),
  longitude: z.union([z.number(), z.null()]).nullish(),
  type: HydroType,
  capacity: z.number(),
});
export type HydroTurbineSchemaCreate = z.infer<typeof HydroTurbineSchemaCreate>;

export const CogenerationSchemaCreate = z.object({
  name: z.string(),
  latitude: z.union([z.number(), z.null()]).nullish(),
  longitude: z.union([z.number(), z.null()]).nullish(),
  type: CogeType,
  capacity: z.number(),
});
export type CogenerationSchemaCreate = z.infer<typeof CogenerationSchemaCreate>;

export const DeliveryPointSchemaCreate = z.object({
  code: z.union([z.string(), z.null()]).nullish(),
  reference: z.union([z.string(), z.null()]).nullish(),
  network_voltage: z.union([NetworkVoltageSchema, z.null()]).nullish(),
  code_kind: DeliveryPointCodeKind,
  type: DeliveryPointType,
  extra: z.union([z.record(z.any()), z.null()]).nullish(),
  network_extra: z.union([EnedisNetworkCfg, z.null()]).nullish(),
  dso: DSOSchema,
  assets: z.array(
    z.union([
      WindTurbineSchemaCreate,
      SolarFieldSchemaCreate,
      HydroTurbineSchemaCreate,
      CogenerationSchemaCreate,
    ])
  ),
});
export type DeliveryPointSchemaCreate = z.infer<
  typeof DeliveryPointSchemaCreate
>;

export const SiteSchemaCreate = z.object({
  name: z.string(),
  latitude: z.number(),
  longitude: z.number(),
  description: z.union([z.record(z.any()), z.null()]).nullish(),
  region: RegionSchema,
  country: CountrySchema,
  commissioning: z.union([z.coerce.date(), z.null()]).nullish(),
  portfolio: z.union([PortfolioSchema, z.null()]).nullish(),
  external_id: z.union([z.string(), z.null()]).nullish(),
  network_voltage: z.union([NetworkVoltageSchema, z.null()]).nullish(),
  assetowner_uuid: z.union([z.string(), z.null()]).nullish(),
  deliverypoints: z.array(DeliveryPointSchemaCreate).nullish().default([]),
});
export type SiteSchemaCreate = z.infer<typeof SiteSchemaCreate>;

export const SiteStatusHistorySchemaCreate = z.object({
  date: z.union([z.coerce.date(), z.null()]).nullish(),
  comment: z.union([z.string(), z.null()]).nullish(),
  status: z.union([SiteStatus, z.null()]).nullish(),
  site_uuid: z.string().uuid(),
});
export type SiteStatusHistorySchemaCreate = z.infer<
  typeof SiteStatusHistorySchemaCreate
>;

export const SiteStatusHistorySchemaUpdate = z.object({
  date: z.union([z.coerce.date(), z.null()]).nullish(),
  comment: z.union([z.string(), z.null()]).nullish(),
  status: z.union([SiteStatus, z.null()]).nullish(),
});
export type SiteStatusHistorySchemaUpdate = z.infer<
  typeof SiteStatusHistorySchemaUpdate
>;

export const freq__2 = z.union([z.string(), z.null()]).nullish().default("1MS");
export type freq__2 = z.infer<typeof freq__2>;

export const missing_left_idx_tol = z
  .union([z.number(), z.null()])
  .nullish()
  .default(0.5);
export type missing_left_idx_tol = z.infer<typeof missing_left_idx_tol>;

export const IOFormatDF = z.enum(["json", "feather"]);
export type IOFormatDF = z.infer<typeof IOFormatDF>;

export const io_format = z
  .union([IOFormatDF, z.null()])
  .nullish()
  .default("feather");
export type io_format = z.infer<typeof io_format>;

export const quantiles = z
  .union([z.array(z.number()), z.null()])
  .nullish()
  .default([0.05, 0.95]);
export type quantiles = z.infer<typeof quantiles>;

export const year_range = z.union([z.number(), z.null()]).nullish().default(10);
export type year_range = z.infer<typeof year_range>;

export const extrapolation_window = z
  .union([z.number(), z.null()])
  .nullish()
  .default(14);
export type extrapolation_window = z.infer<typeof extrapolation_window>;

export const freq__3 = z
  .union([z.enum(["30T", "1D", "1MS"]), z.null()])
  .nullish();
export type freq__3 = z.infer<typeof freq__3>;

export const start = z.union([z.coerce.date(), z.null()]).nullish();
export type start = z.infer<typeof start>;

export const CommissioningGroupName = z.enum([
  "currently_testing",
  "test_passed",
  "test_failed",
  "to_hand_over",
  "handed_over",
]);
export type CommissioningGroupName = z.infer<typeof CommissioningGroupName>;

export const CommissioningSubgroupName = z.enum([
  "work_acceptance",
  "major_reserve",
  "minor_reserve",
  "late",
  "on_time",
  "without_construction_status",
  "missing_data",
  "not_tested_yet",
  "one_month_failed",
  "two_months_failed",
]);
export type CommissioningSubgroupName = z.infer<
  typeof CommissioningSubgroupName
>;

export const BudgetHypothesis = z.object({
  installed_capacity: z.number(),
  full_power_eq_yearly_hours: z.number(),
  yearly_degradation_rate: z.number().nullish(),
  availability_rate: z.number().nullish().default(1),
  tarif: z.union([z.number(), z.null()]).nullish(),
  inflation_rate: z.number().nullish().default(0.004),
  seasonality: z.array(z.number()),
  year_start: z.union([z.number(), z.null()]).nullish(),
  target_pr: z.union([z.number(), z.null()]).nullish(),
  performance_seasonality: z
    .array(z.number())
    .nullish()
    .default([1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]),
});
export type BudgetHypothesis = z.infer<typeof BudgetHypothesis>;

export const CommissioningSite = z.object({
  name: z.string(),
  uuid: z.string().uuid(),
  external_id: z.string(),
  tz: z.string(),
  capacity: z.number(),
  machine_type: z.string(),
  assetowner: CompanySchemaShort,
  portfolio: z.union([PortfolioSchema, z.null()]).nullish(),
  commissioning: z.coerce.date(),
  status: SiteStatus,
  statuses: z.array(SiteStatusHistorySchema),
  commissioning_status: z
    .union([z.enum(["test_passed", "test_failed", "handed_over"]), z.null()])
    .nullish(),
  construction_status: z
    .union([
      z.enum(["work_acceptance", "major_reserve", "minor_reserve"]),
      z.null(),
    ])
    .nullish(),
  business_plan_hypothesis: z.union([BudgetHypothesis, z.null()]),
  deadline_perf_test: z.number().int(),
  deadline_handed_over: z.union([z.number(), z.null()]),
  deadline_reserve: z.union([z.number(), z.null()]),
  missing_data: z.boolean(),
  number_perf_test_failed: z.union([z.number(), z.null()]).nullish(),
  month_pr_reached: z.union([z.coerce.date(), z.null()]).nullish(),
  max_pr_reached: z.union([z.number(), z.null()]).nullish(),
  pr: z.record(z.union([z.number(), z.null()])).nullish(),
  target_pr: z.number(),
});
export type CommissioningSite = z.infer<typeof CommissioningSite>;

export const CommissioningSubgroup = z.object({
  name: CommissioningSubgroupName,
  children: z.array(CommissioningSite),
});
export type CommissioningSubgroup = z.infer<typeof CommissioningSubgroup>;

export const CommissioningGroup = z.object({
  name: CommissioningGroupName,
  children: z.array(CommissioningSubgroup),
  unclassified_sites: z.array(CommissioningSite),
});
export type CommissioningGroup = z.infer<typeof CommissioningGroup>;

export const CommissioningDashboardTree = z.object({
  children: z.array(CommissioningGroup),
  unclassified_sites: z.array(CommissioningSite),
});
export type CommissioningDashboardTree = z.infer<
  typeof CommissioningDashboardTree
>;

export const InvoiceStatus = z.enum([
  "error",
  "incomplete",
  "computed",
  "capped",
  "informative",
  "draft",
  "published",
  "validated",
  "waiting",
  "submitting",
  "submitted",
  "payment",
  "rejected",
  "production_null",
  "canceled_by_credit_note",
  "to_validate",
  "to_redo",
  "paid",
  "deleted",
]);
export type InvoiceStatus = z.infer<typeof InvoiceStatus>;

export const InvoiceAssetOwnerSchema = z.object({
  uuid: z.string().uuid(),
  kind: CompanyKind,
  name: z.string(),
  name_short: z.union([z.string(), z.null()]).nullish(),
  accounting_id: z.union([z.string(), z.null()]).nullish(),
  external_id: z.union([z.string(), z.null()]).nullish(),
});
export type InvoiceAssetOwnerSchema = z.infer<typeof InvoiceAssetOwnerSchema>;

export const InvoiceSiteSchema = z.object({
  uuid: z.string().uuid(),
  name: z.string(),
  external_id: z.union([z.string(), z.null()]).nullish(),
  dp_codes: z.array(z.string()),
  is_external: z.boolean(),
  assetowner: InvoiceAssetOwnerSchema,
  capacity: z.number(),
  portfolio: z.union([PortfolioSchema, z.null()]).nullish(),
});
export type InvoiceSiteSchema = z.infer<typeof InvoiceSiteSchema>;

export const PriceMechanism = z.enum([
  "market_access",
  "feed_in_tarif",
  "contract_for_difference",
  "ppa",
  "lease",
  "maintenance",
]);
export type PriceMechanism = z.infer<typeof PriceMechanism>;

export const ContractTypeSchema = z.object({
  name: z.string(),
  price_mechanism: PriceMechanism,
});
export type ContractTypeSchema = z.infer<typeof ContractTypeSchema>;

export const InvoiceOfftakerSchema = z.object({
  uuid: z.string().uuid(),
  kind: CompanyKind,
  name: z.string(),
  name_short: z.union([z.string(), z.null()]).nullish(),
  userctx_accounting_id: z.union([z.string(), z.null()]).nullish(),
  userctx_external_id: z.union([z.string(), z.null()]).nullish(),
});
export type InvoiceOfftakerSchema = z.infer<typeof InvoiceOfftakerSchema>;

export const InvoiceSubmitChannel = z.enum([
  "email",
  "platform",
  "cegedim",
  "mail",
]);
export type InvoiceSubmitChannel = z.infer<typeof InvoiceSubmitChannel>;

export const InvoiceContractSchema = z.object({
  uuid: z.string().uuid(),
  purchase_order: z.union([z.string(), z.null()]).nullish(),
  type: ContractTypeSchema,
  offtaker: InvoiceOfftakerSchema,
  invoice_submit_channel: z.union([InvoiceSubmitChannel, z.null()]).nullish(),
  start: z.coerce.date(),
  end: z.coerce.date(),
});
export type InvoiceContractSchema = z.infer<typeof InvoiceContractSchema>;

export const InvoiceExternalStatus = z.enum([
  "matched",
  "energy_mismatch",
  "price_mismatch",
  "energy_and_price_mismatch",
  "to_be_matched",
]);
export type InvoiceExternalStatus = z.infer<typeof InvoiceExternalStatus>;

export const ClauseSchemaShort = z.object({
  uuid: z.string().uuid(),
  type: z.string(),
});
export type ClauseSchemaShort = z.infer<typeof ClauseSchemaShort>;

export const SiteInvoiceLine = z.object({
  uuid: z.string().uuid(),
  name: z.string(),
});
export type SiteInvoiceLine = z.infer<typeof SiteInvoiceLine>;

export const InvoiceLineSchema = z.object({
  product: z.string(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  quantity: z.number(),
  price: z.number(),
  amount: z.number(),
  quantity_unit: z.string(),
  is_external: z.boolean(),
  is_summable: z.boolean(),
  clause: ClauseSchemaShort,
  site: z.union([SiteInvoiceLine, z.null()]).nullish(),
});
export type InvoiceLineSchema = z.infer<typeof InvoiceLineSchema>;

export const InvoiceLogAction = z.object({
  date: z.coerce.date(),
  status: z.union([InvoiceStatus, z.null()]).nullish(),
  action: z.union([z.string(), z.null()]).nullish(),
  initiator: z.union([z.string(), z.null()]).nullish(),
});
export type InvoiceLogAction = z.infer<typeof InvoiceLogAction>;

export const InseeIndex = z.enum(["FM0ABE", "ICHT", "BT47", "IPC", "TCH"]);
export type InseeIndex = z.infer<typeof InseeIndex>;

export const InseeIndexComputationDetails = z.object({
  name: InseeIndex,
  coef: z.number(),
  rebased: z.number(),
  rebase_coefs: z.record(z.number()),
  fraction: z.number(),
  latest_value: z.number(),
  initial_value: z.number(),
  base_year: z.number().int(),
  published_version: z.coerce.date(),
});
export type InseeIndexComputationDetails = z.infer<
  typeof InseeIndexComputationDetails
>;

export const LIndexComputationDetails = z.object({
  version: z.coerce.date(),
  L: z.number(),
  map_name_indexes: z.record(InseeIndexComputationDetails),
});
export type LIndexComputationDetails = z.infer<typeof LIndexComputationDetails>;

export const InvoiceCoefDetails = z.object({
  L_details: z.union([LIndexComputationDetails, z.null()]).nullish(),
  indexed_tarif: z.union([z.number(), z.null()]).nullish(),
  M0: z.union([z.number(), z.null()]).nullish(),
  credit_note_cap: z.union([z.number(), z.null()]).nullish(),
  cumulated_deferment: z.union([z.number(), z.null()]).nullish(),
});
export type InvoiceCoefDetails = z.infer<typeof InvoiceCoefDetails>;

export const PPAPriceType = z.enum(["fix_price", "epex_spot"]);
export type PPAPriceType = z.infer<typeof PPAPriceType>;

export const TarifType = z.object({
  price_type: PPAPriceType,
  fix_price: z.union([z.number(), z.null()]).nullish(),
  management_fee: z.union([z.number(), z.null()]).nullish(),
});
export type TarifType = z.infer<typeof TarifType>;

export const _HydroTarifBase = z.object({
  tarif: z.number(),
  majoration: z.union([z.number(), z.null()]).nullish(),
  uncovered_tarif: z.union([z.record(z.any()), z.null()]).nullish(),
});
export type _HydroTarifBase = z.infer<typeof _HydroTarifBase>;

export const HydroTarifComponents = z.object({
  winter_base: z.union([_HydroTarifBase, z.null()]).nullish(),
  winter_off_peak: z.union([_HydroTarifBase, z.null()]).nullish(),
  winter_peak: z.union([_HydroTarifBase, z.null()]).nullish(),
  summer_base: z.union([_HydroTarifBase, z.null()]).nullish(),
  summer_off_peak: z.union([_HydroTarifBase, z.null()]).nullish(),
  summer_peak: z.union([_HydroTarifBase, z.null()]).nullish(),
  base_peak: z.union([_HydroTarifBase, z.null()]).nullish(),
  base_off_peak: z.union([_HydroTarifBase, z.null()]).nullish(),
});
export type HydroTarifComponents = z.infer<typeof HydroTarifComponents>;

export const InvoiceClauseDetails = z.object({
  tarif: z.union([z.number(), z.null()]).nullish(),
  energy_threshold: z.union([z.number(), z.null()]).nullish(),
  tarif_above_threshold: z.union([z.number(), z.null()]).nullish(),
  fix_price: z.union([z.number(), z.null()]).nullish(),
  management_fee: z.union([z.number(), z.null()]).nullish(),
  management_coef: z.union([z.number(), z.null()]).nullish(),
  majoration: z.union([z.number(), z.null()]).nullish(),
  management_bonus: z.union([z.number(), z.null()]).nullish(),
  formula: z.union([z.string(), z.null()]).nullish(),
  power_threshold: z.union([z.number(), z.null()]).nullish(),
  below_tarif: z.union([z.number(), TarifType, z.null()]).nullish(),
  above_tarif: z.union([z.number(), TarifType, z.null()]).nullish(),
  tarif_components: z.union([HydroTarifComponents, z.null()]).nullish(),
  quality_majoration: z.union([z.number(), z.null()]).nullish(),
  quality_coefficient: z.union([z.number(), z.null()]).nullish(),
  deliverypoint_references: z
    .union([z.array(z.union([z.string(), z.null()])), z.null()])
    .nullish(),
  pae: z.union([z.number(), z.null()]).nullish(),
  spot_prices_coefficient: z.union([z.number(), z.null()]).nullish(),
  premium_autoconso: z.union([z.number(), z.null()]).nullish(),
  cumulated_actualisation_rate: z.union([z.number(), z.null()]).nullish(),
  contractual_amount: z.union([z.number(), z.null()]).nullish(),
  actualised_amount: z.union([z.number(), z.null()]).nullish(),
  bonus_malus: z.union([z.number(), z.null()]).nullish(),
  autoconsumption_rate: z.union([z.number(), z.null()]).nullish(),
  vat_amount: z.union([z.number(), z.null()]).nullish(),
  price: z.union([z.number(), z.null()]).nullish(),
  number_of_spot_negative_hours: z.union([z.number(), z.null()]).nullish(),
  number_of_spot_negative_hours_with_production: z
    .union([z.number(), z.null()])
    .nullish(),
  penalty_way_coefficient: z.union([z.number(), z.null()]).nullish(),
  availability_threshold: z.union([z.number(), z.null()]).nullish(),
  availability_rate: z.union([z.number(), z.null()]).nullish(),
  injection_penalty: z.union([z.number(), z.null()]).nullish(),
  max_injected_capa_value: z.union([z.number(), z.null()]).nullish(),
  effluents_tarif: z.union([z.number(), z.null()]).nullish(),
  effluents_rate: z.union([z.number(), z.null()]).nullish(),
  effluents_rate_threshold: z.union([z.number(), z.null()]).nullish(),
  ft_cost: z.union([z.number(), z.null()]).nullish(),
  discount_rate: z.union([z.number(), z.null()]).nullish(),
  comparison_amount: z.union([z.number(), z.null()]).nullish(),
  positive_imbalance_coef: z.union([z.number(), z.null()]).nullish(),
  negative_imbalance_coef: z.union([z.number(), z.null()]).nullish(),
});
export type InvoiceClauseDetails = z.infer<typeof InvoiceClauseDetails>;

export const InvoiceIndex = z.object({
  start_value: z.union([z.number(), z.null()]).nullish(),
  end_value: z.union([z.number(), z.null()]).nullish(),
  value: z.union([z.number(), z.null()]).nullish(),
});
export type InvoiceIndex = z.infer<typeof InvoiceIndex>;

export const InvoiceExtraDetails = z.object({
  prod_index: InvoiceIndex.nullish(),
  conso_index: InvoiceIndex.nullish(),
  prod: z.union([z.number(), z.null()]).nullish(),
  conso: z.union([z.number(), z.null()]).nullish(),
});
export type InvoiceExtraDetails = z.infer<typeof InvoiceExtraDetails>;

export const ClauseTypes = z.enum([
  "cfd_cr_16",
  "cfd_cr_17",
  "cfd_majoration",
  "cfd_autoconso",
  "ft_wind_monthly",
  "ft_solar_monthly",
  "ft_solar_semestrial",
  "ft_hydro_monthly",
  "ft_biogas_monthly",
  "market_access",
  "market_access_imb",
  "ppa_da",
  "ppa_fix",
  "ppa_threshold",
  "ppa_tarif_components",
  "ppa_threshold_multi_period",
  "ppa_management_fee",
  "ppa_da_indexed_fee",
  "ppa_imbalance_index_fee",
  "undelivered_energy_penalty",
  "ppa_asia",
  "neg_price",
  "spot_management_fee",
  "goo",
  "gc",
  "credit_note_cap",
  "settlement",
  "autoconso",
  "autoconso_insee_indexed",
  "autoconso_master",
  "energy_guarantee",
  "energy_guarantee_risk_premium",
  "maintenance",
  "lease",
]);
export type ClauseTypes = z.infer<typeof ClauseTypes>;

export const ClauseComputationResultDetails = z.object({
  coef: z.union([InvoiceCoefDetails, z.null()]).nullish(),
  clause: z.union([InvoiceClauseDetails, z.null()]).nullish(),
  extra: z.union([InvoiceExtraDetails, z.null()]).nullish(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  clause_type: ClauseTypes,
});
export type ClauseComputationResultDetails = z.infer<
  typeof ClauseComputationResultDetails
>;

export const InvoiceSchema = z.object({
  uuid: z.string().uuid(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  computable_asof: z.coerce.date(),
  publication_date: z.union([z.coerce.date(), z.null()]).nullish(),
  computation_date: z.union([z.coerce.date(), z.null()]).nullish(),
  publication_id: z.union([z.string(), z.null()]).nullish(),
  status: InvoiceStatus,
  comment: z.union([z.string(), z.null()]).nullish(),
  site: InvoiceSiteSchema,
  contract: InvoiceContractSchema,
  is_birthday: z.boolean(),
  is_accounted: z.boolean(),
  accounted_date: z.union([z.coerce.date(), z.null()]).nullish(),
  accounted_initiator: z.union([z.string(), z.null()]).nullish(),
  external_status: z.union([InvoiceExternalStatus, z.null()]).nullish(),
  has_pdf: z.boolean(),
  lines: z.array(InvoiceLineSchema),
  external_lines: z.array(InvoiceLineSchema),
  log_actions: z.array(InvoiceLogAction),
  details: z.array(ClauseComputationResultDetails),
});
export type InvoiceSchema = z.infer<typeof InvoiceSchema>;

export const InvoiceSchemaUpdate = z.object({
  start: z.union([z.coerce.date(), z.null()]).nullish(),
  end: z.union([z.coerce.date(), z.null()]).nullish(),
  comment: z.union([z.string(), z.null()]).nullish(),
});
export type InvoiceSchemaUpdate = z.infer<typeof InvoiceSchemaUpdate>;

export const InvoiceSchemaShort = z.object({
  uuid: z.string().uuid(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  computable_asof: z.coerce.date(),
  publication_date: z.union([z.coerce.date(), z.null()]).nullish(),
  computation_date: z.union([z.coerce.date(), z.null()]).nullish(),
  publication_id: z.union([z.string(), z.null()]).nullish(),
  status: InvoiceStatus,
  comment: z.union([z.string(), z.null()]).nullish(),
  site: InvoiceSiteSchema,
  contract: InvoiceContractSchema,
  is_birthday: z.boolean(),
  is_accounted: z.boolean(),
  accounted_date: z.union([z.coerce.date(), z.null()]).nullish(),
  accounted_initiator: z.union([z.string(), z.null()]).nullish(),
  external_status: z.union([InvoiceExternalStatus, z.null()]).nullish(),
  has_pdf: z.boolean(),
  lines: z.array(InvoiceLineSchema),
  external_lines: z.array(InvoiceLineSchema),
});
export type InvoiceSchemaShort = z.infer<typeof InvoiceSchemaShort>;

export const Body_compute_invoice_from_model_api__model___uuid__invoices_compute_post =
  z.object({
    start: z.union([z.coerce.date(), z.null()]).nullish(),
    end: z.union([z.coerce.date(), z.null()]).nullish(),
    regulation_only: z.union([z.boolean(), z.null()]).nullish(),
    flt_on_valuation_horizon: z
      .union([z.boolean(), z.null()])
      .nullish()
      .default(true),
  });
export type Body_compute_invoice_from_model_api__model___uuid__invoices_compute_post =
  z.infer<
    typeof Body_compute_invoice_from_model_api__model___uuid__invoices_compute_post
  >;

export const InvoiceComputationSchema = z.object({
  new: z.union([z.array(InvoiceSchema), z.null()]).nullish(),
  refreshed: z.union([z.array(InvoiceSchema), z.null()]).nullish(),
  published: z.union([z.array(InvoiceSchema), z.null()]).nullish(),
});
export type InvoiceComputationSchema = z.infer<typeof InvoiceComputationSchema>;

export const Body_submit_invoice_from_model_api__model___uuid__invoices_submit_post =
  z.object({
    start: z.union([z.coerce.date(), z.null()]).nullish(),
    end: z.union([z.coerce.date(), z.null()]).nullish(),
  });
export type Body_submit_invoice_from_model_api__model___uuid__invoices_submit_post =
  z.infer<
    typeof Body_submit_invoice_from_model_api__model___uuid__invoices_submit_post
  >;

export const Body_publish_invoice_from_model_api__model___uuid__invoices_publish_post =
  z.object({
    start: z.union([z.coerce.date(), z.null()]).nullish(),
    end: z.union([z.coerce.date(), z.null()]).nullish(),
    as_draft: z.boolean().nullish(),
  });
export type Body_publish_invoice_from_model_api__model___uuid__invoices_publish_post =
  z.infer<
    typeof Body_publish_invoice_from_model_api__model___uuid__invoices_publish_post
  >;

export const InvoiceSchemaSync = z.object({
  uuid: z.string().uuid(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  computable_asof: z.coerce.date(),
  publication_date: z.union([z.coerce.date(), z.null()]).nullish(),
  computation_date: z.union([z.coerce.date(), z.null()]).nullish(),
  publication_id: z.string(),
  status: InvoiceStatus,
  comment: z.union([z.string(), z.null()]).nullish(),
  site: InvoiceSiteSchema,
  contract: InvoiceContractSchema,
  is_birthday: z.boolean(),
  is_accounted: z.boolean(),
  accounted_date: z.union([z.coerce.date(), z.null()]).nullish(),
  accounted_initiator: z.union([z.string(), z.null()]).nullish(),
  external_status: z.union([InvoiceExternalStatus, z.null()]).nullish(),
  has_pdf: z.boolean(),
  lines: z.array(InvoiceLineSchema),
  external_lines: z.array(InvoiceLineSchema),
  log_actions: z.array(InvoiceLogAction),
  offtaker_accounting_id: z.union([z.string(), z.null()]).nullish(),
  assetowner_accounting_id: z.union([z.string(), z.null()]).nullish(),
});
export type InvoiceSchemaSync = z.infer<typeof InvoiceSchemaSync>;

export const Body_set_invoice_accounted_api_invoices__uuid__accounted_post =
  z.object({ is_accounted: z.boolean().nullish().default(true) });
export type Body_set_invoice_accounted_api_invoices__uuid__accounted_post =
  z.infer<typeof Body_set_invoice_accounted_api_invoices__uuid__accounted_post>;

export const CompanyCloudImages = z.object({
  logo: z.union([CloudImageUrl, z.null()]).nullish(),
  stamp: z.union([CloudImageUrl, z.null()]).nullish(),
});
export type CompanyCloudImages = z.infer<typeof CompanyCloudImages>;

export const OfftakerSchema = z.object({
  name: z.string(),
  cloud_image: CompanyCloudImages,
  url: z.union([z.string(), z.null()]).nullish(),
});
export type OfftakerSchema = z.infer<typeof OfftakerSchema>;

export const InvoiceSource = z.enum(["Renewex", "Offtaker", "Asset Owner"]);
export type InvoiceSource = z.infer<typeof InvoiceSource>;

export const SiteSchemaShort = z.object({
  name: z.string(),
  latitude: z.number(),
  longitude: z.number(),
  description: z.union([z.record(z.any()), z.null()]).nullish(),
  region: RegionSchema,
  country: CountrySchema,
  commissioning: z.union([z.coerce.date(), z.null()]).nullish(),
  portfolio: z.union([PortfolioSchema, z.null()]).nullish(),
  external_id: z.union([z.string(), z.null()]).nullish(),
  network_voltage: z.union([NetworkVoltageSchema, z.null()]).nullish(),
  uuid: z.string().uuid(),
  capacity: z.number(),
  p50: z.union([z.number(), z.null()]).nullish(),
  machine_type: z.string(),
  assetowner: CompanySchemaShort,
  status: SiteStatus,
  is_external: z.boolean(),
  estimated_commissioning: z.union([z.coerce.date(), z.null()]).nullish(),
});
export type SiteSchemaShort = z.infer<typeof SiteSchemaShort>;

export const DateOp = z.enum(["add", "ceil", "floor"]);
export type DateOp = z.infer<typeof DateOp>;

export const PaymentDelay = z.object({ op: DateOp, freq: z.string() });
export type PaymentDelay = z.infer<typeof PaymentDelay>;

export const LIndexVersionDesc = z.enum(["AS-JAN", "AS-NOV", "AS-BIRTHDAY"]);
export type LIndexVersionDesc = z.infer<typeof LIndexVersionDesc>;

export const IndexCoefficient = z.object({
  index: InseeIndex,
  coefficient: z.union([z.number(), z.null()]).nullish(),
});
export type IndexCoefficient = z.infer<typeof IndexCoefficient>;

export const LIndexCfg = z.object({
  month_base: LIndexVersionDesc,
  on_birthday: z.boolean(),
  defaults: z.array(IndexCoefficient).nullish(),
});
export type LIndexCfg = z.infer<typeof LIndexCfg>;

export const IndexationParameters = z.object({
  index: InseeIndex,
  coefficient: z.union([z.number(), z.null()]).nullish(),
  initial_value: z.number(),
  base_year: z.number().int(),
});
export type IndexationParameters = z.infer<typeof IndexationParameters>;

export const PartialEnergyParameters = z.object({
  freq: z.union([z.string(), z.null()]).nullish(),
  shift_hours: z.union([z.number(), z.null()]).nullish(),
  cap_to_capa: z.union([z.boolean(), z.null()]).nullish(),
  exclude_neg_volumes: z.union([z.boolean(), z.null()]).nullish(),
  use_index: z.union([z.boolean(), z.null()]).nullish(),
  round_energy_before_amount: z.union([z.boolean(), z.null()]).nullish(),
  best_of_tags: z
    .union([
      z.array(z.union([z.enum(["R4Q", "R4M", "R172"]), z.array(z.string())])),
      z.null(),
    ])
    .nullish(),
});
export type PartialEnergyParameters = z.infer<typeof PartialEnergyParameters>;

export const InvoicingFreq = z.enum([
  "semestrial",
  "monthly",
  "yearly",
  "quarterly",
]);
export type InvoicingFreq = z.infer<typeof InvoicingFreq>;

export const InvoicingCfgDetails = z.object({
  freq: InvoicingFreq,
  is_freq_rounded: z.boolean(),
  delay: z.array(z.string()).nullish().default([]),
  delay_reference: z.enum(["start", "end"]).nullish().default("end"),
  grouping_key_suffix: z.union([z.string(), z.null()]).nullish(),
  allow_overlapping_horizons: z.union([z.boolean(), z.null()]).nullish(),
});
export type InvoicingCfgDetails = z.infer<typeof InvoicingCfgDetails>;

export const InvoicingCfg = z.object({
  base: InvoicingCfgDetails,
  regulation: z.union([InvoicingCfgDetails, z.null()]).nullish(),
  tarif_change_on_birthday: z.boolean().nullish(),
});
export type InvoicingCfg = z.infer<typeof InvoicingCfg>;

export const CfdCr16 = z.object({
  stored_l_index_cfg: z.union([LIndexCfg, z.null()]).nullish(),
  indexation: z.union([z.array(IndexationParameters), z.null()]).nullish(),
  type: z.literal("cfd_cr_16"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  tarif: z.number(),
  contracted_capacity: z.union([z.number(), z.null()]).nullish(),
  management_bonus: z.number(),
});
export type CfdCr16 = z.infer<typeof CfdCr16>;

export const CfdCr17 = z.object({
  stored_l_index_cfg: z.union([LIndexCfg, z.null()]).nullish(),
  indexation: z.union([z.array(IndexationParameters), z.null()]).nullish(),
  type: z.literal("cfd_cr_17"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  tarif: z.number(),
  contracted_capacity: z.union([z.number(), z.null()]).nullish(),
  management_bonus: z.number(),
  energy_threshold: z.number(),
  tarif_above_threshold: z.number().nullish().default(40),
});
export type CfdCr17 = z.infer<typeof CfdCr17>;

export const CfdMajoration = z.object({
  stored_l_index_cfg: z.union([LIndexCfg, z.null()]).nullish(),
  indexation: z.union([z.array(IndexationParameters), z.null()]).nullish(),
  type: z.literal("cfd_majoration"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  tarif: z.number(),
  contracted_capacity: z.union([z.number(), z.null()]).nullish(),
  majoration: z.number(),
});
export type CfdMajoration = z.infer<typeof CfdMajoration>;

export const FeedInTarifWindMonthly = z.object({
  stored_l_index_cfg: z.union([LIndexCfg, z.null()]).nullish(),
  indexation: z.union([z.array(IndexationParameters), z.null()]).nullish(),
  type: z.literal("ft_wind_monthly"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  contracted_capacity: z.union([z.number(), z.null()]).nullish(),
  tarif: z.number(),
});
export type FeedInTarifWindMonthly = z.infer<typeof FeedInTarifWindMonthly>;

export const FeedInTarifSolarMonthly = z.object({
  stored_l_index_cfg: z.union([LIndexCfg, z.null()]).nullish(),
  indexation: z.union([z.array(IndexationParameters), z.null()]).nullish(),
  type: z.literal("ft_solar_monthly"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z
    .union([InvoicingCfg, z.null()])
    .nullish()
    .default({
      base: {
        allow_overlapping_horizons: false,
        delay: ["3D"],
        delay_reference: "end",
        freq: "monthly",
        is_freq_rounded: true,
      },
      tarif_change_on_birthday: false,
    }),
  contracted_capacity: z.union([z.number(), z.null()]).nullish(),
  tarif: z.number(),
  majoration: z.union([z.number(), z.null()]).nullish(),
  energy_threshold: z.number(),
  tarif_above_threshold: z.union([z.number(), z.null()]).nullish().default(50),
  apply_energy_threshold: z.union([z.boolean(), z.null()]).nullish(),
  use_index: z.union([z.boolean(), z.null()]).nullish().default(true),
  use_index_extrapolation: z
    .union([z.boolean(), z.null()])
    .nullish()
    .default(true),
  production_prorata_coef: z.union([z.number(), z.null()]).nullish(),
  use_prorata_temporis: z
    .union([z.boolean(), z.null()])
    .nullish()
    .default(true),
});
export type FeedInTarifSolarMonthly = z.infer<typeof FeedInTarifSolarMonthly>;

export const FeedInTarifSolarSemestrial = z.object({
  stored_l_index_cfg: z.union([LIndexCfg, z.null()]).nullish(),
  indexation: z.union([z.array(IndexationParameters), z.null()]).nullish(),
  type: z.literal("ft_solar_semestrial"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  contracted_capacity: z.union([z.number(), z.null()]).nullish(),
  tarif: z.number(),
  majoration: z.union([z.number(), z.null()]).nullish(),
  energy_threshold: z.number(),
  tarif_above_threshold: z.union([z.number(), z.null()]).nullish().default(50),
  apply_energy_threshold: z.union([z.boolean(), z.null()]).nullish(),
  use_index: z.union([z.boolean(), z.null()]).nullish().default(true),
  use_index_extrapolation: z
    .union([z.boolean(), z.null()])
    .nullish()
    .default(true),
  production_prorata_coef: z.union([z.number(), z.null()]).nullish(),
});
export type FeedInTarifSolarSemestrial = z.infer<
  typeof FeedInTarifSolarSemestrial
>;

export const SeasonEnum = z.enum(["base", "winter", "summer"]);
export type SeasonEnum = z.infer<typeof SeasonEnum>;

export const HourPeriod = z.enum(["base", "off_peak", "peak", "super_peak"]);
export type HourPeriod = z.infer<typeof HourPeriod>;

export const PublicHoliday = z.object({
  day: z.number().int(),
  month: z.number().int(),
});
export type PublicHoliday = z.infer<typeof PublicHoliday>;

export const PeriodDefinition = z.object({
  months: z
    .array(z.number())
    .nullish()
    .default([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  weekdays: z.array(z.number()).nullish().default([0, 1, 2, 3, 4, 5, 6]),
  hours: z
    .array(z.number())
    .nullish()
    .default([
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23,
    ]),
  days_to_exclude: z.array(PublicHoliday).nullish(),
  days_to_include: z.array(PublicHoliday).nullish(),
});
export type PeriodDefinition = z.infer<typeof PeriodDefinition>;

export const PpaTarifComponent = z.object({
  price_type: PPAPriceType,
  fix_price: z.union([z.number(), z.null()]).nullish(),
  management_fee: z.union([z.number(), z.null()]).nullish(),
  management_coef: z.number().nullish().default(1),
});
export type PpaTarifComponent = z.infer<typeof PpaTarifComponent>;

export const TarifComponent = z.object({
  tarif: z.number(),
  majoration: z.union([z.number(), z.null()]).nullish(),
  season: SeasonEnum,
  hour_period: HourPeriod,
  period_definitions: z.array(PeriodDefinition).min(1),
  power_threshold: z.union([z.number(), z.null()]).nullish(),
  uncovered_tarif: z.union([PpaTarifComponent, z.null()]).nullish(),
});
export type TarifComponent = z.infer<typeof TarifComponent>;

export const FeedInTarifHydro = z.object({
  tarif_components: z.array(TarifComponent).min(1),
  stored_l_index_cfg: z.union([LIndexCfg, z.null()]).nullish(),
  indexation: z.union([z.array(IndexationParameters), z.null()]).nullish(),
  type: z.literal("ft_hydro_monthly"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  contracted_capacity: z.union([z.number(), z.null()]).nullish(),
  quality_majoration: z.number(),
  quality_coefficient: z.number(),
  prorata: z.number().nullish().default(1),
  shift_hours: z.number().int().nullish().default(2),
});
export type FeedInTarifHydro = z.infer<typeof FeedInTarifHydro>;

export const NegativeHoursSource = z.enum(["market", "cre"]);
export type NegativeHoursSource = z.infer<typeof NegativeHoursSource>;

export const IndexPriceFallback = z.enum([
  "offtaker",
  "renewex",
  "assetmanager",
  "site",
]);
export type IndexPriceFallback = z.infer<typeof IndexPriceFallback>;

export const MarketAccess = z.object({
  type: z.literal("market_access"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  management_fee: z.union([z.number(), z.null()]).nullish(),
  management_fee_coefficient: z.union([z.number(), z.null()]).nullish(),
  exclude_neg_price_hours: z.boolean().nullish(),
  neg_prices_source: NegativeHoursSource.nullish(),
  index_price_fallback: IndexPriceFallback.nullish(),
  single_line: z.boolean().nullish(),
});
export type MarketAccess = z.infer<typeof MarketAccess>;

export const SpotManagementFee = z.object({
  type: z.literal("spot_management_fee"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  formula: z.string(),
  min_value: z.union([z.number(), z.null()]).nullish(),
  max_value: z.union([z.number(), z.null()]).nullish(),
});
export type SpotManagementFee = z.infer<typeof SpotManagementFee>;

export const MarketAccessImbalance = z.object({
  type: z.literal("market_access_imb"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  management_fee: z.union([z.number(), z.null()]).nullish(),
});
export type MarketAccessImbalance = z.infer<typeof MarketAccessImbalance>;

export const PpaDa = z.object({
  type: z.literal("ppa_da"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  management_fee: z.union([z.number(), z.null()]).nullish(),
  management_coef: z.number().nullish().default(1),
});
export type PpaDa = z.infer<typeof PpaDa>;

export const PpaDaIndexedFee = z.object({
  type: z.literal("ppa_da_indexed_fee"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  management_fee: z.number().nullish(),
  management_coef: z.number().nullish().default(1),
  prorata: z.number().nullish().default(1),
});
export type PpaDaIndexedFee = z.infer<typeof PpaDaIndexedFee>;

export const PPAImbalanceIndexedFee = z.object({
  type: z.literal("ppa_imbalance_index_fee"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  positive_imbalance_coef: z.number().nullish().default(1),
  negative_imbalance_coef: z.number().nullish().default(1),
});
export type PPAImbalanceIndexedFee = z.infer<typeof PPAImbalanceIndexedFee>;

export const PpaFix = z.object({
  type: z.literal("ppa_fix"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  fix_price: z.number(),
  management_fee: z.union([z.number(), z.null()]).nullish(),
  under_positive_spot_prices: z
    .union([z.boolean(), z.null()])
    .nullish()
    .default(true),
});
export type PpaFix = z.infer<typeof PpaFix>;

export const PpaManagementFee = z.object({
  stored_l_index_cfg: z.union([LIndexCfg, z.null()]).nullish(),
  indexation: z.union([z.array(IndexationParameters), z.null()]).nullish(),
  type: z.literal("ppa_management_fee"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  management_fee: z.number().nullish(),
  under_positive_spot_prices: z
    .union([z.boolean(), z.null()])
    .nullish()
    .default(true),
});
export type PpaManagementFee = z.infer<typeof PpaManagementFee>;

export const PpaThreshold = z.object({
  type: z.literal("ppa_threshold"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  tarif_below_cap: PpaTarifComponent,
  tarif_above_cap: PpaTarifComponent,
  power_threshold: z.number(),
  management_fee: z.union([z.number(), z.null()]).nullish(),
});
export type PpaThreshold = z.infer<typeof PpaThreshold>;

export const PpaMultiTarif = z.object({
  tarif_components: z.array(TarifComponent).min(1),
  type: z.literal("ppa_tarif_components"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  management_fee: z.union([z.number(), z.null()]).nullish(),
});
export type PpaMultiTarif = z.infer<typeof PpaMultiTarif>;

export const PriceType = z.enum(["spot", "imbalance"]);
export type PriceType = z.infer<typeof PriceType>;

export const NegativePrice = z.object({
  type: z.literal("neg_price"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  price_type: PriceType,
  use_absolute_value: z.boolean().nullish(),
  cap_by_contract_price: z.union([z.boolean(), z.null()]).nullish(),
  management_fee: z.union([z.number(), z.null()]).nullish(),
  penalty_way_coefficient: z.union([z.number(), z.null()]).nullish().default(1),
});
export type NegativePrice = z.infer<typeof NegativePrice>;

export const DefermentRecord = z.object({
  value: z.number(),
  date: z.coerce.date(),
});
export type DefermentRecord = z.infer<typeof DefermentRecord>;

export const CreditNoteCap = z.object({
  type: z.literal("credit_note_cap"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  with_deferment: z.boolean().nullish().default(true),
  statuses: z
    .array(InvoiceStatus)
    .nullish()
    .default(["published", "paid", "computed", "submitted", "capped"]),
  known_deferment: z.array(DefermentRecord).nullish().default([]),
});
export type CreditNoteCap = z.infer<typeof CreditNoteCap>;

export const Goo = z.object({
  type: z.literal("goo"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  price: z.number(),
});
export type Goo = z.infer<typeof Goo>;

export const MaxCapacityRecord = z.object({
  value: z.number(),
  year: z.number().int(),
});
export type MaxCapacityRecord = z.infer<typeof MaxCapacityRecord>;

export const CfdAutoconso = z.object({
  type: z.literal("cfd_autoconso"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  tarif: z.number(),
  premium_autoconso: z.number().nullish().default(10),
  yearly_max_capacity: z.array(MaxCapacityRecord).nullish(),
});
export type CfdAutoconso = z.infer<typeof CfdAutoconso>;

export const BonusMalusRecord = z.object({
  value: z.number(),
  year: z.number().int(),
  recovery_date: z.union([z.coerce.date(), z.null()]).nullish(),
});
export type BonusMalusRecord = z.infer<typeof BonusMalusRecord>;

export const Autoconso = z.object({
  type: z.literal("autoconso"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  prorata_temporis_mode: z
    .enum(["timedelta", "ndays"])
    .nullish()
    .default("ndays"),
  payment_schedule_freq: z.union([InvoicingFreq, z.null()]).nullish(),
  payment_delay_ops: z.array(PaymentDelay).nullish(),
  yearly_tarif: z.number(),
  yearly_actualisation_rate: z.number(),
  bonus_malus: z.array(BonusMalusRecord).nullish(),
});
export type Autoconso = z.infer<typeof Autoconso>;

export const AutoconsoMaster = z.object({
  type: z.literal("autoconso_master"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
});
export type AutoconsoMaster = z.infer<typeof AutoconsoMaster>;

export const InseeIndexationFormula = z.enum(["A", "B"]);
export type InseeIndexationFormula = z.infer<typeof InseeIndexationFormula>;

export const YearlyUnavailabilityRecord = z.object({
  rate: z.number().gte(0).lte(1),
  year: z.number().int(),
});
export type YearlyUnavailabilityRecord = z.infer<
  typeof YearlyUnavailabilityRecord
>;

export const UnavailabilityPenalty = z.object({
  threshold: z.number().gt(0).lte(1),
  availability_records: z.array(YearlyUnavailabilityRecord).nullish(),
});
export type UnavailabilityPenalty = z.infer<typeof UnavailabilityPenalty>;

export const AutoconsoInseeIndexed = z.object({
  type: z.literal("autoconso_insee_indexed"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  prorata_temporis_mode: z
    .enum(["timedelta", "ndays"])
    .nullish()
    .default("ndays"),
  payment_schedule_freq: z.union([InvoicingFreq, z.null()]).nullish(),
  payment_delay_ops: z.array(PaymentDelay).nullish(),
  yearly_tarif: z.number(),
  indexation_formula: InseeIndexationFormula.nullish(),
  indexation_coefficient: z.array(IndexCoefficient),
  unavailability_penalty: z.union([UnavailabilityPenalty, z.null()]).nullish(),
});
export type AutoconsoInseeIndexed = z.infer<typeof AutoconsoInseeIndexed>;

export const Settlement = z.object({
  type: z.literal("settlement"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  price: z.number().nullish().default(1),
});
export type Settlement = z.infer<typeof Settlement>;

export const UndeliveredEnergyPenalty = z.object({
  type: z.literal("undelivered_energy_penalty"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  pae: z.number(),
  spot_prices_coefficient: z.number(),
});
export type UndeliveredEnergyPenalty = z.infer<typeof UndeliveredEnergyPenalty>;

export const FeedInTarifBiogas = z.object({
  stored_l_index_cfg: z.union([LIndexCfg, z.null()]).nullish(),
  indexation: z.union([z.array(IndexationParameters), z.null()]).nullish(),
  type: z.literal("ft_biogas_monthly"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  contracted_capacity: z.union([z.number(), z.null()]).nullish(),
  tarif: z.number(),
  shift_hours: z.number().int().nullish(),
  use_index: z.union([z.boolean(), z.null()]).nullish().default(true),
  effluents_rate: z.number().nullish(),
  effluents_rate_threshold: z.number().nullish().default(0.6),
  effluents_tarif: z.number().nullish().default(50),
});
export type FeedInTarifBiogas = z.infer<typeof FeedInTarifBiogas>;

export const PpaThresholdMultiPeriod = z.object({
  tarif_components: z.array(TarifComponent).min(1),
  type: z.literal("ppa_threshold_multi_period"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  management_fee: z.union([z.number(), z.null()]).nullish(),
  epex_fee: z.number().nullish().default(0.07),
});
export type PpaThresholdMultiPeriod = z.infer<typeof PpaThresholdMultiPeriod>;

export const EnergyGuarantee = z.object({
  type: z.literal("energy_guarantee"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  fix_price: z.number(),
  management_fee: z.union([z.number(), z.null()]).nullish(),
  under_positive_spot_prices: z
    .union([z.boolean(), z.null()])
    .nullish()
    .default(true),
  yearly_cashflow: z.array(z.number()),
  monthly_seasonality: z
    .array(z.number())
    .nullish()
    .default([1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]),
});
export type EnergyGuarantee = z.infer<typeof EnergyGuarantee>;

export const EnergyGuaranteeRiskPremium = z.object({
  type: z.literal("energy_guarantee_risk_premium"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  guarantee_percentage: z.number(),
});
export type EnergyGuaranteeRiskPremium = z.infer<
  typeof EnergyGuaranteeRiskPremium
>;

export const PpaAsia = z.object({
  tarif_components: z.array(TarifComponent).min(1),
  type: z.literal("ppa_asia"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  ft_cost: z.union([z.number(), z.null()]).nullish(),
  discount_rate: z.number().nullish(),
});
export type PpaAsia = z.infer<typeof PpaAsia>;

export const GCRecord = z.object({
  year: z.number().int(),
  capacity: z.number(),
  price: z.number(),
});
export type GCRecord = z.infer<typeof GCRecord>;

export const GuaranteeOfCapacity = z.object({
  type: z.literal("gc"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  records: z.array(GCRecord).nullish(),
  certification_fee: z.union([z.number(), z.null()]).nullish(),
  management_fee: z.union([z.number(), z.null()]).nullish(),
});
export type GuaranteeOfCapacity = z.infer<typeof GuaranteeOfCapacity>;

export const Maintenance = z.object({
  stored_l_index_cfg: z.union([LIndexCfg, z.null()]).nullish(),
  indexation: z.union([z.array(IndexationParameters), z.null()]).nullish(),
  type: z.literal("maintenance"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  tarif: z.number(),
});
export type Maintenance = z.infer<typeof Maintenance>;

export const LeaseProduct = z.object({
  yearly_amount: z.number(),
  description: z.string(),
});
export type LeaseProduct = z.infer<typeof LeaseProduct>;

export const Lease = z.object({
  stored_l_index_cfg: z.union([LIndexCfg, z.null()]).nullish(),
  indexation: z.union([z.array(IndexationParameters), z.null()]).nullish(),
  type: z.literal("lease"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  prorata_temporis_mode: z
    .enum(["timedelta", "ndays"])
    .nullish()
    .default("ndays"),
  payment_schedule_freq: z.union([InvoicingFreq, z.null()]).nullish(),
  payment_delay_ops: z.array(PaymentDelay).nullish(),
  products: z.array(LeaseProduct).nullish(),
});
export type Lease = z.infer<typeof Lease>;

export const ClauseSchema = z.object({
  uuid: z.string().uuid(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  type: ClauseTypes,
  parameters: z.discriminatedUnion("type", [
    CfdCr16,
    CfdCr17,
    CfdMajoration,
    FeedInTarifWindMonthly,
    FeedInTarifSolarMonthly,
    FeedInTarifSolarSemestrial,
    FeedInTarifHydro,
    MarketAccess,
    SpotManagementFee,
    MarketAccessImbalance,
    PpaDa,
    PpaDaIndexedFee,
    PPAImbalanceIndexedFee,
    PpaFix,
    PpaManagementFee,
    PpaThreshold,
    PpaMultiTarif,
    NegativePrice,
    CreditNoteCap,
    Goo,
    CfdAutoconso,
    Autoconso,
    AutoconsoMaster,
    AutoconsoInseeIndexed,
    Settlement,
    UndeliveredEnergyPenalty,
    FeedInTarifBiogas,
    PpaThresholdMultiPeriod,
    EnergyGuarantee,
    EnergyGuaranteeRiskPremium,
    PpaAsia,
    GuaranteeOfCapacity,
    Maintenance,
    Lease,
  ]),
  pre_computed: z
    .union([z.array(LIndexComputationDetails), z.null()])
    .nullish(),
});
export type ClauseSchema = z.infer<typeof ClauseSchema>;

export const ContractStatusHistorySchema = z.object({
  date: z.coerce.date(),
  status: ContractStatus,
});
export type ContractStatusHistorySchema = z.infer<
  typeof ContractStatusHistorySchema
>;

export const ContractSchema = z.object({
  uuid: z.string().uuid(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  offtaker: OfftakerSchema,
  type: ContractTypeSchema,
  current_status: ContractStatus,
  price_mechanism: PriceMechanism,
  invoice_source: InvoiceSource,
  invoice_submit_channel: z.union([InvoiceSubmitChannel, z.null()]).nullish(),
  invoicing_automation_start: z.union([z.coerce.date(), z.null()]).nullish(),
  offset_consumption: z.boolean(),
  site: SiteSchemaShort,
  target_assetowner: z.union([z.string(), z.null()]).nullish(),
  external_id: z.union([z.string(), z.null()]).nullish(),
  external_name: z.union([z.string(), z.null()]).nullish(),
  purchase_order: z.union([z.string(), z.null()]).nullish(),
  order_number: z.union([z.string(), z.null()]).nullish(),
  market_number: z.union([z.string(), z.null()]).nullish(),
  payment_delay_cfg: z.array(PaymentDelay),
  clauses: z.array(ClauseSchema),
  statuses: z.array(ContractStatusHistorySchema),
  invoicing_cfg: InvoicingCfg,
});
export type ContractSchema = z.infer<typeof ContractSchema>;

export const ContractSchemaUpdate = z.object({
  start: z.union([z.coerce.date(), z.null()]).nullish(),
  end: z.union([z.coerce.date(), z.null()]).nullish(),
  type: z.union([ContractTypeSchema, z.null()]).nullish(),
  invoice_source: z.union([InvoiceSource, z.null()]).nullish(),
  purchase_order: z.union([z.string(), z.null()]).nullish(),
  invoicing_automation_start: z.union([z.coerce.date(), z.null()]).nullish(),
  invoice_submit_channel: z.union([InvoiceSubmitChannel, z.null()]).nullish(),
  offset_consumption: z.union([z.boolean(), z.null()]).nullish(),
  external_id: z.union([z.string(), z.null()]).nullish(),
  external_name: z.union([z.string(), z.null()]).nullish(),
  order_number: z.union([z.string(), z.null()]).nullish(),
  market_number: z.union([z.string(), z.null()]).nullish(),
  target_assetowner: z.union([z.string(), z.null()]).nullish(),
  payment_delay_cfg: z.union([z.array(PaymentDelay), z.null()]).nullish(),
});
export type ContractSchemaUpdate = z.infer<typeof ContractSchemaUpdate>;

export const BaseContractSchema = z.object({
  uuid: z.string().uuid(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  offtaker: OfftakerSchema,
  type: ContractTypeSchema,
  current_status: ContractStatus,
  price_mechanism: PriceMechanism,
  invoice_source: InvoiceSource,
  invoice_submit_channel: z.union([InvoiceSubmitChannel, z.null()]).nullish(),
  invoicing_automation_start: z.union([z.coerce.date(), z.null()]).nullish(),
  offset_consumption: z.boolean(),
  site: SiteSchemaShort,
  target_assetowner: z.union([z.string(), z.null()]).nullish(),
  external_id: z.union([z.string(), z.null()]).nullish(),
  external_name: z.union([z.string(), z.null()]).nullish(),
  purchase_order: z.union([z.string(), z.null()]).nullish(),
  order_number: z.union([z.string(), z.null()]).nullish(),
  market_number: z.union([z.string(), z.null()]).nullish(),
  payment_delay_cfg: z.array(PaymentDelay),
});
export type BaseContractSchema = z.infer<typeof BaseContractSchema>;

export const PartialCfdCr16 = z.object({
  stored_l_index_cfg: z.union([LIndexCfg, z.null()]).nullish(),
  indexation: z.union([z.array(IndexationParameters), z.null()]).nullish(),
  type: z.literal("cfd_cr_16"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  tarif: z.union([z.number(), z.null()]).nullish(),
  contracted_capacity: z.union([z.number(), z.null()]).nullish(),
  management_bonus: z.union([z.number(), z.null()]).nullish(),
});
export type PartialCfdCr16 = z.infer<typeof PartialCfdCr16>;

export const PartialCfdCr17 = z.object({
  stored_l_index_cfg: z.union([LIndexCfg, z.null()]).nullish(),
  indexation: z.union([z.array(IndexationParameters), z.null()]).nullish(),
  type: z.literal("cfd_cr_17"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  tarif: z.union([z.number(), z.null()]).nullish(),
  contracted_capacity: z.union([z.number(), z.null()]).nullish(),
  management_bonus: z.union([z.number(), z.null()]).nullish(),
  energy_threshold: z.union([z.number(), z.null()]).nullish(),
  tarif_above_threshold: z.union([z.number(), z.null()]).nullish(),
});
export type PartialCfdCr17 = z.infer<typeof PartialCfdCr17>;

export const PartialCfdMajoration = z.object({
  stored_l_index_cfg: z.union([LIndexCfg, z.null()]).nullish(),
  indexation: z.union([z.array(IndexationParameters), z.null()]).nullish(),
  type: z.literal("cfd_majoration"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  tarif: z.union([z.number(), z.null()]).nullish(),
  contracted_capacity: z.union([z.number(), z.null()]).nullish(),
  majoration: z.union([z.number(), z.null()]).nullish(),
});
export type PartialCfdMajoration = z.infer<typeof PartialCfdMajoration>;

export const PartialFeedInTarifWindMonthly = z.object({
  stored_l_index_cfg: z.union([LIndexCfg, z.null()]).nullish(),
  indexation: z.union([z.array(IndexationParameters), z.null()]).nullish(),
  type: z.literal("ft_wind_monthly"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  contracted_capacity: z.union([z.number(), z.null()]).nullish(),
  tarif: z.union([z.number(), z.null()]).nullish(),
});
export type PartialFeedInTarifWindMonthly = z.infer<
  typeof PartialFeedInTarifWindMonthly
>;

export const PartialFeedInTarifSolarMonthly = z.object({
  stored_l_index_cfg: z.union([LIndexCfg, z.null()]).nullish(),
  indexation: z.union([z.array(IndexationParameters), z.null()]).nullish(),
  type: z.literal("ft_solar_monthly"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  contracted_capacity: z.union([z.number(), z.null()]).nullish(),
  tarif: z.union([z.number(), z.null()]).nullish(),
  majoration: z.union([z.number(), z.null()]).nullish(),
  energy_threshold: z.union([z.number(), z.null()]).nullish(),
  tarif_above_threshold: z.union([z.number(), z.null()]).nullish(),
  apply_energy_threshold: z.union([z.boolean(), z.null()]).nullish(),
  use_index: z.union([z.boolean(), z.null()]).nullish(),
  use_index_extrapolation: z.union([z.boolean(), z.null()]).nullish(),
  production_prorata_coef: z.union([z.number(), z.null()]).nullish(),
  use_prorata_temporis: z.union([z.boolean(), z.null()]).nullish(),
});
export type PartialFeedInTarifSolarMonthly = z.infer<
  typeof PartialFeedInTarifSolarMonthly
>;

export const PartialFeedInTarifSolarSemestrial = z.object({
  stored_l_index_cfg: z.union([LIndexCfg, z.null()]).nullish(),
  indexation: z.union([z.array(IndexationParameters), z.null()]).nullish(),
  type: z.literal("ft_solar_semestrial"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  contracted_capacity: z.union([z.number(), z.null()]).nullish(),
  tarif: z.union([z.number(), z.null()]).nullish(),
  majoration: z.union([z.number(), z.null()]).nullish(),
  energy_threshold: z.union([z.number(), z.null()]).nullish(),
  tarif_above_threshold: z.union([z.number(), z.null()]).nullish(),
  apply_energy_threshold: z.union([z.boolean(), z.null()]).nullish(),
  use_index: z.union([z.boolean(), z.null()]).nullish(),
  use_index_extrapolation: z.union([z.boolean(), z.null()]).nullish(),
  production_prorata_coef: z.union([z.number(), z.null()]).nullish(),
});
export type PartialFeedInTarifSolarSemestrial = z.infer<
  typeof PartialFeedInTarifSolarSemestrial
>;

export const PartialFeedInTarifHydro = z.object({
  tarif_components: z.union([z.array(TarifComponent), z.null()]).nullish(),
  stored_l_index_cfg: z.union([LIndexCfg, z.null()]).nullish(),
  indexation: z.union([z.array(IndexationParameters), z.null()]).nullish(),
  type: z.literal("ft_hydro_monthly"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  contracted_capacity: z.union([z.number(), z.null()]).nullish(),
  quality_majoration: z.union([z.number(), z.null()]).nullish(),
  quality_coefficient: z.union([z.number(), z.null()]).nullish(),
  prorata: z.union([z.number(), z.null()]).nullish(),
  shift_hours: z.union([z.number(), z.null()]).nullish(),
});
export type PartialFeedInTarifHydro = z.infer<typeof PartialFeedInTarifHydro>;

export const PartialMarketAccess = z.object({
  type: z.literal("market_access"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  management_fee: z.union([z.number(), z.null()]).nullish(),
  management_fee_coefficient: z.union([z.number(), z.null()]).nullish(),
  exclude_neg_price_hours: z.union([z.boolean(), z.null()]).nullish(),
  neg_prices_source: z.union([NegativeHoursSource, z.null()]).nullish(),
  index_price_fallback: z.union([IndexPriceFallback, z.null()]).nullish(),
  single_line: z.union([z.boolean(), z.null()]).nullish(),
});
export type PartialMarketAccess = z.infer<typeof PartialMarketAccess>;

export const PartialSpotManagementFee = z.object({
  type: z.literal("spot_management_fee"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  formula: z.union([z.string(), z.null()]).nullish(),
  min_value: z.union([z.number(), z.null()]).nullish(),
  max_value: z.union([z.number(), z.null()]).nullish(),
});
export type PartialSpotManagementFee = z.infer<typeof PartialSpotManagementFee>;

export const PartialMarketAccessImbalance = z.object({
  type: z.literal("market_access_imb"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  management_fee: z.union([z.number(), z.null()]).nullish(),
});
export type PartialMarketAccessImbalance = z.infer<
  typeof PartialMarketAccessImbalance
>;

export const PartialPpaDa = z.object({
  type: z.literal("ppa_da"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  management_fee: z.union([z.number(), z.null()]).nullish(),
  management_coef: z.union([z.number(), z.null()]).nullish(),
});
export type PartialPpaDa = z.infer<typeof PartialPpaDa>;

export const PartialPpaDaIndexedFee = z.object({
  type: z.literal("ppa_da_indexed_fee"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  management_fee: z.union([z.number(), z.null()]).nullish(),
  management_coef: z.union([z.number(), z.null()]).nullish(),
  prorata: z.union([z.number(), z.null()]).nullish(),
});
export type PartialPpaDaIndexedFee = z.infer<typeof PartialPpaDaIndexedFee>;

export const PartialPPAImbalanceIndexedFee = z.object({
  type: z.literal("ppa_imbalance_index_fee"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  positive_imbalance_coef: z.union([z.number(), z.null()]).nullish(),
  negative_imbalance_coef: z.union([z.number(), z.null()]).nullish(),
});
export type PartialPPAImbalanceIndexedFee = z.infer<
  typeof PartialPPAImbalanceIndexedFee
>;

export const PartialPpaFix = z.object({
  type: z.literal("ppa_fix"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  fix_price: z.union([z.number(), z.null()]).nullish(),
  management_fee: z.union([z.number(), z.null()]).nullish(),
  under_positive_spot_prices: z.union([z.boolean(), z.null()]).nullish(),
});
export type PartialPpaFix = z.infer<typeof PartialPpaFix>;

export const PartialPpaManagementFee = z.object({
  stored_l_index_cfg: z.union([LIndexCfg, z.null()]).nullish(),
  indexation: z.union([z.array(IndexationParameters), z.null()]).nullish(),
  type: z.literal("ppa_management_fee"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  management_fee: z.union([z.number(), z.null()]).nullish(),
  under_positive_spot_prices: z.union([z.boolean(), z.null()]).nullish(),
});
export type PartialPpaManagementFee = z.infer<typeof PartialPpaManagementFee>;

export const PartialPpaThreshold = z.object({
  type: z.literal("ppa_threshold"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  tarif_below_cap: z.union([PpaTarifComponent, z.null()]).nullish(),
  tarif_above_cap: z.union([PpaTarifComponent, z.null()]).nullish(),
  power_threshold: z.union([z.number(), z.null()]).nullish(),
  management_fee: z.union([z.number(), z.null()]).nullish(),
});
export type PartialPpaThreshold = z.infer<typeof PartialPpaThreshold>;

export const PartialPpaMultiTarif = z.object({
  tarif_components: z.union([z.array(TarifComponent), z.null()]).nullish(),
  type: z.literal("ppa_tarif_components"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  management_fee: z.union([z.number(), z.null()]).nullish(),
});
export type PartialPpaMultiTarif = z.infer<typeof PartialPpaMultiTarif>;

export const PartialNegativePrice = z.object({
  type: z.literal("neg_price"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  price_type: z.union([PriceType, z.null()]).nullish(),
  use_absolute_value: z.union([z.boolean(), z.null()]).nullish(),
  cap_by_contract_price: z.union([z.boolean(), z.null()]).nullish(),
  management_fee: z.union([z.number(), z.null()]).nullish(),
  penalty_way_coefficient: z.union([z.number(), z.null()]).nullish(),
});
export type PartialNegativePrice = z.infer<typeof PartialNegativePrice>;

export const PartialCreditNoteCap = z.object({
  type: z.literal("credit_note_cap"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  with_deferment: z.union([z.boolean(), z.null()]).nullish(),
  statuses: z.union([z.array(InvoiceStatus), z.null()]).nullish(),
  known_deferment: z.union([z.array(DefermentRecord), z.null()]).nullish(),
});
export type PartialCreditNoteCap = z.infer<typeof PartialCreditNoteCap>;

export const PartialGoo = z.object({
  type: z.literal("goo"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  price: z.union([z.number(), z.null()]).nullish(),
});
export type PartialGoo = z.infer<typeof PartialGoo>;

export const PartialCfdAutoconso = z.object({
  type: z.literal("cfd_autoconso"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  tarif: z.union([z.number(), z.null()]).nullish(),
  premium_autoconso: z.union([z.number(), z.null()]).nullish(),
  yearly_max_capacity: z
    .union([z.array(MaxCapacityRecord), z.null()])
    .nullish(),
});
export type PartialCfdAutoconso = z.infer<typeof PartialCfdAutoconso>;

export const PartialAutoconso = z.object({
  type: z.literal("autoconso"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  prorata_temporis_mode: z
    .union([z.enum(["timedelta", "ndays"]), z.null()])
    .nullish(),
  payment_schedule_freq: z.union([InvoicingFreq, z.null()]).nullish(),
  payment_delay_ops: z.union([z.array(PaymentDelay), z.null()]).nullish(),
  yearly_tarif: z.union([z.number(), z.null()]).nullish(),
  yearly_actualisation_rate: z.union([z.number(), z.null()]).nullish(),
  bonus_malus: z.union([z.array(BonusMalusRecord), z.null()]).nullish(),
});
export type PartialAutoconso = z.infer<typeof PartialAutoconso>;

export const PartialAutoconsoMaster = z.object({
  type: z.literal("autoconso_master"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
});
export type PartialAutoconsoMaster = z.infer<typeof PartialAutoconsoMaster>;

export const PartialAutoconsoInseeIndexed = z.object({
  type: z.literal("autoconso_insee_indexed"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  prorata_temporis_mode: z
    .union([z.enum(["timedelta", "ndays"]), z.null()])
    .nullish(),
  payment_schedule_freq: z.union([InvoicingFreq, z.null()]).nullish(),
  payment_delay_ops: z.union([z.array(PaymentDelay), z.null()]).nullish(),
  yearly_tarif: z.union([z.number(), z.null()]).nullish(),
  indexation_formula: z.union([InseeIndexationFormula, z.null()]).nullish(),
  indexation_coefficient: z
    .union([z.array(IndexCoefficient), z.null()])
    .nullish(),
  unavailability_penalty: z.union([UnavailabilityPenalty, z.null()]).nullish(),
});
export type PartialAutoconsoInseeIndexed = z.infer<
  typeof PartialAutoconsoInseeIndexed
>;

export const PartialSettlement = z.object({
  type: z.literal("settlement"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  price: z.union([z.number(), z.null()]).nullish(),
});
export type PartialSettlement = z.infer<typeof PartialSettlement>;

export const PartialUndeliveredEnergyPenalty = z.object({
  type: z.literal("undelivered_energy_penalty"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  pae: z.union([z.number(), z.null()]).nullish(),
  spot_prices_coefficient: z.union([z.number(), z.null()]).nullish(),
});
export type PartialUndeliveredEnergyPenalty = z.infer<
  typeof PartialUndeliveredEnergyPenalty
>;

export const PartialFeedInTarifBiogas = z.object({
  stored_l_index_cfg: z.union([LIndexCfg, z.null()]).nullish(),
  indexation: z.union([z.array(IndexationParameters), z.null()]).nullish(),
  type: z.literal("ft_biogas_monthly"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  contracted_capacity: z.union([z.number(), z.null()]).nullish(),
  tarif: z.union([z.number(), z.null()]).nullish(),
  shift_hours: z.union([z.number(), z.null()]).nullish(),
  use_index: z.union([z.boolean(), z.null()]).nullish(),
  effluents_rate: z.union([z.number(), z.null()]).nullish(),
  effluents_rate_threshold: z.union([z.number(), z.null()]).nullish(),
  effluents_tarif: z.union([z.number(), z.null()]).nullish(),
});
export type PartialFeedInTarifBiogas = z.infer<typeof PartialFeedInTarifBiogas>;

export const PartialPpaThresholdMultiPeriod = z.object({
  tarif_components: z.union([z.array(TarifComponent), z.null()]).nullish(),
  type: z.literal("ppa_threshold_multi_period"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  management_fee: z.union([z.number(), z.null()]).nullish(),
  epex_fee: z.union([z.number(), z.null()]).nullish(),
});
export type PartialPpaThresholdMultiPeriod = z.infer<
  typeof PartialPpaThresholdMultiPeriod
>;

export const PartialEnergyGuarantee = z.object({
  type: z.literal("energy_guarantee"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  fix_price: z.union([z.number(), z.null()]).nullish(),
  management_fee: z.union([z.number(), z.null()]).nullish(),
  under_positive_spot_prices: z.union([z.boolean(), z.null()]).nullish(),
  yearly_cashflow: z.union([z.array(z.number()), z.null()]).nullish(),
  monthly_seasonality: z.union([z.array(z.number()), z.null()]).nullish(),
});
export type PartialEnergyGuarantee = z.infer<typeof PartialEnergyGuarantee>;

export const PartialEnergyGuaranteeRiskPremium = z.object({
  type: z.literal("energy_guarantee_risk_premium"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  guarantee_percentage: z.union([z.number(), z.null()]).nullish(),
});
export type PartialEnergyGuaranteeRiskPremium = z.infer<
  typeof PartialEnergyGuaranteeRiskPremium
>;

export const PartialPpaAsia = z.object({
  tarif_components: z.union([z.array(TarifComponent), z.null()]).nullish(),
  type: z.literal("ppa_asia"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  ft_cost: z.union([z.number(), z.null()]).nullish(),
  discount_rate: z.union([z.number(), z.null()]).nullish(),
});
export type PartialPpaAsia = z.infer<typeof PartialPpaAsia>;

export const PartialGuaranteeOfCapacity = z.object({
  type: z.literal("gc"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  records: z.union([z.array(GCRecord), z.null()]).nullish(),
  certification_fee: z.union([z.number(), z.null()]).nullish(),
  management_fee: z.union([z.number(), z.null()]).nullish(),
});
export type PartialGuaranteeOfCapacity = z.infer<
  typeof PartialGuaranteeOfCapacity
>;

export const PartialMaintenance = z.object({
  stored_l_index_cfg: z.union([LIndexCfg, z.null()]).nullish(),
  indexation: z.union([z.array(IndexationParameters), z.null()]).nullish(),
  type: z.literal("maintenance"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  tarif: z.union([z.number(), z.null()]).nullish(),
});
export type PartialMaintenance = z.infer<typeof PartialMaintenance>;

export const PartialLease = z.object({
  stored_l_index_cfg: z.union([LIndexCfg, z.null()]).nullish(),
  indexation: z.union([z.array(IndexationParameters), z.null()]).nullish(),
  type: z.literal("lease"),
  stored_energy_params: z.union([PartialEnergyParameters, z.null()]).nullish(),
  clause_invoicing_cfg: z.union([InvoicingCfg, z.null()]).nullish(),
  prorata_temporis_mode: z
    .union([z.enum(["timedelta", "ndays"]), z.null()])
    .nullish(),
  payment_schedule_freq: z.union([InvoicingFreq, z.null()]).nullish(),
  payment_delay_ops: z.union([z.array(PaymentDelay), z.null()]).nullish(),
  products: z.union([z.array(LeaseProduct), z.null()]).nullish(),
});
export type PartialLease = z.infer<typeof PartialLease>;

export const ClauseUpdateSchema = z.object({
  start: z.union([z.coerce.date(), z.null()]).nullish(),
  end: z.union([z.coerce.date(), z.null()]).nullish(),
  alias: z.union([z.string(), z.null()]).nullish(),
  parameters: z
    .union([
      z.discriminatedUnion("type", [
        PartialCfdCr16,
        PartialCfdCr17,
        PartialCfdMajoration,
        PartialFeedInTarifWindMonthly,
        PartialFeedInTarifSolarMonthly,
        PartialFeedInTarifSolarSemestrial,
        PartialFeedInTarifHydro,
        PartialMarketAccess,
        PartialSpotManagementFee,
        PartialMarketAccessImbalance,
        PartialPpaDa,
        PartialPpaDaIndexedFee,
        PartialPPAImbalanceIndexedFee,
        PartialPpaFix,
        PartialPpaManagementFee,
        PartialPpaThreshold,
        PartialPpaMultiTarif,
        PartialNegativePrice,
        PartialCreditNoteCap,
        PartialGoo,
        PartialCfdAutoconso,
        PartialAutoconso,
        PartialAutoconsoMaster,
        PartialAutoconsoInseeIndexed,
        PartialSettlement,
        PartialUndeliveredEnergyPenalty,
        PartialFeedInTarifBiogas,
        PartialPpaThresholdMultiPeriod,
        PartialEnergyGuarantee,
        PartialEnergyGuaranteeRiskPremium,
        PartialPpaAsia,
        PartialGuaranteeOfCapacity,
        PartialMaintenance,
        PartialLease,
      ]),
      z.null(),
    ])
    .nullish(),
});
export type ClauseUpdateSchema = z.infer<typeof ClauseUpdateSchema>;

export const TSMetadata = z.object({
  freq: z.string(),
  unit: z.string(),
  horizon_start: z.union([z.coerce.date(), z.null()]).nullish(),
  horizon_end: z.union([z.coerce.date(), z.null()]).nullish(),
});
export type TSMetadata = z.infer<typeof TSMetadata>;

export const version = z
  .union([z.string(), z.coerce.date(), z.null()])
  .nullish();
export type version = z.infer<typeof version>;

export const missing_freq_tol = z.union([z.number(), z.null()]).nullish();
export type missing_freq_tol = z.infer<typeof missing_freq_tol>;

export const VersionStatistics = z.object({
  min_index: z.coerce.date(),
  max_index: z.coerce.date(),
  completeness: z.number(),
  min_value: z.number(),
  max_value: z.number(),
  avg_value: z.number(),
  std_value: z.union([z.number(), z.null()]).nullish(),
  count_value: z.number().int(),
  version: z.coerce.date(),
});
export type VersionStatistics = z.infer<typeof VersionStatistics>;

export const Statistics = z.object({
  min_index: z.coerce.date(),
  max_index: z.coerce.date(),
  completeness: z.number(),
  min_value: z.number(),
  max_value: z.number(),
  avg_value: z.number(),
  std_value: z.union([z.number(), z.null()]).nullish(),
  count_value: z.number().int(),
  tz: z.string(),
  freq: z.string(),
  versions: z.array(VersionStatistics).nullish(),
  version_freq: z.union([z.string(), z.null()]).nullish(),
  version_delta_horizon: z.union([z.string(), z.null()]).nullish(),
});
export type Statistics = z.infer<typeof Statistics>;

export const Body_get_timeseries_ids_from_model_api__model___uuid__timeseries_tsids_post =
  z.object({
    tags: z.array(
      z.union([z.string(), z.array(z.union([z.string(), z.null()])), z.null()])
    ),
  });
export type Body_get_timeseries_ids_from_model_api__model___uuid__timeseries_tsids_post =
  z.infer<
    typeof Body_get_timeseries_ids_from_model_api__model___uuid__timeseries_tsids_post
  >;

export const Body_get_timeseries_data_from_model_api__model___uuid__timeseries_data_post =
  z.object({
    tags: z.array(
      z.union([z.string(), z.array(z.union([z.string(), z.null()])), z.null()])
    ),
  });
export type Body_get_timeseries_data_from_model_api__model___uuid__timeseries_data_post =
  z.infer<
    typeof Body_get_timeseries_data_from_model_api__model___uuid__timeseries_data_post
  >;

export const PartnerSummarySchema = z.object({
  uuid: z.string().uuid(),
  name: z.string(),
  logo: z.union([z.string(), z.null()]).nullish(),
  url: z.union([z.string(), z.null()]).nullish(),
  kind: z.union([z.string(), z.null()]).nullish(),
  capacity: z.union([z.number(), z.null()]).nullish(),
  contracts_number: z.union([z.number(), z.null()]).nullish(),
  contacts: z.array(ContactInfoSchema),
});
export type PartnerSummarySchema = z.infer<typeof PartnerSummarySchema>;

export const FilesQueryModelName = z.enum([
  "assetmanager",
  "assetowner",
  "holding",
  "site",
  "contract",
  "deliverypoint",
]);
export type FilesQueryModelName = z.infer<typeof FilesQueryModelName>;

export const FileInfoSchema = z.object({
  filename: z.string(),
  size: z.number().int(),
  modified: z.coerce.date(),
  model: FilesQueryModelName,
  uuid: z.string(),
});
export type FileInfoSchema = z.infer<typeof FileInfoSchema>;

export const Body_post_files_filtered_by_model_api__model___uuid__files_post =
  z.object({ files: z.array(z.instanceof(File)) });
export type Body_post_files_filtered_by_model_api__model___uuid__files_post =
  z.infer<
    typeof Body_post_files_filtered_by_model_api__model___uuid__files_post
  >;

export const EventType = z.enum(["stop", "downgraded", "informative"]);
export type EventType = z.infer<typeof EventType>;

export const EventCause = z.enum([
  "preventive_maintenance",
  "corrective_maintenance",
  "negative_price",
  "network",
  "breakdown",
]);
export type EventCause = z.infer<typeof EventCause>;

export const EventLosses = z.object({
  energy_loss: z.union([z.number(), z.null()]).nullish(),
  revenue_loss: z.union([z.number(), z.null()]).nullish(),
});
export type EventLosses = z.infer<typeof EventLosses>;

export const EventSource = z.enum(["ai", "power_production", "manual"]);
export type EventSource = z.infer<typeof EventSource>;

export const EventSchema = z.object({
  start: z.coerce.date(),
  end: z.coerce.date(),
  description: z.union([z.string(), z.null()]).nullish(),
  is_external: z.boolean(),
  is_validated: z.boolean(),
  uuid: z.string().uuid(),
  site: SiteSchemaShort,
  available_capacity: z.number(),
  creation_date: z.coerce.date(),
  last_update: z.coerce.date(),
  event_type: EventType,
  cause: z.union([EventCause, z.null()]).nullish(),
  losses: z.union([EventLosses, z.null()]).nullish(),
  source: EventSource,
});
export type EventSchema = z.infer<typeof EventSchema>;

export const EventSchemaUpdate = z.object({
  start: z.union([z.coerce.date(), z.null()]).nullish(),
  end: z.union([z.coerce.date(), z.null()]).nullish(),
  event_type: z.union([EventType, z.null()]).nullish(),
  cause: z.union([EventCause, z.null()]).nullish(),
  description: z.union([z.string(), z.null()]).nullish(),
  available_capacity: z.union([z.number(), z.null()]).nullish(),
  unavailable_capacity: z.union([z.number(), z.null()]).nullish(),
  available_percentage: z.union([z.number(), z.null()]).nullish(),
  unavailable_percentage: z.union([z.number(), z.null()]).nullish(),
  site_uuid: z.union([z.string(), z.null()]).nullish(),
  is_external: z.union([z.boolean(), z.null()]).nullish(),
  is_validated: z.union([z.boolean(), z.null()]).nullish(),
  source: z.union([EventSource, z.null()]).nullish(),
});
export type EventSchemaUpdate = z.infer<typeof EventSchemaUpdate>;

export const EventSchemaCreate = z.object({
  start: z.coerce.date(),
  end: z.coerce.date(),
  description: z.union([z.string(), z.null()]).nullish(),
  is_external: z.boolean(),
  is_validated: z.boolean(),
  event_type: z.union([EventType, z.null()]).nullish(),
  cause: z.union([EventCause, z.null()]).nullish(),
  available_capacity: z.union([z.number(), z.null()]).nullish(),
  unavailable_capacity: z.union([z.number(), z.null()]).nullish(),
  available_percentage: z.union([z.number(), z.null()]).nullish(),
  unavailable_percentage: z.union([z.number(), z.null()]).nullish(),
  site_uuid: z.string().uuid(),
});
export type EventSchemaCreate = z.infer<typeof EventSchemaCreate>;

export const DocKind = z.enum([
  "invoice",
  "contract",
  "productivity_study",
  "production_report",
  "finance_report",
  "operation_report",
  "commissioning_report",
  "performance_test_report",
  "final_acceptance_report",
  "pr_report",
  "cumulative_index_report",
  "data_extract",
  "contract_data_extract",
  "load_profile_extract",
  "partner_extract",
  "finance_landing_extract",
  "invoice_lines_report",
  "invoices_accounting_report",
  "edf_oa_account_details",
  "invoice_bonus_malus",
]);
export type DocKind = z.infer<typeof DocKind>;

export const DocumentQueryModelName = z.enum([
  "assetmanager",
  "assetowner",
  "holding",
  "site",
  "portfolio",
  "contract",
  "invoice",
]);
export type DocumentQueryModelName = z.infer<typeof DocumentQueryModelName>;

export const DocumentSchemaShort = z.object({
  uuid: z.string().uuid(),
  kind: z.union([DocKind, z.null()]).nullish(),
  filename: z.string(),
  creation_date: z.coerce.date(),
  last_update: z.coerce.date(),
  target_uuid: z.string().uuid(),
  target_model: DocumentQueryModelName,
  file_exists: z.boolean(),
  is_attachment: z.boolean(),
});
export type DocumentSchemaShort = z.infer<typeof DocumentSchemaShort>;

export const docgen_kind = z.union([DocKind, z.null()]).nullish();
export type docgen_kind = z.infer<typeof docgen_kind>;

export const Body_create_document_api__model___uuid__document_post = z.object({
  files: z.array(z.instanceof(File)),
  kind: DocKind,
  is_attachment: z.boolean().nullish(),
});
export type Body_create_document_api__model___uuid__document_post = z.infer<
  typeof Body_create_document_api__model___uuid__document_post
>;

export const DocGenConfigSchema = z.object({
  kind: DocKind,
  skeleton_article_name: z.union([z.string(), z.null()]).nullish(),
  article_names: z.union([z.array(z.string()), z.record(z.array(z.string()))]),
  static_input: z.record(z.any()),
  description: z.union([z.string(), z.null()]).nullish(),
});
export type DocGenConfigSchema = z.infer<typeof DocGenConfigSchema>;

export const DocumentSchema = z.object({
  uuid: z.string().uuid(),
  kind: z.union([DocKind, z.null()]).nullish(),
  filename: z.string(),
  creation_date: z.coerce.date(),
  last_update: z.coerce.date(),
  target_uuid: z.string().uuid(),
  target_model: DocumentQueryModelName,
  file_exists: z.boolean(),
  is_attachment: z.boolean(),
  docgen_config: z.union([DocGenConfigSchema, z.null()]),
  gen_input: z.record(z.any()),
});
export type DocumentSchema = z.infer<typeof DocumentSchema>;

export const Body_refresh_documents_api_document_post = z.object({
  uuids: z.array(z.string()),
});
export type Body_refresh_documents_api_document_post = z.infer<
  typeof Body_refresh_documents_api_document_post
>;

export const NoKwargsReport = z.object({
  uuids: z.array(z.string()),
  kind: z.enum([
    "performance_test_report",
    "final_acceptance_report",
    "commissioning_report",
    "invoice_lines_report",
    "partner_extract",
  ]),
  filename: z.union([z.string(), z.null()]).nullish(),
});
export type NoKwargsReport = z.infer<typeof NoKwargsReport>;

export const DateRangeReportKwargs = z.object({
  uuids: z.array(z.string()),
  kind: z.enum([
    "pr_report",
    "invoices_accounting_report",
    "contract_data_extract",
  ]),
  filename: z.union([z.string(), z.null()]).nullish(),
  start: z.coerce.date(),
  end: z.coerce.date(),
});
export type DateRangeReportKwargs = z.infer<typeof DateRangeReportKwargs>;

export const GSDataCategory = z.enum([
  "realtime",
  "metering",
  "producible",
  "operation",
  "revenue",
  "budget",
  "self_consumption",
]);
export type GSDataCategory = z.infer<typeof GSDataCategory>;

export const DataExtractReportKwargs = z.object({
  uuids: z.array(z.string()),
  kind: z.literal("data_extract"),
  filename: z.union([z.string(), z.null()]).nullish(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  categories: z.array(GSDataCategory).nullish().default(["metering"]),
  aggregate: z.boolean().nullish(),
  freq: z
    .enum(["1D", "1MS", "1YS", "1W-MON", "1AS-JAN"])
    .nullish()
    .default("1MS"),
});
export type DataExtractReportKwargs = z.infer<typeof DataExtractReportKwargs>;

export const CumIndexReportKwargs = z.object({
  uuids: z.array(z.string()),
  kind: z.literal("cumulative_index_report"),
  filename: z.union([z.string(), z.null()]).nullish(),
  date: z.union([z.coerce.date(), z.null()]).nullish(),
});
export type CumIndexReportKwargs = z.infer<typeof CumIndexReportKwargs>;

export const GSMetricName = z.enum([
  "total_consumption",
  "self_consumption_rate",
  "self_production_rate",
  "coverage_rate",
  "max_injected_power",
  "max_offtaken_power",
  "pr_budget",
  "trs_budget",
  "pr_budget_recalled",
  "trs_budget_recalled",
  "revenue",
  "availability_injection",
  "availability_detailed",
  "availability_energy_prorated",
  "availability_technical",
  "production_loss_internal",
  "production_loss_global",
  "trs",
  "pr",
  "production",
  "consumption",
  "energy",
]);
export type GSMetricName = z.infer<typeof GSMetricName>;

export const BudgetScope = z.enum(["budget", "business_plan", "bank"]);
export type BudgetScope = z.infer<typeof BudgetScope>;

export const OperationReportKwargs = z.object({
  uuids: z.array(z.string()),
  kind: z.literal("operation_report"),
  filename: z.union([z.string(), z.null()]).nullish(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  title: z.string().nullish().default("Rapport d'Exploitation"),
  metric_names: z
    .array(GSMetricName)
    .nullish()
    .default([
      "production",
      "availability_injection",
      "pr",
      "availability_technical",
    ]),
  budget_scope: z.union([BudgetScope, z.null()]).nullish(),
});
export type OperationReportKwargs = z.infer<typeof OperationReportKwargs>;

export const FinanceReportKwargs = z.object({
  uuids: z.array(z.string()),
  kind: z.literal("finance_report"),
  filename: z.union([z.string(), z.null()]).nullish(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  title: z.string().nullish().default("Rapport Financier"),
  metric_names: z
    .array(GSMetricName)
    .nullish()
    .default(["production", "revenue"]),
  budget_scope: z.union([BudgetScope, z.null()]).nullish(),
});
export type FinanceReportKwargs = z.infer<typeof FinanceReportKwargs>;

export const LoadProfileExtractReportKwargs = z.object({
  uuids: z.array(z.string()),
  kind: z.literal("load_profile_extract"),
  filename: z.union([z.string(), z.null()]).nullish(),
  start: z.coerce.date(),
  end: z.coerce.date(),
  reference: z.union([z.string(), z.null()]).nullish(),
  ways: z
    .array(z.enum(["PROD", "CONS"]))
    .nullish()
    .default(["PROD"]),
});
export type LoadProfileExtractReportKwargs = z.infer<
  typeof LoadProfileExtractReportKwargs
>;

export const generate_documents_Body = z.discriminatedUnion("kind", [
  NoKwargsReport,
  DateRangeReportKwargs,
  DataExtractReportKwargs,
  CumIndexReportKwargs,
  OperationReportKwargs,
  FinanceReportKwargs,
  LoadProfileExtractReportKwargs,
]);

export type generate_documents_Body = z.infer<typeof generate_documents_Body>;

export const HorizonLabel = z.enum([
  "ytd",
  "yt_last_month",
  "yt_quarter",
  "last_year",
  "rolling_year",
  "current_month",
  "last_month",
  "rolling_month",
  "current_week",
  "last_week",
  "rolling_week",
  "today",
  "yesterday",
  "custom",
  "rolling_0_30",
  "rolling_0_90",
  "rolling_30_60",
  "rolling_30_120",
]);
export type HorizonLabel = z.infer<typeof HorizonLabel>;

export const horizon = z.union([HorizonLabel, z.null()]).nullish();
export type horizon = z.infer<typeof horizon>;

export const ComputedHorizon = z.object({
  label: HorizonLabel,
  base: z.array(z.coerce.date()).nullish(),
  versus: z.union([z.array(z.coerce.date()), z.null()]).nullish(),
  base_str: z.string(),
  versus_str: z.union([z.string(), z.null()]),
});
export type ComputedHorizon = z.infer<typeof ComputedHorizon>;

export const MetricStatus = z.enum([
  "missing",
  "missing_configuration",
  "good",
  "warning",
  "error",
  "compute_error",
  "skipped",
  "na",
]);
export type MetricStatus = z.infer<typeof MetricStatus>;

export const MetricSchema = z.object({
  uuid: z.string().uuid(),
  name: z.string(),
  horizon: z.union([ComputedHorizon, z.null()]).nullish(),
  value: z.unknown(),
  last_refresh: z.union([z.coerce.date(), z.null()]).nullish(),
  status: MetricStatus,
  err_details: z.unknown(),
});
export type MetricSchema = z.infer<typeof MetricSchema>;

export const DeliveryPointSchemaUpdate = z.object({
  code: z.union([z.string(), z.null()]).nullish(),
  reference: z.union([z.string(), z.null()]).nullish(),
  network_voltage: z.union([NetworkVoltageSchema, z.null()]).nullish(),
  code_kind: z.union([DeliveryPointCodeKind, z.null()]).nullish(),
  type: z.union([DeliveryPointType, z.null()]).nullish(),
  extra: z.union([z.record(z.any()), z.null()]).nullish(),
  network_extra: z.union([EnedisNetworkCfg, z.null()]).nullish(),
});
export type DeliveryPointSchemaUpdate = z.infer<
  typeof DeliveryPointSchemaUpdate
>;

export const Way = z.enum(["consumption", "production"]);
export type Way = z.infer<typeof Way>;

export const MeasureType = z.enum(["load_profile", "index", "invoicing"]);
export type MeasureType = z.infer<typeof MeasureType>;

export const Body_trigger_enedis_backfill_api_etl_enedis__uuid__backfill_post =
  z.object({
    ways: z.array(Way).nullish().default(["consumption", "production"]),
    measure_types: z
      .array(MeasureType)
      .nullish()
      .default(["load_profile", "index", "invoicing"]),
    start: z.coerce.date(),
    end: z.coerce.date(),
  });
export type Body_trigger_enedis_backfill_api_etl_enedis__uuid__backfill_post =
  z.infer<
    typeof Body_trigger_enedis_backfill_api_etl_enedis__uuid__backfill_post
  >;

export const Body_trigger_soda_backfill_api_etl_soda__uuid__backfill_post =
  z.object({ start: z.coerce.date(), end: z.coerce.date() });
export type Body_trigger_soda_backfill_api_etl_soda__uuid__backfill_post =
  z.infer<typeof Body_trigger_soda_backfill_api_etl_soda__uuid__backfill_post>;

export const _BaseGaugeModel = z.object({
  value: z.union([z.number(), z.null()]).nullish(),
  versus: z.union([z.number(), z.null()]).nullish(),
  weight: z.number().nullish().default(1),
  diff: z.union([z.number(), z.null()]),
  rate: z.union([z.number(), z.null()]),
  ratio: z.union([z.number(), z.null()]),
});
export type _BaseGaugeModel = z.infer<typeof _BaseGaugeModel>;

export const GaugeModel = z.object({
  value: z.union([z.number(), z.null()]).nullish(),
  versus: z.union([z.number(), z.null()]).nullish(),
  weight: z.number().nullish().default(1),
  gauge_budget: z.union([_BaseGaugeModel, z.null()]).nullish(),
  diff: z.union([z.number(), z.null()]),
  rate: z.union([z.number(), z.null()]),
  ratio: z.union([z.number(), z.null()]),
});
export type GaugeModel = z.infer<typeof GaugeModel>;

export const GSGaugeMetric = z.object({
  name: z.string(),
  gauge: GaugeModel,
  horizon: ComputedHorizon,
});
export type GSGaugeMetric = z.infer<typeof GSGaugeMetric>;

export const MachineType = z.enum([
  "wind_turbine",
  "solar_field",
  "cogeneration",
  "hydro_turbine",
]);
export type MachineType = z.infer<typeof MachineType>;

export const MachineSubType = z.enum([
  "wind_turbine_onshore",
  "wind_turbine_offshore",
  "solar_field_ground_mounted",
  "solar_field_rooftop",
  "solar_field_canopy",
  "hydro_turbine_run_of_river",
  "hydro_turbine_pumped_storage",
  "hydro_turbine_reservoir",
  "cogeneration_biomass",
  "cogeneration_waste",
  "cogeneration_other",
]);
export type MachineSubType = z.infer<typeof MachineSubType>;

export const technos = z
  .union([z.array(z.union([MachineType, MachineSubType])), z.null()])
  .nullish();
export type technos = z.infer<typeof technos>;

export const GSSiteSchema = z.object({
  uuid: z.string().uuid(),
  name: z.string(),
  external_id: z.union([z.string(), z.null()]).nullish(),
  dp_codes: z.array(z.string()),
  is_external: z.boolean(),
  assetowner: CompanySchemaShort,
  capacity: z.number(),
  portfolio: z.union([PortfolioSchema, z.null()]).nullish(),
});
export type GSSiteSchema = z.infer<typeof GSSiteSchema>;

export const GSSchema = z.object({
  start: z.union([z.coerce.date(), z.null()]).nullish(),
  end: z.union([z.coerce.date(), z.null()]).nullish(),
  freq: z.enum(["1MS", "1D"]),
  site: GSSiteSchema,
  last_update: z.coerce.date(),
});
export type GSSchema = z.infer<typeof GSSchema>;

export const GSRefreshModel = z.enum([
  "site",
  "assetowner",
  "assetmanager",
  "holding",
]);
export type GSRefreshModel = z.infer<typeof GSRefreshModel>;

export const GSRefreshParams = z.object({
  model: GSRefreshModel,
  uuids: z.array(z.string()),
  start: z.union([z.coerce.date(), z.null()]).nullish(),
  end: z.union([z.coerce.date(), z.null()]).nullish(),
  refresh_freqs: z
    .array(z.enum(["1D", "1MS"]))
    .nullish()
    .default(["1D", "1MS"]),
  to_refresh: z
    .array(GSDataCategory)
    .nullish()
    .default([
      "realtime",
      "metering",
      "producible",
      "operation",
      "revenue",
      "budget",
      "self_consumption",
    ]),
});
export type GSRefreshParams = z.infer<typeof GSRefreshParams>;

export const GSKpiLevel = z.enum([
  "site",
  "assetowner",
  "holding",
  "regional",
  "portfolio",
  "region",
  "machine_type",
  "machine_subtype",
]);
export type GSKpiLevel = z.infer<typeof GSKpiLevel>;

export const aggregation_levels = z
  .union([z.array(GSKpiLevel), z.null()])
  .nullish();
export type aggregation_levels = z.infer<typeof aggregation_levels>;

export const SiteGSKpi = z.object({
  target_name: z.string(),
  is_external: z.boolean(),
  site_statuses: z.record(z.number().int()),
  techno: z.record(z.number().int()),
  capacity: z.number(),
  production: z.union([GaugeModel, z.null()]).nullish(),
  revenue: z.union([GaugeModel, z.null()]).nullish(),
  pr: z.union([GaugeModel, z.null()]).nullish(),
  availability_injection: z.union([GaugeModel, z.null()]).nullish(),
  target_model: z.literal("site"),
  target_uuid: z.string().uuid(),
  portfolio: z.union([z.string(), z.null()]).nullish(),
  region: z.union([z.string(), z.null()]).nullish(),
});
export type SiteGSKpi = z.infer<typeof SiteGSKpi>;

export const SubGroupGSKpiUuid = z.object({
  target_name: z.string(),
  is_external: z.boolean(),
  site_statuses: z.record(z.number().int()),
  techno: z.record(z.number().int()),
  capacity: z.number(),
  production: z.union([GaugeModel, z.null()]).nullish(),
  revenue: z.union([GaugeModel, z.null()]).nullish(),
  pr: z.union([GaugeModel, z.null()]).nullish(),
  availability_injection: z.union([GaugeModel, z.null()]).nullish(),
  target_model: GSKpiLevel,
  target_uuid: z.string().uuid(),
  children: z.array(SiteGSKpi),
});
export type SubGroupGSKpiUuid = z.infer<typeof SubGroupGSKpiUuid>;

export const SubGroupGSKpi = z.object({
  target_name: z.string(),
  is_external: z.boolean(),
  site_statuses: z.record(z.number().int()),
  techno: z.record(z.number().int()),
  capacity: z.number(),
  production: z.union([GaugeModel, z.null()]).nullish(),
  revenue: z.union([GaugeModel, z.null()]).nullish(),
  pr: z.union([GaugeModel, z.null()]).nullish(),
  availability_injection: z.union([GaugeModel, z.null()]).nullish(),
  target_model: GSKpiLevel,
  children: z.array(SiteGSKpi),
});
export type SubGroupGSKpi = z.infer<typeof SubGroupGSKpi>;

export const GroupGSKpi = z.object({
  target_name: z.string(),
  is_external: z.boolean(),
  site_statuses: z.record(z.number().int()),
  techno: z.record(z.number().int()),
  capacity: z.number(),
  production: z.union([GaugeModel, z.null()]).nullish(),
  revenue: z.union([GaugeModel, z.null()]).nullish(),
  pr: z.union([GaugeModel, z.null()]).nullish(),
  availability_injection: z.union([GaugeModel, z.null()]).nullish(),
  target_model: z.enum(["region", "machine_subtype", "machine_type"]),
  children: z.array(z.union([SiteGSKpi, SubGroupGSKpiUuid, SubGroupGSKpi])),
});
export type GroupGSKpi = z.infer<typeof GroupGSKpi>;

export const GroupGSKpiUuid = z.object({
  target_name: z.string(),
  is_external: z.boolean(),
  site_statuses: z.record(z.number().int()),
  techno: z.record(z.number().int()),
  capacity: z.number(),
  production: z.union([GaugeModel, z.null()]).nullish(),
  revenue: z.union([GaugeModel, z.null()]).nullish(),
  pr: z.union([GaugeModel, z.null()]).nullish(),
  availability_injection: z.union([GaugeModel, z.null()]).nullish(),
  target_model: z.enum(["portfolio", "holding", "assetowner"]),
  target_uuid: z.string().uuid(),
  children: z.array(z.union([SiteGSKpi, SubGroupGSKpiUuid, SubGroupGSKpi])),
});
export type GroupGSKpiUuid = z.infer<typeof GroupGSKpiUuid>;

export const GSKpiMachineSubType = z.object({
  n_sites: z.number().int(),
  n_spvs: z.number().int(),
  capacity: z.number(),
  production: z.union([GaugeModel, z.null()]).nullish(),
  production_budget: z.union([z.number(), z.null()]).nullish(),
  revenue: z.union([GaugeModel, z.null()]).nullish(),
  revenue_budget: z.union([z.number(), z.null()]).nullish(),
  techno: MachineSubType,
});
export type GSKpiMachineSubType = z.infer<typeof GSKpiMachineSubType>;

export const GSKpiTechno = z.object({
  n_sites: z.number().int(),
  n_spvs: z.number().int(),
  capacity: z.number(),
  production: z.union([GaugeModel, z.null()]).nullish(),
  production_budget: z.union([z.number(), z.null()]).nullish(),
  revenue: z.union([GaugeModel, z.null()]).nullish(),
  revenue_budget: z.union([z.number(), z.null()]).nullish(),
  techno: MachineType,
  children: z.array(GSKpiMachineSubType).nullish(),
});
export type GSKpiTechno = z.infer<typeof GSKpiTechno>;

export const AddressSchemaWithPermission = z.object({
  uuid: z.string().uuid(),
  name: z.union([z.string(), z.null()]).nullish(),
  address: z.union([z.string(), z.null()]).nullish(),
  address_extra: z.union([z.string(), z.null()]).nullish(),
  postal_box: z.union([z.string(), z.null()]).nullish(),
  city: z.union([z.string(), z.null()]).nullish(),
  postal_code: z.union([z.string(), z.null()]).nullish(),
  country: z.union([z.string(), z.null()]).nullish(),
  editable: z.boolean(),
});
export type AddressSchemaWithPermission = z.infer<
  typeof AddressSchemaWithPermission
>;

export const AddressSchemaCreate = z.object({
  name: z.union([z.string(), z.null()]).nullish(),
  address: z.union([z.string(), z.null()]).nullish(),
  address_extra: z.union([z.string(), z.null()]).nullish(),
  postal_box: z.union([z.string(), z.null()]).nullish(),
  city: z.union([z.string(), z.null()]).nullish(),
  postal_code: z.union([z.string(), z.null()]).nullish(),
  country: z.union([z.string(), z.null()]).nullish(),
});
export type AddressSchemaCreate = z.infer<typeof AddressSchemaCreate>;

export const SiteSchemaTiny = z.object({
  uuid: z.string().uuid(),
  name: z.string(),
});
export type SiteSchemaTiny = z.infer<typeof SiteSchemaTiny>;

export const AddressSchemaLong = z.object({
  uuid: z.string().uuid(),
  name: z.union([z.string(), z.null()]).nullish(),
  address: z.union([z.string(), z.null()]).nullish(),
  address_extra: z.union([z.string(), z.null()]).nullish(),
  postal_box: z.union([z.string(), z.null()]).nullish(),
  city: z.union([z.string(), z.null()]).nullish(),
  postal_code: z.union([z.string(), z.null()]).nullish(),
  country: z.union([z.string(), z.null()]).nullish(),
  editable: z.boolean(),
  owner: z.union([CompanySchemaShort, z.null()]),
  companies: z.array(CompanySchemaShort),
  sites: z.array(SiteSchemaTiny),
});
export type AddressSchemaLong = z.infer<typeof AddressSchemaLong>;

export const AddressSchemaUpdate = z.object({
  name: z.union([z.string(), z.null()]).nullish(),
  address: z.union([z.string(), z.null()]).nullish(),
  address_extra: z.union([z.string(), z.null()]).nullish(),
  postal_box: z.union([z.string(), z.null()]).nullish(),
  city: z.union([z.string(), z.null()]).nullish(),
  postal_code: z.union([z.string(), z.null()]).nullish(),
  country: z.union([z.string(), z.null()]).nullish(),
});
export type AddressSchemaUpdate = z.infer<typeof AddressSchemaUpdate>;

export const ContactInfoSchemaWithPermission = z.object({
  uuid: z.string().uuid(),
  firstname: z.union([z.string(), z.null()]).nullish(),
  lastname: z.union([z.string(), z.null()]).nullish(),
  team_name: z.union([z.string(), z.null()]).nullish(),
  position: z.union([z.string(), z.null()]).nullish(),
  email: z.union([z.string(), z.null()]).nullish(),
  company_phone: z.union([z.string(), z.null()]).nullish(),
  mobile_phone: z.union([z.string(), z.null()]).nullish(),
  cloud_image: z.union([ContactCloudImages, z.null()]).nullish(),
  editable: z.boolean(),
});
export type ContactInfoSchemaWithPermission = z.infer<
  typeof ContactInfoSchemaWithPermission
>;

export const ContactInfoSchemaCreate = z.object({
  firstname: z.union([z.string(), z.null()]).nullish(),
  lastname: z.union([z.string(), z.null()]).nullish(),
  team_name: z.union([z.string(), z.null()]).nullish(),
  position: z.union([z.string(), z.null()]).nullish(),
  email: z.union([z.string(), z.null()]).nullish(),
  company_phone: z.union([z.string(), z.null()]).nullish(),
  mobile_phone: z.union([z.string(), z.null()]).nullish(),
  cloud_image: z.union([ContactCloudImages, z.null()]).nullish(),
});
export type ContactInfoSchemaCreate = z.infer<typeof ContactInfoSchemaCreate>;

export const ContactInfoSchemaLong = z.object({
  uuid: z.string().uuid(),
  firstname: z.union([z.string(), z.null()]).nullish(),
  lastname: z.union([z.string(), z.null()]).nullish(),
  team_name: z.union([z.string(), z.null()]).nullish(),
  position: z.union([z.string(), z.null()]).nullish(),
  email: z.union([z.string(), z.null()]).nullish(),
  company_phone: z.union([z.string(), z.null()]).nullish(),
  mobile_phone: z.union([z.string(), z.null()]).nullish(),
  cloud_image: z.union([ContactCloudImages, z.null()]).nullish(),
  editable: z.boolean(),
  owner: z.union([CompanySchemaShort, z.null()]),
  companies: z.array(CompanySchemaShort),
  sites: z.array(SiteSchemaTiny),
});
export type ContactInfoSchemaLong = z.infer<typeof ContactInfoSchemaLong>;

export const ContactInfoSchemaUpdate = z.object({
  firstname: z.union([z.string(), z.null()]).nullish(),
  lastname: z.union([z.string(), z.null()]).nullish(),
  team_name: z.union([z.string(), z.null()]).nullish(),
  position: z.union([z.string(), z.null()]).nullish(),
  email: z.union([z.string(), z.null()]).nullish(),
  company_phone: z.union([z.string(), z.null()]).nullish(),
  mobile_phone: z.union([z.string(), z.null()]).nullish(),
  cloud_image: z.union([ContactCloudImages, z.null()]).nullish(),
});
export type ContactInfoSchemaUpdate = z.infer<typeof ContactInfoSchemaUpdate>;

export const freq__4 = z.union([z.enum(["1MS", "1D"]), z.null()]).nullish();
export type freq__4 = z.infer<typeof freq__4>;

export const DHCheckKind = z.enum([
  "config",
  "completeness",
  "freshness",
  "consistency",
]);
export type DHCheckKind = z.infer<typeof DHCheckKind>;

export const checks = z.union([z.array(DHCheckKind), z.null()]).nullish();
export type checks = z.infer<typeof checks>;

export const DHCategory = z.enum(["metering", "finance", "operation"]);
export type DHCategory = z.infer<typeof DHCategory>;

export const categories = z.union([z.array(DHCategory), z.null()]).nullish();
export type categories = z.infer<typeof categories>;

export const DHFlagCode = z.enum([
  "BCKM023",
  "CNC",
  "CNS",
  "ELD_NOT_CONFIGURED",
  "EXPMO",
  "ISPC",
  "MDE",
  "MDPC",
  "MF",
  "MI",
  "MPO",
  "MPRM",
  "WPRM",
  "NC",
  "NCCI",
  "NMDAT",
  "PDFA",
  "R172_LATE",
  "INDEX_RESET",
  "DATA_INCONSISTENCY",
  "WCS",
]);
export type DHFlagCode = z.infer<typeof DHFlagCode>;

export const flag_codes = z.union([z.array(DHFlagCode), z.null()]).nullish();
export type flag_codes = z.infer<typeof flag_codes>;

export const DHComments = z.object({
  error: z.array(z.string()).nullish(),
  info: z.array(z.string()).nullish(),
  aggregated: z.array(z.string()).nullish(),
});
export type DHComments = z.infer<typeof DHComments>;

export const BaseDHSiteSchema = z.object({
  uuid: z.string().uuid(),
  name: z.string(),
  external_id: z.union([z.string(), z.null()]).nullish(),
  dp_codes: z.array(z.string()),
  is_external: z.boolean(),
  assetowner: CompanySchemaShort,
  capacity: z.number(),
  portfolio: z.union([PortfolioSchema, z.null()]).nullish(),
  commissioning: z.union([z.coerce.date(), z.null()]).nullish(),
});
export type BaseDHSiteSchema = z.infer<typeof BaseDHSiteSchema>;

export const DHAssignee = z.enum(["client", "renewex"]);
export type DHAssignee = z.infer<typeof DHAssignee>;

export const DHActionLabel = z.enum([
  "inspect_config",
  "manual_backfill",
  "ask_for_mandate",
  "configure_this_case",
  "configure_timeseries",
  "inspect_goldensource",
  "send_contract",
  "fix_contract_synchronization",
]);
export type DHActionLabel = z.infer<typeof DHActionLabel>;

export const DHJob = z.enum([
  "backfill",
  "enedis_inspect_orders_job",
  "enedis_order_measurements_job",
  "enedis_order_m023_backfill_files_job",
  "enedis_files_job",
  "edfoa_synchronize_contracts_job",
]);
export type DHJob = z.infer<typeof DHJob>;

export const BaseDHFlagSchema = z.object({
  code: DHFlagCode,
  name: z.string(),
  assignee: z.union([DHAssignee, z.null()]).nullish(),
  action_label: z.union([DHActionLabel, z.null()]).nullish(),
  job: z.union([DHJob, z.null()]).nullish(),
});
export type BaseDHFlagSchema = z.infer<typeof BaseDHFlagSchema>;

export const DHSchema = z.object({
  uuid: z.string().uuid(),
  freq: z.union([z.enum(["1MS", "1D"]), z.null()]).nullish(),
  score: z.number(),
  check: DHCheckKind,
  category: DHCategory,
  comments: DHComments,
  last_update: z.coerce.date(),
  site: BaseDHSiteSchema,
  flags: z.array(BaseDHFlagSchema),
});
export type DHSchema = z.infer<typeof DHSchema>;

export const BaseDHSchema = z.object({
  uuid: z.string().uuid(),
  freq: z.union([z.enum(["1MS", "1D"]), z.null()]).nullish(),
  score: z.number(),
  check: DHCheckKind,
  category: DHCategory,
  comments: DHComments,
  last_update: z.coerce.date(),
});
export type BaseDHSchema = z.infer<typeof BaseDHSchema>;

export const SiteDHSchema = z.object({
  uuid: z.string().uuid(),
  name: z.string(),
  capacity: z.number(),
  dp_codes: z.array(z.string()),
  commissioning: z.union([z.coerce.date(), z.null()]).nullish(),
  external_id: z.union([z.string(), z.null()]).nullish(),
  datahealths: z.array(BaseDHSchema),
  is_external: z.boolean(),
});
export type SiteDHSchema = z.infer<typeof SiteDHSchema>;

export const DHScoreSchema = z.object({
  score: z.number(),
  name: z.union([z.string(), z.null()]).nullish(),
  subscores: z.array(z.record(z.any())),
});
export type DHScoreSchema = z.infer<typeof DHScoreSchema>;

export const Body_datahealth_model_level_refresh_api__model___uuid__datahealth_refresh_post =
  z.object({
    checks: z.union([z.array(DHCheckKind), z.null()]).nullish(),
    categories: z.union([z.array(DHCategory), z.null()]).nullish(),
  });
export type Body_datahealth_model_level_refresh_api__model___uuid__datahealth_refresh_post =
  z.infer<
    typeof Body_datahealth_model_level_refresh_api__model___uuid__datahealth_refresh_post
  >;

export const name = z.union([PastillaName, z.null()]).nullish();
export type name = z.infer<typeof name>;

export const status = z.union([PastillaStatus, z.null()]).nullish();
export type status = z.infer<typeof status>;

export const PastillaSchema = z.object({
  name: PastillaName,
  status: PastillaStatus,
  site: GSSiteSchema,
  horizon_label: HorizonLabel,
  sources: z.array(z.string()),
  gauge_value: z.union([z.number(), z.null()]).nullish(),
  budget_value: z.union([z.number(), z.null()]).nullish(),
  last_update: z.coerce.date(),
  last_status_change: z.coerce.date(),
  start: z.union([z.coerce.date(), z.null()]).nullish(),
  end: z.union([z.coerce.date(), z.null()]).nullish(),
  comment: z.union([z.string(), z.null()]).nullish(),
});
export type PastillaSchema = z.infer<typeof PastillaSchema>;

export const FilterKind = z.enum(["regions", "assetowners", "portfolios"]);
export type FilterKind = z.infer<typeof FilterKind>;

export const NoteTagSchema = z.object({
  uuid: z.string().uuid(),
  name: z.string(),
  code: z.string(),
  category: z.union([z.string(), z.null()]).nullish(),
  color: z.union([z.string(), z.null()]).nullish(),
  description: z.union([z.string(), z.null()]).nullish(),
});
export type NoteTagSchema = z.infer<typeof NoteTagSchema>;

export const NoteQueryModelName = z.enum([
  "assetmanager",
  "assetowner",
  "holding",
  "site",
  "portfolio",
  "contract",
]);
export type NoteQueryModelName = z.infer<typeof NoteQueryModelName>;

export const NoteSchema = z.object({
  uuid: z.string().uuid(),
  body: z.union([z.string(), z.null()]).nullish(),
  tags: z.array(NoteTagSchema),
  target_uuid: z.string().uuid(),
  target_model: NoteQueryModelName,
  target_name: z.string(),
  start: z.union([z.coerce.date(), z.null()]).nullish(),
  end: z.union([z.coerce.date(), z.null()]).nullish(),
  creation_date: z.coerce.date(),
  update_date: z.coerce.date(),
  created_by: z.union([z.string(), z.null()]).nullish(),
  updated_by: z.union([z.string(), z.null()]).nullish(),
  pinned: z.boolean(),
  level: z.union([z.string(), z.null()]).nullish(),
  archived: z.boolean(),
});
export type NoteSchema = z.infer<typeof NoteSchema>;

export const NoteSchemaCreate = z.object({
  body: z.string(),
  tags: z.union([z.array(z.string()), z.null()]).nullish(),
  start: z.union([z.coerce.date(), z.null()]).nullish(),
  end: z.union([z.coerce.date(), z.null()]).nullish(),
  pinned: z.union([z.boolean(), z.null()]).nullish(),
  level: z.union([z.string(), z.null()]).nullish(),
});
export type NoteSchemaCreate = z.infer<typeof NoteSchemaCreate>;

export const NoteSchemaUpdate = z.object({
  body: z.union([z.string(), z.null()]).nullish(),
  tags: z.union([z.array(z.string()), z.null()]).nullish(),
  start: z.union([z.coerce.date(), z.null()]).nullish(),
  end: z.union([z.coerce.date(), z.null()]).nullish(),
  pinned: z.union([z.boolean(), z.null()]).nullish(),
  level: z.union([z.string(), z.null()]).nullish(),
});
export type NoteSchemaUpdate = z.infer<typeof NoteSchemaUpdate>;

export const NoteTagSchemaCreate = z.object({
  name: z.string(),
  code: z.string(),
  category: z.union([z.string(), z.null()]).nullish(),
  color: z.union([z.string(), z.null()]).nullish(),
  description: z.union([z.string(), z.null()]).nullish(),
});
export type NoteTagSchemaCreate = z.infer<typeof NoteTagSchemaCreate>;

export const NoteTagSchemaUpdate = z.object({
  name: z.union([z.string(), z.null()]).nullish(),
  code: z.union([z.string(), z.null()]).nullish(),
  category: z.union([z.string(), z.null()]).nullish(),
  color: z.union([z.string(), z.null()]).nullish(),
  description: z.union([z.string(), z.null()]).nullish(),
});
export type NoteTagSchemaUpdate = z.infer<typeof NoteTagSchemaUpdate>;

export const endpoints = makeApi([
  {
    method: "get",
    path: "/api/:model/:uuid/contracts",
    alias: "get_contracts_filtered_by_model",
    description: `List of contracts`,
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum(["site", "assetmanager", "assetowner", "holding"]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.array(BaseContractSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/datahealth",
    alias: "get_datahealth",
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "site",
          "assetowner",
          "assetmanager",
          "holding",
          "portfolio",
          "region",
        ]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "freq",
        type: "Query",
        schema: freq__4,
      },
      {
        name: "checks",
        type: "Query",
        schema: checks,
      },
      {
        name: "categories",
        type: "Query",
        schema: categories,
      },
      {
        name: "is_external",
        type: "Query",
        schema: is_external,
      },
      {
        name: "technos",
        type: "Query",
        schema: technos,
      },
      {
        name: "flag_codes",
        type: "Query",
        schema: flag_codes,
      },
    ],
    response: z.array(DHSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/:model/:uuid/datahealth/refresh",
    alias: "datahealth_model_level_refresh",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Body_datahealth_model_level_refresh_api__model___uuid__datahealth_refresh_post,
      },
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "site",
          "assetowner",
          "assetmanager",
          "holding",
          "portfolio",
          "region",
        ]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.union([SiteDHSchema, z.null()]),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/document",
    alias: "get_documents_filtered_by_model",
    description: `Returns all documents of the given model
We force the children model according to the following rules:
- AssetManager : AM + Holding files
- Holding : Holding + AO files
- AssetOwner : AO + Site files
- Site : Site + Contract files
- Contract : Contract + Invoices files`,
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "assetmanager",
          "assetowner",
          "holding",
          "site",
          "portfolio",
          "contract",
          "invoice",
        ]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "start",
        type: "Query",
        schema: start,
      },
      {
        name: "end",
        type: "Query",
        schema: start,
      },
      {
        name: "docgen_kind",
        type: "Query",
        schema: docgen_kind,
      },
    ],
    response: z.array(DocumentSchemaShort),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/:model/:uuid/document",
    alias: "create_document",
    requestFormat: "form-data",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: Body_create_document_api__model___uuid__document_post,
      },
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "assetmanager",
          "assetowner",
          "holding",
          "site",
          "portfolio",
          "contract",
          "invoice",
        ]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.array(DocumentSchemaShort),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/document/detailed",
    alias: "get_detailed_document_by_model",
    description: `Returns all detailed filtered documents of the given model`,
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "assetmanager",
          "assetowner",
          "holding",
          "site",
          "portfolio",
          "contract",
          "invoice",
        ]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "start",
        type: "Query",
        schema: start,
      },
      {
        name: "end",
        type: "Query",
        schema: start,
      },
      {
        name: "docgen_kind",
        type: "Query",
        schema: docgen_kind,
      },
    ],
    response: z.array(DocumentSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/events",
    alias: "get_events_filtered_by_model",
    description: `Retrieve list of events from a specific model.`,
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum(["site", "assetowner", "assetmanager", "holding"]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "start",
        type: "Query",
        schema: start,
      },
      {
        name: "end",
        type: "Query",
        schema: start,
      },
    ],
    response: z.array(EventSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/:model/:uuid/files",
    alias: "post_files_filtered_by_model",
    description: `Upload file(s) related to a specific model instance.`,
    requestFormat: "form-data",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: Body_post_files_filtered_by_model_api__model___uuid__files_post,
      },
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "assetmanager",
          "assetowner",
          "holding",
          "site",
          "contract",
          "deliverypoint",
        ]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/files/content",
    alias: "download_file_by_model",
    description: `Get the content of a file associated to a specific model instance.`,
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "assetmanager",
          "assetowner",
          "holding",
          "site",
          "contract",
          "deliverypoint",
        ]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "filename",
        type: "Query",
        schema: z.string(),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "delete",
    path: "/api/:model/:uuid/files/content",
    alias: "delete_file_by_model",
    description: `Delete a file using the model, uuid and the filename of the target.`,
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "assetmanager",
          "assetowner",
          "holding",
          "site",
          "contract",
          "deliverypoint",
        ]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "filename",
        type: "Query",
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/files/infos",
    alias: "get_fileinfos_filtered_by_instance",
    description: `List of files informations related to a specific model instance.`,
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "assetmanager",
          "assetowner",
          "holding",
          "site",
          "contract",
          "deliverypoint",
        ]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.array(FileInfoSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/goldensource",
    alias: "get_goldensource_filtered_by_model",
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum(["site", "assetowner", "assetmanager", "holding"]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "freq",
        type: "Query",
        schema: z.enum(["1D", "1MS"]),
      },
    ],
    response: z.array(GSSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/goldensource/gauge",
    alias: "get_goldensource_gauge",
    description: `Get metrics associated to a model.`,
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum(["site", "assetowner", "assetmanager", "holding"]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.enum([
          "total_consumption",
          "self_consumption_rate",
          "self_production_rate",
          "coverage_rate",
          "max_injected_power",
          "max_offtaken_power",
          "pr_budget",
          "trs_budget",
          "pr_budget_recalled",
          "trs_budget_recalled",
          "revenue",
          "availability_injection",
          "availability_detailed",
          "availability_energy_prorated",
          "availability_technical",
          "production_loss_internal",
          "production_loss_global",
          "trs",
          "pr",
          "production",
          "consumption",
          "energy",
        ]),
      },
      {
        name: "start",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "end",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "freq",
        type: "Query",
        schema: company_uuid,
      },
      {
        name: "comparison_horizon_type",
        type: "Query",
        schema: z.enum(["last_year", "preceding", "none"]),
      },
      {
        name: "is_external",
        type: "Query",
        schema: is_external,
      },
    ],
    response: GSGaugeMetric,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/goldensource/kpis",
    alias: "get_goldensource_kpis",
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum(["assetowner", "assetmanager", "holding"]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "aggregation_levels",
        type: "Query",
        schema: aggregation_levels,
      },
      {
        name: "start",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "end",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "is_external",
        type: "Query",
        schema: is_external,
      },
      {
        name: "technos",
        type: "Query",
        schema: technos,
      },
      {
        name: "portfolios",
        type: "Query",
        schema: sites,
      },
      {
        name: "regions",
        type: "Query",
        schema: sites,
      },
    ],
    response: z.array(
      z.union([
        SiteGSKpi,
        GroupGSKpi,
        GroupGSKpiUuid,
        SubGroupGSKpi,
        SubGroupGSKpiUuid,
      ])
    ),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/goldensource/kpis/techno",
    alias: "get_goldensource_kpis_per_techno",
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum(["assetowner", "assetmanager", "holding"]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "start",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "end",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "is_external",
        type: "Query",
        schema: is_external,
      },
    ],
    response: z.array(GSKpiTechno),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/goldensource/timeseries",
    alias: "get_goldensource_timeseries",
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum(["site", "assetowner", "assetmanager", "holding"]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "chart_type",
        type: "Query",
        schema: z
          .enum(["production", "finance", "operation", "self_consumption"])
          .nullish(),
      },
      {
        name: "columns",
        type: "Query",
        schema: z.array(z.string()).nullish(),
      },
      {
        name: "start",
        type: "Query",
        schema: start,
      },
      {
        name: "end",
        type: "Query",
        schema: start,
      },
      {
        name: "freq",
        type: "Query",
        schema: z.enum(["1D", "1MS", "1YS", "1W-MON", "1AS-JAN"]),
      },
      {
        name: "is_external",
        type: "Query",
        schema: is_external,
      },
      {
        name: "technos",
        type: "Query",
        schema: technos,
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/invoices",
    alias: "get_invoices_filtered_by_model",
    description: `Retrieve list of invoices from a specific model.`,
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "site",
          "assetowner",
          "holding",
          "assetmanager",
          "contract",
        ]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "start",
        type: "Query",
        schema: start,
      },
      {
        name: "end",
        type: "Query",
        schema: start,
      },
    ],
    response: z.array(InvoiceSchemaShort),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/invoices/accountable",
    alias: "get_accountable_invoices",
    description: `Get invoices that are not yet accounted, and ready to be.`,
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "site",
          "assetowner",
          "holding",
          "assetmanager",
          "contract",
        ]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "only_paid",
        type: "Query",
        schema: z.boolean().nullish(),
      },
    ],
    response: z.array(InvoiceSchemaSync),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/:model/:uuid/invoices/compute",
    alias: "compute_invoice_from_model",
    description: `Triggers the computation of Invoices related to
the selected model instance for the selected horizon.`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Body_compute_invoice_from_model_api__model___uuid__invoices_compute_post,
      },
      {
        name: "model",
        type: "Path",
        schema: z.enum(["site", "contract", "invoice"]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: InvoiceComputationSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/:model/:uuid/invoices/publish",
    alias: "publish_invoice_from_model",
    description: `Publish invoices invoices related to the selected model instance
for the selected horizon.

When the model is a specific invoice, horizon given is discarded.`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Body_publish_invoice_from_model_api__model___uuid__invoices_publish_post,
      },
      {
        name: "model",
        type: "Path",
        schema: z.enum(["site", "contract", "invoice"]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.array(InvoiceSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/:model/:uuid/invoices/submit",
    alias: "submit_invoice_from_model",
    description: `Submit invoices related to the selected model instance
for the selected horizon.

When the model is a specific invoice, horizon given is discarded.`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Body_submit_invoice_from_model_api__model___uuid__invoices_submit_post,
      },
      {
        name: "model",
        type: "Path",
        schema: z.enum(["site", "contract", "invoice"]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.array(InvoiceSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/invoices/sync",
    alias: "get_invoices_filtered_by_last_meaningful_update",
    description: `Sync invoices on any new log action change between horizon.`,
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "site",
          "assetowner",
          "holding",
          "assetmanager",
          "contract",
        ]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "start",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "end",
        type: "Query",
        schema: z.coerce.date(),
      },
    ],
    response: z.array(InvoiceSchemaSync),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/metric",
    alias: "get_metrics",
    description: `Get metrics associated to a model.`,
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum(["assetmanager", "assetowner", "holding", "site"]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "name",
        type: "Query",
        schema: z.string(),
      },
      {
        name: "horizon",
        type: "Query",
        schema: horizon,
      },
    ],
    response: z.array(MetricSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/note",
    alias: "get_notes_filtered_by_model",
    description: `Retrieve notes from a specific model.`,
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "assetmanager",
          "assetowner",
          "holding",
          "site",
          "portfolio",
          "contract",
        ]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "tags",
        type: "Query",
        schema: sites,
      },
      {
        name: "start",
        type: "Query",
        schema: start,
      },
      {
        name: "end",
        type: "Query",
        schema: start,
      },
      {
        name: "include_archived",
        type: "Query",
        schema: is_external,
      },
      {
        name: "pinned",
        type: "Query",
        schema: is_external,
      },
      {
        name: "level",
        type: "Query",
        schema: company_uuid,
      },
      {
        name: "get_all_children",
        type: "Query",
        schema: z.boolean().nullish(),
      },
    ],
    response: z.array(NoteSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/:model/:uuid/note",
    alias: "create_note",
    description: `Create a single note tag`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: NoteSchemaCreate,
      },
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "assetmanager",
          "assetowner",
          "holding",
          "site",
          "portfolio",
          "contract",
        ]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: NoteSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/pastillas",
    alias: "get_pastillas_filtered_by_model",
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum(["assetmanager", "site", "assetowner", "holding"]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "name",
        type: "Query",
        schema: name,
      },
      {
        name: "horizon_label",
        type: "Query",
        schema: horizon,
      },
      {
        name: "status",
        type: "Query",
        schema: status,
      },
    ],
    response: z.array(PastillaSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/pastillas/underperforming",
    alias: "get_underperforming_pastillas",
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum(["holding", "assetowner", "assetmanager"]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.array(PastillaSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/sites",
    alias: "get_sites_filtered_by_model",
    description: `list of sites`,
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "assetmanager",
          "assetowner",
          "holding",
          "portfolio",
          "region",
          "site",
        ]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "is_external",
        type: "Query",
        schema: is_external,
      },
      {
        name: "statuses",
        type: "Query",
        schema: statuses,
      },
    ],
    response: z.array(SiteSchemaOverview),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/:uuid/sites/datahealth",
    alias: "get_sites_with_datahealth_filtered_by_model",
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "assetmanager",
          "assetowner",
          "holding",
          "portfolio",
          "region",
          "site",
        ]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.array(SiteDHSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/:model/:uuid/timeseries/data",
    alias: "get_timeseries_data_from_model",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Body_get_timeseries_data_from_model_api__model___uuid__timeseries_data_post,
      },
      {
        name: "model",
        type: "Path",
        schema: z.enum(["site", "tenderrequest", "contract", "invoice"]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "start",
        type: "Query",
        schema: start,
      },
      {
        name: "end",
        type: "Query",
        schema: start,
      },
      {
        name: "version",
        type: "Query",
        schema: version,
      },
      {
        name: "freq",
        type: "Query",
        schema: company_uuid,
      },
      {
        name: "unit",
        type: "Query",
        schema: company_uuid,
      },
      {
        name: "missing_freq_tol",
        type: "Query",
        schema: missing_freq_tol,
      },
      {
        name: "io_format",
        type: "Query",
        schema: io_format,
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/:model/:uuid/timeseries/tsids",
    alias: "get_timeseries_ids_from_model",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Body_get_timeseries_ids_from_model_api__model___uuid__timeseries_tsids_post,
      },
      {
        name: "model",
        type: "Path",
        schema: z.enum(["site", "tenderrequest", "contract", "invoice"]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.array(z.string()),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/:model/document",
    alias: "generate_documents",
    description: `Generate document report`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: generate_documents_Body,
      },
      {
        name: "model",
        type: "Path",
        schema: z.enum(["assetmanager", "assetowner", "holding", "site"]),
      },
    ],
    response: z.array(DocumentSchemaShort),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/:model/files/infos",
    alias: "get_fileinfos_filtered_by_model",
    description: `List of files informations related to a specific model.`,
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum([
          "assetmanager",
          "assetowner",
          "holding",
          "site",
          "contract",
          "deliverypoint",
        ]),
      },
      {
        name: "uuids",
        type: "Query",
        schema: sites,
      },
    ],
    response: z.array(FileInfoSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "patch",
    path: "/api/accounting_infos/:uuid",
    alias: "update_offtaker_accounting_infos",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: AccountingInfosSchemaUpdate,
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: AccountingInfosSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/addresses",
    alias: "get_addresses",
    description: `Get a list of addresses in the user&#x27;s scope`,
    requestFormat: "json",
    response: z.array(AddressSchemaWithPermission),
  },
  {
    method: "post",
    path: "/api/addresses",
    alias: "create_address",
    description: `Create an Address`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: AddressSchemaCreate,
      },
    ],
    response: AddressSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/addresses/:uuid",
    alias: "get_address_details",
    description: `Get an address&#x27; details`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: AddressSchemaLong,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "patch",
    path: "/api/addresses/:uuid",
    alias: "update_address",
    description: `Update an address`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: AddressSchemaUpdate,
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: AddressSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "delete",
    path: "/api/addresses/:uuid",
    alias: "delete_address",
    description: `Delete an address`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/auth/me",
    alias: "get_me",
    requestFormat: "json",
    response: User,
  },
  {
    method: "get",
    path: "/api/companies",
    alias: "get_companies",
    description: `Get a list of companies in the user&#x27;s scope (including self)`,
    requestFormat: "json",
    response: z.array(CompanySchema),
  },
  {
    method: "post",
    path: "/api/companies",
    alias: "create_company",
    description: `Create a Company`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CompanySchemaCreate,
      },
    ],
    response: CompanySchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/companies/:uuid",
    alias: "get_company_details",
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: CompanySchemaWithPermissions,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "patch",
    path: "/api/companies/:uuid",
    alias: "update_company",
    description: `Update a company`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CompanySchemaUpdate,
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: CompanySchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/contact-infos",
    alias: "get_contact_infos",
    description: `Get a list of contact infos in the user&#x27;s scope`,
    requestFormat: "json",
    response: z.array(ContactInfoSchemaWithPermission),
  },
  {
    method: "post",
    path: "/api/contact-infos",
    alias: "create_contact_info",
    description: `Create a contact info`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: ContactInfoSchemaCreate,
      },
    ],
    response: ContactInfoSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/contact-infos/:uuid",
    alias: "get_contact_info_details",
    description: `Get a contact info&#x27;s details`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: ContactInfoSchemaLong,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "patch",
    path: "/api/contact-infos/:uuid",
    alias: "update_contact_info",
    description: `Update a contact info`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: ContactInfoSchemaUpdate,
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: ContactInfoSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "delete",
    path: "/api/contact-infos/:uuid",
    alias: "delete_contact_info",
    description: `Delete a contact info`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/contracts/:uuid",
    alias: "get_contract",
    description: `Contract Details`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: ContractSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "patch",
    path: "/api/contracts/:uuid",
    alias: "update_contract",
    description: `Update a single contract`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: ContractSchemaUpdate,
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: ContractSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "patch",
    path: "/api/contracts/clause/:uuid",
    alias: "update_clause",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: ClauseUpdateSchema,
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: ClauseSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/datahealth/:uuid/refresh",
    alias: "datahealth_refresh",
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: DHScoreSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "patch",
    path: "/api/deliverypoints/:uuid",
    alias: "update_deliverypoint",
    description: `Update a single deliverypoint`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: DeliveryPointSchemaUpdate,
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: DeliveryPointSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/document",
    alias: "refresh_documents",
    description: `Refresh document`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: Body_refresh_documents_api_document_post,
      },
    ],
    response: z.array(DocumentSchemaShort),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/document/:uuid",
    alias: "get_document",
    description: `Retrieve a single document from its uuid.`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: DocumentSchemaShort,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "delete",
    path: "/api/document/:uuid",
    alias: "delete_document",
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/document/:uuid/content",
    alias: "download_document",
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/etl/enedis/:uuid/backfill",
    alias: "trigger_enedis_backfill",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema:
          Body_trigger_enedis_backfill_api_etl_enedis__uuid__backfill_post,
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.boolean(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/etl/soda/:uuid/backfill",
    alias: "trigger_soda_backfill",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: Body_trigger_soda_backfill_api_etl_soda__uuid__backfill_post,
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.boolean(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/events",
    alias: "create_event",
    description: `Create a single event`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: EventSchemaCreate,
      },
    ],
    response: EventSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/events/:uuid",
    alias: "get_event",
    description: `Retrieve a single invoice from its uuid.`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: EventSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "patch",
    path: "/api/events/:uuid",
    alias: "update_event",
    description: `Update a single event`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: EventSchemaUpdate,
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: EventSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "delete",
    path: "/api/events/:uuid",
    alias: "delete_event",
    description: `Delete a single event`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/filters",
    alias: "get_filters_values",
    requestFormat: "json",
    parameters: [
      {
        name: "kinds",
        type: "Query",
        schema: z.array(FilterKind),
      },
    ],
    response: z.record(z.array(z.string())),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/filters/:model/:uuid/docgen_configs",
    alias: "get_defined_docgen_configs",
    requestFormat: "json",
    parameters: [
      {
        name: "model",
        type: "Path",
        schema: z.enum(["assetmanager", "assetowner", "holding", "site"]),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.array(DocGenConfigSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/goldensource/refresh",
    alias: "refresh_goldensources",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: GSRefreshParams,
      },
    ],
    response: z.boolean(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/invoices/:uuid",
    alias: "get_invoice",
    description: `Retrieve a single invoice from its uuid.`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: InvoiceSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "patch",
    path: "/api/invoices/:uuid",
    alias: "patch_invoice",
    description: `Update a single invoice`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: InvoiceSchemaUpdate,
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: InvoiceSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/invoices/:uuid/:status",
    alias: "set_invoice_definitive_status",
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "status",
        type: "Path",
        schema: z.enum(["submitted", "paid"]),
      },
    ],
    response: InvoiceSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/invoices/:uuid/accounted",
    alias: "set_invoice_accounted",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ is_accounted: z.boolean().nullish().default(true) }),
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: InvoiceSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/invoices/:uuid/cancel",
    alias: "cancel_invoice",
    description: `Apply cancel action for specific invoice`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "cancel_action",
        type: "Query",
        schema: z
          .enum([
            "unpublish",
            "reject",
            "void_and_replace",
            "credit_note",
            "delete",
          ])
          .nullish(),
      },
    ],
    response: z.array(InvoiceSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/invoices/:uuid/document",
    alias: "get_invoice_pdf",
    description: `Retrieve a single invoice document from its invoice uuid.`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/invoices/:uuid/excel",
    alias: "get_invoice_data",
    description: `Retrieve a single invoice data from its uuid.`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/invoices/:uuid/submit_to_zero",
    alias: "submit_invoice_to_zero",
    description: `Apply submit_to_zero action for specific invoice`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: InvoiceSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/metric/:uuid",
    alias: "compute_metric",
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: MetricSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/note/:uuid",
    alias: "get_note",
    description: `Retrieve a single note from its uuid.`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: NoteSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "patch",
    path: "/api/note/:uuid",
    alias: "patch_note",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: NoteSchemaUpdate,
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: NoteSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "delete",
    path: "/api/note/:uuid",
    alias: "delete_note",
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "patch",
    path: "/api/note/:uuid/archive",
    alias: "archive_note",
    description: `Archive a note`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: NoteSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/notetag",
    alias: "get_note_tag",
    description: `get all notetags and can include or note common notetags`,
    requestFormat: "json",
    parameters: [
      {
        name: "include_commons",
        type: "Query",
        schema: z.boolean().nullish().default(true),
      },
    ],
    response: z.array(NoteTagSchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/notetag",
    alias: "create_notetag",
    description: `Create a single notetag`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: NoteTagSchemaCreate,
      },
    ],
    response: NoteTagSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "patch",
    path: "/api/notetag/:uuid",
    alias: "patch_notetag",
    description: `Update a single notetag`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: NoteTagSchemaUpdate,
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: NoteTagSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "delete",
    path: "/api/notetag/:uuid",
    alias: "delete_notetag",
    description: `Delete a single notetag`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/offtaker/:uuid/accounting_infos",
    alias: "get_offtaker_accounting_infos",
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.union([AccountingInfosSchema, z.null()]),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/offtaker/:uuid/accounting_infos",
    alias: "create_offtaker_accounting_info",
    description: `Create an Offtaker Accounting Infos`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: AccountingInfosSchemaCreate,
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: AccountingInfosSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/partners",
    alias: "get_partners",
    description: `Partners Summary`,
    requestFormat: "json",
    response: z.array(PartnerSummarySchema),
  },
  {
    method: "post",
    path: "/api/site_status",
    alias: "log_site_status",
    description: `Log a new site status for a site`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: SiteStatusHistorySchemaCreate,
      },
    ],
    response: SiteStatusHistorySchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "patch",
    path: "/api/site_status/:uuid",
    alias: "edit_site_status",
    description: `Update a single site status`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: SiteStatusHistorySchemaUpdate,
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: SiteStatusHistorySchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "delete",
    path: "/api/site_status/:uuid",
    alias: "delete_site_status",
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/sites",
    alias: "create_site",
    description: `Create a Site and its corresponding structure (Site &#x3D;&gt; DeliveryPoints &#x3D;&gt; Assets)`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: SiteSchemaCreate,
      },
    ],
    response: z.string().uuid(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/sites/:uuid",
    alias: "get_site",
    description: `Site&#x27;s Physical Description.

The powercurve will be a list of tuple [wind_speed (m/s), power (kW)] like so:
- [..., [4.0, 92.1], [4.5, 141.7], [5.0, 200.8], ...]`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: SiteSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "patch",
    path: "/api/sites/:uuid",
    alias: "update_site",
    description: `Update a single site`,
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: SiteSchemaUpdate,
      },
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: SiteSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/sites/:uuid/finance",
    alias: "get_site_financial_report",
    description: `Get financial report of a given site. Can get current or previous year report.`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "start",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "end",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "freq",
        type: "Query",
        schema: freq__2,
      },
      {
        name: "io_format",
        type: "Query",
        schema: io_format,
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/sites/:uuid/load_factor",
    alias: "get_load_factor",
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "start",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "end",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "freq",
        type: "Query",
        schema: freq__2,
      },
      {
        name: "missing_left_idx_tol",
        type: "Query",
        schema: missing_left_idx_tol,
      },
      {
        name: "io_format",
        type: "Query",
        schema: io_format,
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/sites/:uuid/load_factor_quantiles",
    alias: "get_load_factor_quantiles",
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "start",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "end",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "freq",
        type: "Query",
        schema: freq__2,
      },
      {
        name: "quantiles",
        type: "Query",
        schema: quantiles,
      },
      {
        name: "year_range",
        type: "Query",
        schema: year_range,
      },
      {
        name: "extrapolation_window",
        type: "Query",
        schema: extrapolation_window,
      },
      {
        name: "io_format",
        type: "Query",
        schema: io_format,
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/sites/:uuid/production",
    alias: "get_site_production_chart",
    description: `Get production chart for a given site.
missing_freq_tol and missing_data_resilient to be tuned
in order to be more or less permissive on data holes`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "start",
        type: "Query",
        schema: start,
      },
      {
        name: "end",
        type: "Query",
        schema: start,
      },
      {
        name: "freq",
        type: "Query",
        schema: z.string().nullish().default("1MS"),
      },
      {
        name: "unit",
        type: "Query",
        schema: z.string().nullish().default("MWh"),
      },
      {
        name: "missing_freq_tol",
        type: "Query",
        schema: z.number().nullish().default(1),
      },
      {
        name: "missing_data_resilient",
        type: "Query",
        schema: z.boolean().nullish().default(true),
      },
      {
        name: "io_format",
        type: "Query",
        schema: z.enum(["json", "feather"]).nullish(),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/sites/:uuid/supervision",
    alias: "get_site_supervision_chart",
    description: `Get supervision chart for a given site.`,
    requestFormat: "json",
    parameters: [
      {
        name: "uuid",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "start",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "end",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "freq",
        type: "Query",
        schema: freq__3,
      },
      {
        name: "unit",
        type: "Query",
        schema: company_uuid,
      },
      {
        name: "io_format",
        type: "Query",
        schema: io_format,
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/sites/commissioning",
    alias: "get_sites_for_commissioning",
    requestFormat: "json",
    parameters: [
      {
        name: "start",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "end",
        type: "Query",
        schema: z.coerce.date(),
      },
    ],
    response: CommissioningDashboardTree,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "post",
    path: "/api/tender_request",
    alias: "create_tender_request",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: TenderRequestCreate,
      },
    ],
    response: z.string().uuid(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/tender_request/:external_id",
    alias: "get_tender_request",
    requestFormat: "json",
    parameters: [
      {
        name: "external_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "delete",
    path: "/api/tender_request/:external_id",
    alias: "delete_tender_request",
    requestFormat: "json",
    parameters: [
      {
        name: "external_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/timeseries/:timeserie_id/data",
    alias: "get_timeseries_data",
    requestFormat: "json",
    parameters: [
      {
        name: "timeserie_id",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "start",
        type: "Query",
        schema: start,
      },
      {
        name: "end",
        type: "Query",
        schema: start,
      },
      {
        name: "version",
        type: "Query",
        schema: version,
      },
      {
        name: "freq",
        type: "Query",
        schema: company_uuid,
      },
      {
        name: "unit",
        type: "Query",
        schema: company_uuid,
      },
      {
        name: "missing_freq_tol",
        type: "Query",
        schema: missing_freq_tol,
      },
      {
        name: "io_format",
        type: "Query",
        schema: io_format,
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/timeseries/:timeserie_id/metadata",
    alias: "get_timeseries_metadata",
    requestFormat: "json",
    parameters: [
      {
        name: "timeserie_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: TSMetadata,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/timeseries/:timeserie_id/stats",
    alias: "get_timeseries_stats",
    requestFormat: "json",
    parameters: [
      {
        name: "timeserie_id",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "start",
        type: "Query",
        schema: start,
      },
      {
        name: "end",
        type: "Query",
        schema: start,
      },
    ],
    response: Statistics,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/topology/capacity",
    alias: "contracted_capacity",
    description: `Provide a portfolio view of installed capacity split by year and contract type`,
    requestFormat: "json",
    parameters: [
      {
        name: "start",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "end",
        type: "Query",
        schema: z.coerce.date(),
      },
      {
        name: "freq",
        type: "Query",
        schema: freq,
      },
      {
        name: "company_uuid",
        type: "Query",
        schema: company_uuid,
      },
    ],
    response: z.record(TimeSeries),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/topology/contracts/summary",
    alias: "portfolio_contract_summary",
    description: `Provide a portfolio summary with number of contract per price mechanism`,
    requestFormat: "json",
    parameters: [
      {
        name: "company_uuid",
        type: "Query",
        schema: company_uuid,
      },
    ],
    response: z.record(z.array(ContractSummarySchema)),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/topology/physical",
    alias: "get_sites_topology",
    description: `Providing an excel file filled in with slected sites data.
If sites list is empty, all portfolio sites will be provided`,
    requestFormat: "json",
    parameters: [
      {
        name: "sites",
        type: "Query",
        schema: sites,
      },
      {
        name: "company_uuid",
        type: "Query",
        schema: company_uuid,
      },
    ],
    response: z.unknown(),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
  {
    method: "get",
    path: "/api/topology/sites/summary",
    alias: "portfolio_site_summary",
    description: `Provide a portfolio summary with number of site per technology
 and corresponding total installed capacity`,
    requestFormat: "json",
    parameters: [
      {
        name: "company_uuid",
        type: "Query",
        schema: company_uuid,
      },
    ],
    response: z.record(SiteSummarySchema),
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: HTTPValidationError,
      },
    ],
  },
]);

export function createApiClient(baseUrl: string, options?: ZodiosOptions) {
  return new Zodios(baseUrl, endpoints, options);
}
